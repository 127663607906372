import { Item, Drink } from './RestaurantPrivate';

export class RestaurantPublic {
    public id: string;
    public name: string;
    public address: string = "";
    public email: string = "";
    public phoneNumber: string = "";
    public countryCallingCode: string = "";
    public categories: string[] = [];
    public type: string[] = [];
    public coordinate: string = "";
    public quality: number = 0;
    public image: string = "";
    public images: string[] = [];
    public midPrice: number = 0;
    public midQuality: number = 0;
    public midQualityPrice: number = 0;
    public campaigns: Campaigns[] = [];
    public services: Service[] = [];
    public periods: ClosePeriods[] = [];
    public dishes: Dish[] = [];
    public houseWines: HouseWine[] = [];
    public restaurantFeedBack: Feedback = new Feedback().toPlainObject();
    public serviceSettings: ServiceSettings = new ServiceSettings().toPlainObject();
    public rating: number = 0;
    public numFeedback: number = 0;
    public isActive: boolean = true;    //Restaurante iniciado a false até estar pronto a ser publicado
    public menus: MenuDish[] = [];
    public variableDishes: VariableDishes[] = [];

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

  

class Campaigns {
    public name: string;
    public createdAt: Date;
    public beginning: Date;
    public ending: Date;
    public discount: number;
    public maxClients: number;
    public currentUsedClients: number;
    
    constructor(name: string, createdAt: Date, beginning: Date, ending: Date, discount: number, maxClients: number, currentUsedClients: number) {
        this.name = name;
        this.createdAt = createdAt;
        this.beginning = beginning;
        this.ending = ending;
        this.discount = discount;
        this.maxClients = maxClients;
        this.currentUsedClients = currentUsedClients;
    }
}

export class Service {
    public service: Availability;
    public serviceDays: DayStatus[];
    
    constructor(service: Availability, serviceDays: DayStatus[], ) {
        this.service = service;
        this.serviceDays = serviceDays;
    }
}

class ClosePeriods {
    public name: string;
    public duration: PeriodsDuration;
    public services: string[];
    
    constructor(name: string, duration: PeriodsDuration, services: string[]) {
        this.name = name;
        this.duration = duration;
        this.services = services;
    }
}

export class Dish {
    public dish: Item = new Item();
    public categoryId: string = "";
}

export class DrinkCart {
    public drink: Drink = new Drink();
    public categoryName: string = "";
}

export class HouseWine {
    wineName: string;
    wineType: string;
    wineImage: string;
    winePriceSizes: WinePriceSizes[];
    
    constructor(wineName: string, wineType: string, wineImage: string, winePriceSizes: WinePriceSizes[]) {
        this.wineName = wineName;
        this.wineType = wineType;
        this.wineImage = wineImage;
        this.winePriceSizes = winePriceSizes;
    }
}

export class WinePriceSizes {
    public wineSize: string;
    public winePrice: number;
    
    constructor(wineSize: string, winePrice: number) {
        this.wineSize = wineSize;
        this.winePrice = winePrice;
    }
}

class Feedback {
    public foodFeedBack: FoodFeedback[] = [];
    public restaurantFeedBack: RestaurantFeedback[] = [];

    toPlainObject(): any {
        return {
            foodFeedBack: this.foodFeedBack,
            restaurantFeedBack: this.restaurantFeedBack,
        };
    }
}

class FoodFeedback {
    public id: string;
    public userID: string;
    public dishID: string;
    public rating: number;
    
    constructor(id: string, userID: string, dishID: string, rating: number) {
        this.id = id;
        this.userID = userID;
        this.dishID = dishID;
        this.rating = rating;
    }
}

class RestaurantFeedback {
    public id: string;
    public createdAt: Date;
    public userID: string;
    public food: number;
    public service: number;
    public space: number;
    public noise: number;
    public speed: number;
    public qualityPrice: number;
    public context: string;
    public comment: string;
    public images: string[];

    constructor(
        id: string,
        createdAt: Date,
        userID: string,
        food: number,
        service: number,
        space: number,
        noise: number,
        speed: number,
        qualityPrice: number,
        context: string,
        comment: string,
        images: string[]
    ) {
        this.id = id;
        this.createdAt = createdAt;
        this.userID = userID;
        this.food = food;
        this.service = service;
        this.space = space;
        this.noise = noise;
        this.speed = speed;
        this.qualityPrice = qualityPrice;
        this.context = context;
        this.comment = comment;
        this.images = images;
    }
}

class ServiceSettings {
    public timeSlot: number = 30;
    public isAuto: boolean = false;
    public table: number[] = [0, 1];
    public criteria: number = 0;

    toPlainObject(): any {
        return {
            timeSlot: this.timeSlot,
            isAuto: this.isAuto,
            table: this.table,
            criteria: this.criteria,
        };
    }
}

export class MenuDish {
    public availability: Availability = new Availability((new Date()).toISOString(), (new Date()).toISOString(), 1, "nome");
    public lunch: boolean[] = [false, false, false, false, false, false, false];
    public dinner: boolean[] = [false, false, false, false, false, false, false];
    public dishes: DishWithoutCategory[] = [];
    public name: string = "";
    public prices: MenuPrice = new MenuPrice();
}

export class VariableDishes {
    public description: string = "";
    public categoryName: number = 0;
    public restaurantId: number = 0;

    constructor(description: string, categoryName: number, restaurantId: number) {
        this.description = description;
        this.categoryName = categoryName;
        this.restaurantId = restaurantId;
    }
}

export class DayStatus {
    public isOpen: boolean;
    public openTime: Date;
    public closeTime: Date;
    public lastClient: Date;
    public maxClients: number;
    
    constructor(isOpen: boolean, openTime: Date, closeTime: Date, lastClient: Date, maxClients: number) {
        this.isOpen = isOpen;
        this.openTime = openTime;
        this.closeTime = closeTime;
        this.lastClient = lastClient;
        this.maxClients = maxClients;
    }
}

class PeriodsDuration {
    public startDate: Date;
    public endDate: Date;

    constructor(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

export class DishWithoutCategory {
    public id: string = "";
    public createdAt: Date = new Date();
    public name: string = "";
    public description: string = "";
    public images: string[] = [];
    public prices: DishPrice[] = [new DishPrice()];
    public rating: number = -1;
    public type: string = "";
    public code: string = "";
    public caracteristics: string[] = [];
    public isAvailable: boolean = true;
    public restaurantMenuType: boolean[] | null = null;
}

export class DishPrice {
    public price: number = 0;
    public description: string = "";
}

class MenuPrice {
    public drink: MenuDrink = new MenuDrink();
    public noDrink: MenuDrink = new MenuDrink();
}

class MenuDrink {
    public isActive: boolean = false;
    public price: number = 0;
}

export class Availability {
    public startDate: string;
    public endDate: string;
    public status: number; // 1 - always active or 2 - between given start and end dates
    public name: string;

    constructor(startDate: string, endDate: string, status: number, name: string) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.status = status;
        this.name = name;
    }
}