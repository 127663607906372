import "./typesDropdown.css";
import blackArrow from "../../utils/logos/blackArrow.svg";
import arrow from "../../utils/logos/arrow.svg";

const options = [
    "Africano",
    "Americano",
    "Argentino",
    "Asiático",
    "Belga",
    "Brasileiro",
    "Chinês",
    "Europeu",
    "Francês",
    "Fusão",
    "Grego",
    "Indiano",
    "Internacional",
    "Iraniano",
    "Italiano",
    "Japonês",
    "Coreano",
    "Lebanês",
    "Mediterrâneo",
    "Mexicano",
    "Marroquino",
    "Pizzaria",
    "Português",
    "Regional",
    "Seafood",
    "Sul Americano",
    "Espanhol",
    "Thai",
    "Tradicional",
    "Vegan",
    "Vegetariano",
    "Vietnamita",
];

const TypesDropdown = ({
    isActive,
    setIsActive,
    isEditable = true,
    selectedOptions,
    onOptionSelect,
    width = 23.5,
    height = 2,
}) => {
    return (
        <div className="typesDropdown" style={{ width: width + "rem" }}>
            <div
                className="dropdown-btn"
                onClick={() => setIsActive(!isActive)}
                style={
                    isActive
                        ? {
                              backgroundColor: "#2F2F2F",
                              color: "#FFFFFF",
                              height: height + "rem",
                          }
                        : { height: height + "rem" }
                }
            >
                <p style={isActive ? { color: "#FFFFFF" } : {}}>
                    Tipo de cozinha
                </p>
                <img
                    src={isActive ? arrow : blackArrow}
                    style={isActive ? { transform: "rotate(180deg)" } : {}}
                    alt="arrow"
                />
            </div>

            {isActive && (
                <div
                    className="dropdown-content"
                    style={{ top: `calc(${height}rem)` }}
                >
                    <div className="rowsContainer">
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[0])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[0])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[0]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[1])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[1])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[1]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[2])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[2])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[2]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[3])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[3])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[3]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[4])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[4])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[4]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[5])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[5])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[5]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[6])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[6])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[6]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[7])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[7])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[7]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[8])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[8])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[8]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[9])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[9])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[9]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[10])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[10])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[10]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[11])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[11])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[11]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[12])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[12])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[12]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[13])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[13])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[13]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[14])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[14])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[14]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[15])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[15])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[15]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[16])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[16])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[16]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[17])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[17])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[17]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[18])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[18])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[18]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[19])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[19])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[19]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[20])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[20])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[20]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[21])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[21])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[21]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[22])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[22])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[22]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[23])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[23])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[23]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[24])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[24])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[24]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[25])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[25])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[25]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[26])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[26])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[26]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[27])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[27])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[27]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[28])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[28])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[28]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[29])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[29])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[29]}
                            </span>
                        </div>
                        <div className="row">
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[30])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[30])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[30]}
                            </span>
                            <span
                                onClick={
                                    isEditable
                                        ? () => onOptionSelect(options[31])
                                        : null
                                }
                                className={
                                    selectedOptions.includes(options[31])
                                        ? "selectedOption"
                                        : ""
                                }
                            >
                                {options[31]}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TypesDropdown;
