import React from 'react';
import { SearchBar, AddHouseWinePopup, AddWineDirectoryPopup, EditHouseWinePopup, WineSizePriceDropdown } from '../../components';
import blackArrow from '../../utils/logos/blackArrow.svg';
import whiteArrow from '../../utils/logos/whiteArrow.svg';
import plus from '../../utils/logos/plus.svg';
import WineList from '../../components/wineList/WineList';

const bottleVolume = ['Copo', '25 cl', '37,5 cl', '50 cl', '75 cl', '1 l', '1,5 l', '3 l', '4,5 l', '6 l', '9 l', '12 l', '18 l'];

const WinePage = ({ wines,
                    isMainYearDropdownActive,
                    handleYearDetails,
                    selectedYear,
                    availableYears,
                    setAvailableYears,
                    handleListYearDropdownItemClick,
                    addWinePopupIsClicked,
                    setAddWinePopupIsClicked,
                    setWineData,
                    wineData,
                    addHouseWine,
                    addDirectoryWine,
                    handleWineSubmit,
                    wine_Directory,
                    wineVolume,
                    handleWineDetails,
                    selectedRow,
                    isBottleDropdownActive,
                    setIsBottleDropdownActive,
                    isYearDropdownActive,
                    setIsYearDropdownActive,
                    suggestionIsClicked,
                    handleSuggestionsButton,
                    handleYearDropdownItemClick,
                    handleBottleDropdownItemClick, 
                    handleWineDetailsSubmit, 
                    handleGlassPriceChange, 
                    handleBottlePriceChange, 
                    handleSetDescription, 
                    handleAddWine, 
                    addWineIsClicked, 
                    getWineTypes,
                    handleDirectoryDropdown,
                    handleHouseWine,
                    dropdownOptions,
                    houseWineState,
                    setHouseWineState,
                    handleDropdown,
                    handleSelectedOption,
                    wineDirectoryState,
                    setWineDirectoryState,
                    handleWineDirectoryBack,
                    handleHouseWineCancel,
                    handleAddPriceSize,
                    handleWineSizeSelection,
                    handleWinePriceSelection,
                    handleDropdownToggle,
                    handleWineNameSelection,
                    isHouseWineStateComplete,
                    isDirectoryWineStateComplete,
                    uploadImage,
                    toggleTypeExpansion,
                    toggleVolumeExpansion,
                    editHouseWine,
                    updateHouseWine,
                    removeHouseWine,
                    handleDirectoryWineCancel
                }) => {

    console.log("houseWineState: ", houseWineState);

    return (
        <div className='menu-wine'>   
            <h1>Carta de vinhos</h1>
            <div className='add-wine'>
                <button className="add-wine-btn" onClick={handleAddWine} type="button" style={{ background: addWineIsClicked ? '#248E86' : '#E9E9E9' }}>
                    <span style={{ color: addWineIsClicked ? '#FFF' : '#000' }}>{dropdownOptions}</span>
                    <img src={addWineIsClicked ? whiteArrow : blackArrow} alt="arrow" style={ addWineIsClicked ? {transform: "rotate(180deg)"} : {}}/>
                </button>
                {addWineIsClicked &&
                    <div className='add-wine-content'>
                        <button className='add-wine-item' type='button' onClick={handleDirectoryDropdown}><span>Diretório de vinhos</span></button>
                        <button className='add-wine-item' type='button' onClick={handleHouseWine}><span>Vinho da casa</span></button>
                    </div>
                }
            </div>
            {houseWineState.addHouseWine && 
                <div className='slideIn addWine'>
                    <h1>Vinho da casa</h1>
                    <div className='addHouseWine'>
                        <AddHouseWinePopup
                            searchBarPlaceholder='Vinho da casa'
                            handleWineSubmit={handleWineSubmit}
                            houseWineState={houseWineState}
                            handleDropdown={handleDropdown}
                            handleSelectedOption={handleSelectedOption}
                            handleWineNameSelection={handleWineNameSelection}
                            uploadImage={uploadImage}
                        />
                        <h2>Tamanho</h2>
                        <WineSizePriceDropdown
                            isEdit={false}
                            wineState={houseWineState}
                            handleDropdownToggle={handleDropdownToggle}
                            handleWineSizeSelection={handleWineSizeSelection}
                            handleWinePriceSelection={handleWinePriceSelection}
                            handleAddPriceSize={handleAddPriceSize}
                        />
                    </div>
                    <div className='addWine-cancel-save'>
                        <button className='cancel' onClick={handleHouseWineCancel}>Cancelar</button>
                        <button
                            className='save'
                            onClick={isHouseWineStateComplete() ? addHouseWine : null}
                            style={{
                                backgroundColor: isHouseWineStateComplete() ? '' : 'rgba(14, 75, 64, 0.7)',
                                cursor: isHouseWineStateComplete() ? 'pointer' : ''
                            }}
                            disabled={!isHouseWineStateComplete()}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            }

            {(houseWineState.wines || wineDirectoryState.wines) && (
                <div className='wine-list'>
                    <SearchBar width='38.438rem' text='Pesquisar na carta' margin='0 0 2.188rem 0' background="#E9E9E9" />
                    <WineList
                        houseWines={houseWineState.wines} 
                        directoryWines={wineDirectoryState.wines} 
                        getWineTypes={getWineTypes}
                        toggleTypeExpansion={toggleTypeExpansion}
                        expandedType={houseWineState.expandedType}
                        toggleVolumeExpansion={toggleVolumeExpansion}
                        expandedVolume={houseWineState.expandedVolume}
                        bottleVolume={bottleVolume}
                        editHouseWine={editHouseWine}
                        updateHouseWine={updateHouseWine}
                    />
                    {houseWineState.editHouseWine &&
                        <div className='slideIn addWine'>
                            <h1>Vinho da casa</h1>
                            <div className='addHouseWine'>
                                <EditHouseWinePopup 
                                    searchBarPlaceholder={houseWineState.wineName}
                                    handleWineSubmit={handleWineSubmit}
                                    houseWineState={houseWineState}
                                    setAddHouseWine={setHouseWineState}
                                    handleDropdown={handleDropdown}
                                    handleSelectedOption={handleSelectedOption}
                                    handleWineNameSelection={handleWineNameSelection}
                                    uploadImage={uploadImage}
                                />
                                <h2>Tamanho</h2>
                                <WineSizePriceDropdown
                                    isEdit={true}
                                    wineState={houseWineState}
                                    handleDropdownToggle={handleDropdownToggle}
                                    handleWineSizeSelection={handleWineSizeSelection}
                                    handleWinePriceSelection={handleWinePriceSelection}
                                    handleAddPriceSize={handleAddPriceSize}
                                />
                            </div>
                            <div className='addWine-cancel-save'>
                                <button className='cancel' onClick={handleHouseWineCancel}>Cancelar</button>
                                <button
                                    className='save'
                                    onClick={isHouseWineStateComplete() ? updateHouseWine : null}
                                    style={{
                                        backgroundColor: isHouseWineStateComplete() ? '' : 'rgba(14, 75, 64, 0.7)',
                                        cursor: isHouseWineStateComplete() ? 'pointer' : ''
                                    }}
                                    disabled={!isHouseWineStateComplete()}
                                >
                                    Guardar
                                </button>
                            </div>
                            <div>
                                <span className='remove-wine' onClick={removeHouseWine}>Remover da carta</span>
                            </div>
                        </div>
                    }
                </div>
            )}

            {wineDirectoryState.addWineDirectory &&
                <div className='wineDirectory-slide'>
                    <div className='wineDirectory'>
                        <div className='wineDirectory-header'>
                            <img src={blackArrow} alt='back' style={{ transform: 'rotate(90deg)' }} onClick={handleWineDirectoryBack}/>
                            <h1>Diretório de vinhos</h1>
                        </div>
                        <button className='add-directory-wine' onClick={() => setWineDirectoryState({ ...wineDirectoryState, addToWineDirectory: !wineDirectoryState.addToWineDirectory })}>
                            <img src={plus} alt='plus' />
                            <span>Adicionar vinho ao diretório</span>
                        </button>
                        <SearchBar width='38.438rem' text='Pesquisar no diretório' margin='0 0 2.188rem 0' background="#FFFFFF" />
                        {wineDirectoryState.wines && wineDirectoryState.wines.length > 0 && (
                            <div className='wine-list'>
                                <WineList 
                                    houseWines={houseWineState.wines} 
                                    directoryWines={wineDirectoryState.wines} 
                                    getWineTypes={getWineTypes}
                                    toggleTypeExpansion={toggleTypeExpansion}
                                    expandedType={houseWineState.expandedType}
                                    toggleVolumeExpansion={toggleVolumeExpansion}
                                    expandedVolume={houseWineState.expandedVolume}
                                    bottleVolume={bottleVolume}
                                    editHouseWine={editHouseWine}
                                    updateHouseWine={updateHouseWine}
                                />
                            </div>
                        )}
                        {wineDirectoryState.addToWineDirectory && 
                            <div className='slideIn addWine'>
                                <h1>Nome do vinho</h1>
                                <div className='addHouseWine'>
                                    <AddWineDirectoryPopup
                                        searchBarPlaceholder='Nome do vinho'
                                        handleWineSubmit={handleWineSubmit}
                                        handleDropdown={handleDropdown}
                                        handleSelectedOption={handleSelectedOption}
                                        uploadImage={uploadImage}
                                        wineDirectoryState={wineDirectoryState}
                                        handleWineNameSelection={handleWineNameSelection}
                                    />
                                    <div className='addWine-cancel-save'>
                                        <button className='cancel' onClick={handleDirectoryWineCancel}>Cancelar</button>
                                        <button
                                            className='save'
                                            onClick={isDirectoryWineStateComplete() ? addDirectoryWine : null}
                                            style={{
                                                backgroundColor: isDirectoryWineStateComplete() ? '' : 'rgba(14, 75, 64, 0.7)',
                                                cursor: isDirectoryWineStateComplete() ? 'pointer' : ''
                                            }}
                                            disabled={!isDirectoryWineStateComplete()}
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }            
        </div>
    )
}

export default WinePage;