export class RestaurantPrivate {
    public id: string;
    public bankData: BankData = new BankData().toPlainObject();
    public billingData: BillingData = new BillingData().toPlainObject();
    public itemList: Item[] = [];

    constructor(id: string) {
        this.id = id;
    }
}

class BankData {
    public id: string = "";
    public accountOwnerName: string = "";
    public address: string = "";
    public iban: string = "";
    public locality: string = "";
    public paymentFrequency: string = "";
    public swiftBic: string = "";
    public postCode: string = "";

    toPlainObject(): any {
        return {
            id: this.id,
            accountOwnerName: this.accountOwnerName,
            address: this.address,
            iban: this.iban,
            locality: this.locality,
            paymentFrequency: this.paymentFrequency,
            swiftBic: this.swiftBic,
            postCode: this.postCode,
        };
    }
}

class BillingData {
    public nipc: string = "";
    public city: string = "";
    public companyName: string = "";
    public address: string = "";
    public postCode: string = "";
    public checkboxes: boolean[] = [false, false, false];

    toPlainObject(): any {
        return {
            nipc: this.nipc,
            city: this.city,
            companyName: this.companyName,
            address: this.address,
            postCode: this.postCode,
            checkboxes: this.checkboxes,
        };
    }
}

export class Item {
    public dishName: string = "";
    public dishDescription: string = "";
    public dishImage: string = "";
    public dishCode: string = "";
    public prices: PriceItem[] = [new PriceItem()];
    public dishCaracteristics: string[] = [];
    public dishOptions: DishOption[] = [];
}

class PriceItem {
    public price: number = 0;
    public description: string = "";
}

class DishOption {
    public dishOptionName: string = "";
    public numberOptions: number = 0;
    public dish_options: string[] = [""];
}

export class Drink {
    public drinkName: string = "";
    public drinkIVA: number = 0;
    public drinkDescription: string = "";
    public drinkImage: string = "";
    public prices: PriceItem[] = [new PriceItem()];
    public drinkOptions: DishOption[] = [];
    public drinkCaracteristics: string[] = [];
}