import "./closingPeriods.css";
import React from "react";
import plus from "../../utils/logos/plus.svg";
import optionsButton from "../../utils/logos/optionsButton.png";
import crossButton from "../../utils/logos/crossButton.png";

const ClosingPeriods = ({
  isCreatePeriodActive,
  setIsCreatePeriodActive,
  isOptionsCreatePeriodActive,
  setIsOptionsCreatePeriodActive,
  periodName,
  setPeriodName,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  periodServices,
  serviceButtonIsClicked,
  isEditPeriodActive,
  setIsEditPeriodActive,
  periods,
  setPeriods,
  services,
  handleServiceButtonClick,
  handleSavePeriod,
  handleIsEditPeriodActive,
  formatDateForInput,
  handleSaveEditPeriod,
  formatDateString,
}) => {
  return (
    <div className="closingPeriods">
      <button
        onClick={
          isEditPeriodActive === -1
            ? () => setIsCreatePeriodActive(!isCreatePeriodActive)
            : () => {}
        }
        className="createNew"
        style={
          isCreatePeriodActive
            ? { background: "#77B9B4", width: "10.375rem" }
            : { width: "10.375rem" }
        }
      >
        <img src={plus} alt="plus" />
        <p>Criar novo período</p>
      </button>

      <table className="table">
        <thead
          style={
            !periods || (periods.length === 0 && !isCreatePeriodActive)
              ? { display: "none" }
              : {}
          }
        >
          <tr>
            <th className="firstColumn"></th>
            <th className="periodName">Nome</th>
            <th>Início</th>
            <th>Fim</th>
            <th>Serviços</th>
          </tr>
        </thead>

        <tbody>
          {isCreatePeriodActive && (
            <tr>
              <td className="firstColumn">
                <img
                  onClick={() =>
                    setIsOptionsCreatePeriodActive(!isOptionsCreatePeriodActive)
                  }
                  src={
                    isOptionsCreatePeriodActive ? crossButton : optionsButton
                  }
                  alt="options"
                />
              </td>
              <td className="periodName">
                {periodName === "" ? "Nome" : periodName}
              </td>
              <td>{formatDateString(startDate)}</td>
              <td>{formatDateString(endDate)}</td>
              <td>{periodServices === "" ? "Nenhum" : periodServices}</td>
            </tr>
          )}
          <tr>
            <td colSpan="5" className="p-0">
              {isOptionsCreatePeriodActive && (
                <div className="popup">
                  <div className="name">
                    <span className="title">Nome</span>
                    <input
                      value={periodName}
                      onChange={(e) => {
                        setPeriodName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="dates">
                    <div className="startDate">
                      <span className="title">Início</span>
                      <input
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>

                    <div className="endDate">
                      <span className="title">Fim</span>
                      <input
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="servicesOptions">
                    <span className="title">Serviços</span>
                    <div className="servicesButtons">
                      {services.map((service, idx) => (
                        <button
                          onClick={() => handleServiceButtonClick(idx)}
                          style={
                            serviceButtonIsClicked[idx]
                              ? {
                                  background: "#77B9B4",
                                }
                              : {}
                          }
                          key={idx}
                        >
                          <span>{service.service.name}</span>
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="buttons">
                    <button
                      onClick={() => {
                        setIsCreatePeriodActive(false);
                        setIsOptionsCreatePeriodActive(false);
                      }}
                    >
                      <span>Cancelar</span>
                    </button>
                    <button onClick={handleSavePeriod} className="save">
                      <span>Guardar</span>
                    </button>
                  </div>
                </div>
              )}
            </td>
          </tr>

          {periods &&
            periods.map((period, key) => (
              <React.Fragment key={key}>
                <tr>
                  <td className="firstColumn">
                    <img
                      onClick={() => handleIsEditPeriodActive(key)}
                      src={
                        isEditPeriodActive === key ? crossButton : optionsButton
                      }
                      alt="options"
                    />
                  </td>
                  <td className="periodName">
                    {period.name === "" ? "Nome" : period.name}
                  </td>
                  <td>{formatDateString(period.startDate)}</td>
                  <td>{formatDateString(period.endDate)}</td>
                  <td>{period.serviceNames}</td>
                </tr>

                <tr>
                  <td colSpan="5" style={{ padding: "0" }}>
                    {isEditPeriodActive === key && (
                      <div className="popup">
                        <div className="name">
                          <span className="title">Nome</span>
                          <input
                            value={periodName}
                            onChange={(e) => {
                              setPeriodName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="dates">
                          <div className="startDate">
                            <span className="title">Início</span>
                            <input
                              value={
                                startDate instanceof Date
                                  ? formatDateForInput(startDate)
                                  : formatDateForInput(new Date(startDate))
                              }
                              type="date"
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </div>

                          <div className="endDate">
                            <span className="title">Fim</span>
                            <input
                              value={
                                endDate instanceof Date
                                  ? formatDateForInput(endDate)
                                  : formatDateForInput(new Date(endDate))
                              }
                              type="date"
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="servicesOptions">
                          <span className="title">Serviços</span>
                          <div className="servicesButtons">
                            {services.map((service, idx) => (
                              <button
                                onClick={() => handleServiceButtonClick(idx)}
                                style={
                                  serviceButtonIsClicked[idx]
                                    ? {
                                        background: "#77B9B4",
                                      }
                                    : {}
                                }
                                key={idx}
                              >
                                <span>{service.service.name}</span>
                              </button>
                            ))}
                          </div>
                        </div>

                        <div className="buttons">
                          <p
                            onClick={() => {
                              setPeriods((prev) =>
                                prev.filter(
                                  (_, index) => index !== isEditPeriodActive
                                )
                              );
                              setIsEditPeriodActive(-1);
                            }}
                            className="deleteServiceAndPeriod"
                          >
                            Eliminar período
                          </p>
                          <button onClick={() => setIsEditPeriodActive(-1)}>
                            <span>Cancelar</span>
                          </button>
                          <button
                            onClick={handleSaveEditPeriod}
                            className="save"
                          >
                            <span>Guardar</span>
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClosingPeriods;
