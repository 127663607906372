import './addWinePopup.css';
import blackArrow from '../../utils/logos/blackArrow.svg';
import plus from '../../utils/logos/plus.svg';

import React from 'react';

const AddHouseWinePopup = ({ searchBarPlaceholder, handleWineSubmit, houseWineState, handleDropdown, handleSelectedOption, handleWineNameSelection, uploadImage }) => {
    
    const wineTypes = ['Tinto', 'Branco', 'Rosé', 'Espumante', 'Fortificado', 'Colheita tardia'];

    const Dropdown = ({ options, selectedValue, isDropdown, label, value, toSelect }) => (
        <div className='dropdown'>
            <button className={isDropdown ? 'dropdown-btn-clicked' : 'dropdown-btn'} onClick={() => handleDropdown(true, value)} type="button" style={ isDropdown ? { background: '#D9D9D9' } : {} }>
                <span>{selectedValue || label}</span>
                <img src={blackArrow} alt="arrow" style={isDropdown ? { transform: "rotate(180deg)" } : {}} />
            </button>
            {isDropdown &&
                <div className='dropdown-content'>
                    {options.map((option) => (
                        <button key={option} type='button' className='dropdown-item' onClick={() => handleSelectedOption(true, value, toSelect, option)}>
                            {option}
                        </button>
                    ))}
                </div>
            }
        </div>
    );

    return (
        <div className='addWine-popup'>
            <form onSubmit={handleWineSubmit}>
                <div className='wineName'>
                    <h2>Nome:</h2>
                    <input
                        type='text'
                        placeholder={searchBarPlaceholder}
                        onBlur={(event) => handleWineNameSelection(true, event.target.value)}
                    />
                </div>
                <div className='wineType'>
                    <div className='wineType-header'>
                        <h2>Tipo de vinho:</h2>
                        <Dropdown
                            options={wineTypes}
                            selectedValue={houseWineState.wineType}
                            isDropdown={houseWineState.dropdown === 'wineTypeDropdown'}
                            label='Tipo de vinho'
                            value='wineTypeDropdown'
                            toSelect='wineType'
                        />
                    </div>
                </div>
                <div className='wineImage'>
                    <h2>Fotografia</h2>
                    <div className='wineImage-input'>
                        <input type="file" id="file" onChange={(e) => {uploadImage(e.target.files[0], "houseWine")}} />
                        <label htmlFor="file" className="custom-file-upload">
                            <img src={plus} alt='plus' />
                        </label>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddHouseWinePopup;