import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children, setAuthorizationToken }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        JSON.parse(localStorage.getItem("authToken") ? true : false)
    );

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("authToken"));
        if (token) {
            setAuthorizationToken(token);
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [setAuthorizationToken]);

    const login = (token) => {
        setIsAuthenticated(true);
        setAuthorizationToken(token);
        localStorage.setItem("authToken", JSON.stringify(token));
    };

    const logout = () => {
        setIsAuthenticated(false);
        setAuthorizationToken(null);
        localStorage.removeItem("authToken");
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
