import './dishTypeButtons.css';
import pencil from '../../utils/logos/pencil.svg';

const DishTypeButtons = ({ dishTypeSelected, setDishTypeSelected, isEditCategoriesActive, setIsEditCategoriesActive, categories=true }) => {
  return (
    <div className='dishTypeButtons'>
        <button onClick={() => setDishTypeSelected("comida")} style={ dishTypeSelected === "comida" ? { background: "#77B9B4" } : {}}><span>Comida</span></button>
        <button onClick={() => setDishTypeSelected("bebida")} style={ dishTypeSelected === "bebida" ? { marginLeft: "2.25rem", background: "#77B9B4" }: {marginLeft: "2.25rem"}}><span>Bebidas</span></button>
        { categories &&
            <button onClick={() => setIsEditCategoriesActive(true)} style={isEditCategoriesActive ? {background: "#77B9B4"} : {}} className="addCat" type="button"><img src={pencil} alt="edit" /><span>Editar categorias</span></button>
        }
    </div>
  )
}

export default DishTypeButtons;