import './dishForSlideIn.css';


const DishForSlideIn = ({ title, description, price, image, margin, onClick }) => {
  return (
    <div className='dishForSlideIn' onClick={onClick} style={{ margin: margin }}>
        <img src={image} alt="image" className='dishPic' />
        <div className='infos'>
            <h3 className='dishTitle'>{title}</h3>
            <p className='dishDescription'>{description}</p>
        </div>
        <span className='dishPrice'>{price}€</span>
    </div>
  )
}

export default DishForSlideIn;