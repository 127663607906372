import "./multiLayerDropdown.css";
import blackArrow from "../../utils/logos/blackArrow.svg";
import arrow from "../../utils/logos/arrow.svg";

const tabs = [
  ["Sempre", -2],
  ["Até ao início do serviço", -1],
  ["Customizar", -5],
];

function MultiLayerDropdown({
  selected,
  setSelected,
  isActive,
  setIsActive,
  minutes,
  setMinutes,
}) {
  return (
    <div className="multiLayerDropdown">
      <div
        className="dropdown-btn"
        onClick={() => setIsActive(!isActive)}
        style={isActive ? { backgroundColor: "#2F2F2F", color: "#FFFFFF" } : {}}
      >
        {console.log(selected)}
        <p style={isActive ? { color: "#FFFFFF" } : {}}>
          {selected === "Customizar"
            ? selected + ` (${minutes} min)`
            : selected}
        </p>
        <img
          src={isActive ? arrow : blackArrow}
          className={isActive ? "rotate-180" : undefined}
          alt="arrow"
        />
      </div>

      {isActive && (
        <div className="dropdown-content">
          {tabs.map((item, idx) => (
            <div
              className="dropdown-item"
              style={selected === item[0] ? { background: "#8f8f8f" } : {}}
              key={idx}
              onClick={
                item[0] === "Customizar"
                  ? () => {
                      setSelected(item[0]);
                    }
                  : () => {
                      setSelected(item[0]);
                      setIsActive(false);
                    }
              }
            >
              <p style={selected === item[0] ? { color: "white" } : {}}>
                {item[0]}
              </p>
              {selected === "Customizar" && (
                <div className="CustomizarExtension">
                  <input
                    placeholder={minutes}
                    onChange={(e) => setMinutes(Number(e.target.value))}
                    type="number"
                  />
                  <p>minutos antes do início do serviço</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MultiLayerDropdown;
