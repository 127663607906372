import React from "react";
import "./servicesPage.css";
import "../closingPeriods/closingPeriods.css";
import { HourDropdown, CalendarDropdown } from "../../components";
import plus from "../../utils/logos/plus.svg";
import optionsButton from "../../utils/logos/optionsButton.png";
import crossButton from "../../utils/logos/crossButton.png";
import Switch from "react-switch";

const dropdownTabs30 = [
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
];
const dropdownTabs15 = [
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
];

const ServicesPage = ({
  isCreateServiceActive,
  setIsCreateServiceActive,
  isOptionsCreateActive,
  setIsOptionsCreateActive,
  isEditServiceActive,
  setIsEditServiceActive,
  timeOptionSelected,
  setTimeOptionSelected,
  dropdownsSelected,
  isDropdownActive,
  isToggleChecked,
  maxClients,
  calendarSelectedStart,
  setCalendarSelectedStart,
  calendarSelectedEnd,
  setCalendarSelectedEnd,
  isCalendarActive,
  setIsCalendarActive,
  serviceName,
  setServiceName,
  services,
  setServices,
  setDisplayDays,
  setUnselectedDays,
  handleIsEditServiceActive,
  timeSlot,
  handleDropdownSelection,
  handleDropdownClick,
  updateMaxClients,
  handleToggle,
  resetFields,
  handleUpdate,
  handleSave,
  formatDateString,
}) => {
  return (
    <div className="servicesPage">
      {!isOptionsCreateActive && (
        <button
          className="createNew"
          onClick={() => setIsCreateServiceActive(!isCreateServiceActive)}
          style={isCreateServiceActive ? { background: "#77B9B4" } : {}}
        >
          <img src={plus} alt="plus" />
          <p>Criar novo serviço</p>
        </button>
      )}

      <table className="table">
        <thead
          style={
            !services || (services.length === 0 && !isCreateServiceActive)
              ? { display: "none" }
              : {}
          }
        >
          <tr>
            <th className="firstColumn"></th>
            <th className="name">Nome</th>
            <th className="timeOption">Início/Fim</th>
            <th className="days">Dias da semana</th>
            <th>Horário</th>
          </tr>
        </thead>

        <tbody>
          {isCreateServiceActive && (
            <tr>
              <td className="firstColumn">
                <img
                  onClick={() =>
                    setIsOptionsCreateActive(!isOptionsCreateActive)
                  }
                  src={isOptionsCreateActive ? crossButton : optionsButton}
                  alt="options"
                />
              </td>
              <td className="name">
                {serviceName === "" ? "Nome" : serviceName}
              </td>
              <td className="timeOption">
                {timeOptionSelected === 1
                  ? "Sempre válido"
                  : `${formatDateString(
                      calendarSelectedStart
                    )} - ${formatDateString(calendarSelectedEnd)}`}
              </td>
              <td className="days">{setDisplayDays(isToggleChecked, true)}</td>
              <td className="schedule">
                <span>{dropdownsSelected[0] + "-" + dropdownsSelected[1]}</span>
                <span>Última reserva até {dropdownsSelected[2]}</span>
                <span>
                  {setUnselectedDays() !== ""
                    ? `Excepto ${setUnselectedDays()}`
                    : ""}
                </span>
              </td>
            </tr>
          )}

          {services &&
            services.map((service, key) => (
              <tr key={key}>
                <td className="firstColumn">
                  <img
                    onClick={() => handleIsEditServiceActive(key)}
                    src={
                      isEditServiceActive === key ? crossButton : optionsButton
                    }
                    alt="options"
                  />
                </td>
                <td className="name">
                  {service.service.name === "" ? "Nome" : service.service.name}
                </td>
                <td className="timeOption">
                  {service.service.status === 1
                    ? "Sempre válido"
                    : `${formatDateString(
                        service.service.startDate
                      )} - ${formatDateString(service.service.endDate)}`}
                </td>
                <td className="days">
                  {setDisplayDays(service.serviceDays, false)}
                </td>
                <td className="schedule">
                  <span>
                    {dropdownsSelected[0] + "-" + dropdownsSelected[1]}
                  </span>
                  <span>Última reserva até {dropdownsSelected[2]}</span>
                  <span>
                    {setUnselectedDays(service) !== ""
                      ? `Excepto ${setUnselectedDays(service)}`
                      : ""}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {isEditServiceActive !== -1 && (
        <div>
          <div className="timeButtons">
            <button
              onClick={() => setTimeOptionSelected(1)}
              className={timeOptionSelected === 1 ? "selected" : undefined}
              style={{ marginRight: "0.938rem" }}
            >
              <span>Sempre</span>
            </button>
            <button
              onClick={() => setTimeOptionSelected(2)}
              className={timeOptionSelected === 2 ? "selected" : undefined}
            >
              <span>Período específico</span>
            </button>
          </div>

          <div className="container">
            <div className="inputDiv">
              <span>Nome</span>
              <input
                value={serviceName}
                onChange={(e) => {
                  setServiceName(e.target.value);
                }}
              />
              {timeOptionSelected === 2 && (
                <div className="dateDiv">
                  <CalendarDropdown
                    selectedStart={calendarSelectedStart}
                    setSelectedStart={setCalendarSelectedStart}
                    selectedEnd={calendarSelectedEnd}
                    setSelectedEnd={setCalendarSelectedEnd}
                    isActive={isCalendarActive}
                    setIsActive={setIsCalendarActive}
                    circularButtton={true}
                  />
                  {`${formatDateString(
                    calendarSelectedStart
                  )} - ${formatDateString(calendarSelectedEnd)}`}
                </div>
              )}
            </div>

            <table className="popup-table">
              <thead>
                <tr>
                  <th>Dias da semana</th>
                  <th>Horas de abertura</th>
                  <th>Último cliente</th>
                  <th>Nº max de clientes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Aplicar os mesmos valores
                    <br />
                    para todos os dias
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[0]}
                      setSelected={(value) => handleDropdownSelection(0, value)}
                      isActive={isDropdownActive[0]}
                      setIsActive={() => handleDropdownClick(0)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[1]}
                      setSelected={(value) => handleDropdownSelection(1, value)}
                      isActive={isDropdownActive[1]}
                      setIsActive={() => handleDropdownClick(1)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[2]}
                      setSelected={(value) => handleDropdownSelection(2, value)}
                      isActive={isDropdownActive[2]}
                      setIsActive={() => handleDropdownClick(2)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[0]}
                      onChange={(e) => updateMaxClients(0, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr className="extraInfo">
                  <td colSpan="2">
                    Excepto:
                    <br />
                    Dom: 12h30 - 14h00, entrada do último cliente 13h30
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[0]}
                      onChange={() => handleToggle(0)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Segunda</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[3]}
                      setSelected={(value) => handleDropdownSelection(3, value)}
                      isActive={isDropdownActive[3]}
                      setIsActive={() => handleDropdownClick(3)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[4]}
                      setSelected={(value) => handleDropdownSelection(4, value)}
                      isActive={isDropdownActive[4]}
                      setIsActive={() => handleDropdownClick(4)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[5]}
                      setSelected={(value) => handleDropdownSelection(5, value)}
                      isActive={isDropdownActive[5]}
                      setIsActive={() => handleDropdownClick(5)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[1]}
                      onChange={(e) => updateMaxClients(1, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[1]}
                      onChange={() => handleToggle(1)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Terça</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[6]}
                      setSelected={(value) => handleDropdownSelection(6, value)}
                      isActive={isDropdownActive[6]}
                      setIsActive={() => handleDropdownClick(6)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[7]}
                      setSelected={(value) => handleDropdownSelection(7, value)}
                      isActive={isDropdownActive[7]}
                      setIsActive={() => handleDropdownClick(7)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[8]}
                      setSelected={(value) => handleDropdownSelection(8, value)}
                      isActive={isDropdownActive[8]}
                      setIsActive={() => handleDropdownClick(8)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[2]}
                      onChange={(e) => updateMaxClients(2, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[2]}
                      onChange={() => handleToggle(2)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Quarta</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[9]}
                      setSelected={(value) => handleDropdownSelection(9, value)}
                      isActive={isDropdownActive[9]}
                      setIsActive={() => handleDropdownClick(9)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[10]}
                      setSelected={(value) =>
                        handleDropdownSelection(10, value)
                      }
                      isActive={isDropdownActive[10]}
                      setIsActive={() => handleDropdownClick(10)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[11]}
                      setSelected={(value) =>
                        handleDropdownSelection(11, value)
                      }
                      isActive={isDropdownActive[11]}
                      setIsActive={() => handleDropdownClick(11)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[3]}
                      onChange={(e) => updateMaxClients(3, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[3]}
                      onChange={() => handleToggle(3)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Quinta</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[12]}
                      setSelected={(value) =>
                        handleDropdownSelection(12, value)
                      }
                      isActive={isDropdownActive[12]}
                      setIsActive={() => handleDropdownClick(12)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[13]}
                      setSelected={(value) =>
                        handleDropdownSelection(13, value)
                      }
                      isActive={isDropdownActive[13]}
                      setIsActive={() => handleDropdownClick(13)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[14]}
                      setSelected={(value) =>
                        handleDropdownSelection(14, value)
                      }
                      isActive={isDropdownActive[14]}
                      setIsActive={() => handleDropdownClick(14)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[4]}
                      onChange={(e) => updateMaxClients(4, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[4]}
                      onChange={() => handleToggle(4)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Sexta</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[15]}
                      setSelected={(value) =>
                        handleDropdownSelection(15, value)
                      }
                      isActive={isDropdownActive[15]}
                      setIsActive={() => handleDropdownClick(15)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[16]}
                      setSelected={(value) =>
                        handleDropdownSelection(16, value)
                      }
                      isActive={isDropdownActive[16]}
                      setIsActive={() => handleDropdownClick(16)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[17]}
                      setSelected={(value) =>
                        handleDropdownSelection(17, value)
                      }
                      isActive={isDropdownActive[17]}
                      setIsActive={() => handleDropdownClick(17)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[5]}
                      onChange={(e) => updateMaxClients(5, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[5]}
                      onChange={() => handleToggle(5)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Sábado</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[18]}
                      setSelected={(value) =>
                        handleDropdownSelection(18, value)
                      }
                      isActive={isDropdownActive[18]}
                      setIsActive={() => handleDropdownClick(18)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[19]}
                      setSelected={(value) =>
                        handleDropdownSelection(19, value)
                      }
                      isActive={isDropdownActive[19]}
                      setIsActive={() => handleDropdownClick(19)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[20]}
                      setSelected={(value) =>
                        handleDropdownSelection(20, value)
                      }
                      isActive={isDropdownActive[20]}
                      setIsActive={() => handleDropdownClick(20)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[6]}
                      onChange={(e) => updateMaxClients(6, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[6]}
                      onChange={() => handleToggle(6)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Domingo</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[21]}
                      setSelected={(value) =>
                        handleDropdownSelection(21, value)
                      }
                      isActive={isDropdownActive[21]}
                      setIsActive={() => handleDropdownClick(21)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[22]}
                      setSelected={(value) =>
                        handleDropdownSelection(22, value)
                      }
                      isActive={isDropdownActive[22]}
                      setIsActive={() => handleDropdownClick(22)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[23]}
                      setSelected={(value) =>
                        handleDropdownSelection(23, value)
                      }
                      isActive={isDropdownActive[23]}
                      setIsActive={() => handleDropdownClick(23)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[7]}
                      onChange={(e) => updateMaxClients(7, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="buttons">
              <p
                onClick={() => {
                  setServices((prev) =>
                    prev.filter((_, index) => index !== isEditServiceActive)
                  );
                  setIsEditServiceActive(-1);
                }}
                className="deleteServiceAndPeriod"
              >
                Eliminar serviço
              </p>
              <button
                onClick={() => {
                  setIsEditServiceActive(-1);
                  resetFields();
                }}
              >
                <span>Cancelar</span>
              </button>
              <button className="save" onClick={handleUpdate}>
                <span>Guardar</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {isOptionsCreateActive && (
        <div>
          <div className="timeButtons">
            <button
              onClick={() => setTimeOptionSelected(1)}
              className={timeOptionSelected === 1 ? "selected" : undefined}
              style={{ marginRight: "0.938rem" }}
            >
              <span>Sempre</span>
            </button>
            <button
              onClick={() => setTimeOptionSelected(2)}
              className={timeOptionSelected === 2 ? "selected" : undefined}
            >
              <span>Período específico</span>
            </button>
          </div>

          <div className="container">
            <div className="inputDiv">
              <span>Nome</span>
              <input
                value={serviceName}
                onChange={(e) => {
                  setServiceName(e.target.value);
                }}
              />
              {timeOptionSelected === 2 && (
                <div className="dateDiv">
                  <CalendarDropdown
                    selectedStart={calendarSelectedStart}
                    setSelectedStart={setCalendarSelectedStart}
                    selectedEnd={calendarSelectedEnd}
                    setSelectedEnd={setCalendarSelectedEnd}
                    isActive={isCalendarActive}
                    setIsActive={setIsCalendarActive}
                    circularButtton={true}
                  />
                  <p className="date">{`${formatDateString(
                    calendarSelectedStart
                  )} - ${formatDateString(calendarSelectedEnd)}`}</p>
                </div>
              )}
            </div>

            <table className="popup-table">
              <thead>
                <tr>
                  <th>Dias da semana</th>
                  <th>Horas de abertura</th>
                  <th>Último cliente</th>
                  <th>Nº max de clientes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Aplicar os mesmos valores
                    <br />
                    para todos os dias
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[0]}
                      setSelected={(value) => handleDropdownSelection(0, value)}
                      isActive={isDropdownActive[0]}
                      setIsActive={() => handleDropdownClick(0)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[1]}
                      setSelected={(value) => handleDropdownSelection(1, value)}
                      isActive={isDropdownActive[1]}
                      setIsActive={() => handleDropdownClick(1)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[2]}
                      setSelected={(value) => handleDropdownSelection(2, value)}
                      isActive={isDropdownActive[2]}
                      setIsActive={() => handleDropdownClick(2)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={maxClients[0]}
                      onChange={(e) => updateMaxClients(0, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr className="extraInfo">
                  <td colSpan="2">
                    Excepto:
                    <br />
                    Dom: 12h30 - 14h00, entrada do último cliente 13h30
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[0]}
                      onChange={() => handleToggle(0)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Segunda</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[3]}
                      setSelected={(value) => handleDropdownSelection(3, value)}
                      isActive={isDropdownActive[3]}
                      setIsActive={() => handleDropdownClick(3)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[4]}
                      setSelected={(value) => handleDropdownSelection(4, value)}
                      isActive={isDropdownActive[4]}
                      setIsActive={() => handleDropdownClick(4)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[5]}
                      setSelected={(value) => handleDropdownSelection(5, value)}
                      isActive={isDropdownActive[5]}
                      setIsActive={() => handleDropdownClick(5)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[1]}
                      onChange={(e) => updateMaxClients(1, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[1]}
                      onChange={() => handleToggle(1)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Terça</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[6]}
                      setSelected={(value) => handleDropdownSelection(6, value)}
                      isActive={isDropdownActive[6]}
                      setIsActive={() => handleDropdownClick(6)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[7]}
                      setSelected={(value) => handleDropdownSelection(7, value)}
                      isActive={isDropdownActive[7]}
                      setIsActive={() => handleDropdownClick(7)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[8]}
                      setSelected={(value) => handleDropdownSelection(8, value)}
                      isActive={isDropdownActive[8]}
                      setIsActive={() => handleDropdownClick(8)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[2]}
                      onChange={(e) => updateMaxClients(2, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[2]}
                      onChange={() => handleToggle(2)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Quarta</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[9]}
                      setSelected={(value) => handleDropdownSelection(9, value)}
                      isActive={isDropdownActive[9]}
                      setIsActive={() => handleDropdownClick(9)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[10]}
                      setSelected={(value) =>
                        handleDropdownSelection(10, value)
                      }
                      isActive={isDropdownActive[10]}
                      setIsActive={() => handleDropdownClick(10)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[11]}
                      setSelected={(value) =>
                        handleDropdownSelection(11, value)
                      }
                      isActive={isDropdownActive[11]}
                      setIsActive={() => handleDropdownClick(11)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[3]}
                      onChange={(e) => updateMaxClients(3, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[3]}
                      onChange={() => handleToggle(3)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Quinta</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[12]}
                      setSelected={(value) =>
                        handleDropdownSelection(12, value)
                      }
                      isActive={isDropdownActive[12]}
                      setIsActive={() => handleDropdownClick(12)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[13]}
                      setSelected={(value) =>
                        handleDropdownSelection(13, value)
                      }
                      isActive={isDropdownActive[13]}
                      setIsActive={() => handleDropdownClick(13)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[14]}
                      setSelected={(value) =>
                        handleDropdownSelection(14, value)
                      }
                      isActive={isDropdownActive[14]}
                      setIsActive={() => handleDropdownClick(14)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[4]}
                      onChange={(e) => updateMaxClients(4, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[4]}
                      onChange={() => handleToggle(4)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Sexta</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[15]}
                      setSelected={(value) =>
                        handleDropdownSelection(15, value)
                      }
                      isActive={isDropdownActive[15]}
                      setIsActive={() => handleDropdownClick(15)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[16]}
                      setSelected={(value) =>
                        handleDropdownSelection(16, value)
                      }
                      isActive={isDropdownActive[16]}
                      setIsActive={() => handleDropdownClick(16)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[17]}
                      setSelected={(value) =>
                        handleDropdownSelection(17, value)
                      }
                      isActive={isDropdownActive[17]}
                      setIsActive={() => handleDropdownClick(17)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[5]}
                      onChange={(e) => updateMaxClients(5, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[5]}
                      onChange={() => handleToggle(5)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Sábado</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[18]}
                      setSelected={(value) =>
                        handleDropdownSelection(18, value)
                      }
                      isActive={isDropdownActive[18]}
                      setIsActive={() => handleDropdownClick(18)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[19]}
                      setSelected={(value) =>
                        handleDropdownSelection(19, value)
                      }
                      isActive={isDropdownActive[19]}
                      setIsActive={() => handleDropdownClick(19)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[20]}
                      setSelected={(value) =>
                        handleDropdownSelection(20, value)
                      }
                      isActive={isDropdownActive[20]}
                      setIsActive={() => handleDropdownClick(20)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[6]}
                      onChange={(e) => updateMaxClients(6, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="toggle">
                    <Switch
                      checked={isToggleChecked[6]}
                      onChange={() => handleToggle(6)}
                      onColor="#77B9B4"
                      offColor="#E9E9E9"
                      onHandleColor="#000000"
                      offHandleColor="#000000"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={40}
                      height={20}
                      handleDiameter={20}
                    />
                    <span>Domingo</span>
                  </td>
                  <td className="dropdownCell">
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[21]}
                      setSelected={(value) =>
                        handleDropdownSelection(21, value)
                      }
                      isActive={isDropdownActive[21]}
                      setIsActive={() => handleDropdownClick(21)}
                      top={true}
                    />
                    <span>-</span>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[22]}
                      setSelected={(value) =>
                        handleDropdownSelection(22, value)
                      }
                      isActive={isDropdownActive[22]}
                      setIsActive={() => handleDropdownClick(22)}
                    />
                  </td>
                  <td>
                    <HourDropdown
                      tabs={timeSlot === 30 ? dropdownTabs30 : dropdownTabs15}
                      selected={dropdownsSelected[23]}
                      setSelected={(value) =>
                        handleDropdownSelection(23, value)
                      }
                      isActive={isDropdownActive[23]}
                      setIsActive={() => handleDropdownClick(23)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={maxClients[7]}
                      onChange={(e) => updateMaxClients(7, e.target.value)}
                      className="maxClients"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="buttons">
              <button
                onClick={() => {
                  setIsCreateServiceActive(false);
                  setIsOptionsCreateActive(false);
                }}
              >
                <span>Cancelar</span>
              </button>
              <button className="save" onClick={handleSave}>
                <span>Guardar</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesPage;
