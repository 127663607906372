import "./serviceSettings.css";
import React, { useState } from "react";
import { MultiLayerDropdown } from "../../components";
import Switch from "react-switch";

const ServiceSettings = ({
  timeSlot,
  setTimeSlot,
  automaticAcception,
  setAutomaticAcception,
  seatAmount,
  setSeatAmount,
  selectedTabBigDropdown,
  setSelectedTabBigDropdown,
  minutesDropdown,
  setMinutesDropdown,
}) => {
  const [isBigDropdownActive, setIsBigDropdownActive] = useState(false);

  return (
    <div className="serviceSettings">
      <h2>Definições</h2>

      <h3>Time-slot</h3>
      <p className="timeSlotDescription">
        Selecione o time-slot que aparecerá para cada serviço
      </p>
      <div className="timeButtons">
        <button
          onClick={() => setTimeSlot(15)}
          style={
            timeSlot === 15
              ? { background: "#77B9B4", marginRight: "1.813rem" }
              : { marginRight: "1.813rem" }
          }
        >
          <span>15 minutos</span>
        </button>
        <button
          onClick={() => setTimeSlot(30)}
          style={timeSlot === 30 ? { background: "#77B9B4" } : {}}
        >
          <span>30 minutos</span>
        </button>
      </div>

      <div className="automaticAcception">
        <h3>Aceitação automática</h3>
        <Switch
          checked={automaticAcception}
          onChange={setAutomaticAcception}
          onColor="#77B9B4"
          offColor="#E9E9E9"
          onHandleColor="#000000"
          offHandleColor="#000000"
          checkedIcon={false}
          uncheckedIcon={false}
          width={40}
          height={20}
          handleDiameter={20}
        />
      </div>
      <p className="criteria">
        As reservas podem ser aceites automaticamente (sem confirmações) ou
        manualmente através da Twinkle Operations App*
      </p>

      {automaticAcception && (
        <div className="automaticAcceptionExtension">
          <h3>Critérios para aceitação automática</h3>
          <div className="multiLayerDropdownDiv">
            <MultiLayerDropdown
              selected={selectedTabBigDropdown}
              setSelected={setSelectedTabBigDropdown}
              isActive={isBigDropdownActive}
              setIsActive={setIsBigDropdownActive}
              minutes={minutesDropdown}
              setMinutes={setMinutesDropdown}
            />
          </div>

          <div className="placesAmount">
            <p>As mesas devem ter no máximo:</p>
            <input
              className="my-0 mx-[0.62rem]"
              placeholder={seatAmount}
              onChange={(e) => setSeatAmount(Number(e.target.value))}
              type="number"
            />
            <p>pessoas.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceSettings;
