import { Spinner } from "flowbite-react";

const Loading = () => {
  return (
    <div className="flex items-center justify-center w-full h-full absolute top-0 left-0">
      <Spinner className="fill-twinkleColor" size="xl" />
    </div>
  );
};

export default Loading;
