import './addWinePopup.css';
import blackArrow from '../../utils/logos/blackArrow.svg';
import plus from '../../utils/logos/plus.svg';

import React from 'react';

const AddWineDirectoryPopup = ({ searchBarPlaceholder, handleWineSubmit, handleDropdown, handleSelectedOption, uploadImage, handleWineNameSelection, wineDirectoryState }) => {
    
    const wineTypes = ['Tinto', 'Branco', 'Rosé', 'Espumante', 'Fortificado', 'Colheita tardia'];
    
    const fortifiedWineStyles = {
        Porto: ['Ruby', 'Tawny', 'Vintage', 'LBV'],
        Xerez: ['Fino', 'Manzanilla', 'Amontillado', 'Oloroso', 'Palo Cortado', 'Pedro Ximénez'],
        Vermute: ['Vermute Doce', 'Vermute Seco', 'Vermute Branco'],
        Marsala: ['Fine', 'Superiore', 'Superiore Riserva', 'Vergine', 'Vergine Riserva'],
        Madeira: ['Sercial', 'Verdelho', 'Bual', 'Malmsey'],
    };
    const regionsByCountry = {
        Portugal: ['Douro', 'Porto', 'Alentejo', 'Dão', 'Minho'],
        Espanha: ['Rioja', 'Ribera del Duero', 'Priorat', 'Jerez', 'Navarra'],
        Itália: ['Toscana', 'Piemonte', 'Sicília', 'Vêneto', 'Lombardia'],
        França: ['Bordéus', 'Borgonha', 'Champagne', 'Provença', 'Alsácia'],
        Argentina: ['Mendoza', 'Salta', 'San Juan', 'La Rioja', 'Patagônia'],
    };

    const Dropdown = ({ options, selectedValue, isDropdown, label, value, toSelect }) => (
        <div className='dropdown'>
            <button className={isDropdown ? 'dropdown-btn-clicked' : 'dropdown-btn'} onClick={() => handleDropdown(false, value)} type="button" style={ isDropdown ? { background: '#D9D9D9' } : {} }>
                <span>{selectedValue || label}</span>
                <img src={blackArrow} alt="arrow" style={isDropdown ? { transform: "rotate(180deg)" } : {}} />
            </button>
            {isDropdown &&
                <div className='dropdown-content'>
                {options.map((option) => (
                    <button key={option} type='button' className='dropdown-item' onClick={() => handleSelectedOption(false, value, toSelect, option)}>
                        {option}
                    </button>
                ))}
                </div>
            }
        </div>
    );

    return (
        <div className='addWine-popup'>
            <form onSubmit={handleWineSubmit}>
                <div className='wineName'>
                    <h2>Nome:</h2>
                    <input
                        type='text'
                        placeholder={searchBarPlaceholder}
                        onBlur={(event) => handleWineNameSelection(false, event.target.value)}
                    />
                </div>
                <div className='wineType'>
                    <div className='wineType-header'>
                        <h2>Tipo de vinho:</h2>
                        <Dropdown
                            options={wineTypes}
                            selectedValue={wineDirectoryState.wineType}
                            isDropdown={wineDirectoryState.dropdown === 'wineTypeDropdown'}
                            label='Tipo de vinho'
                            value='wineTypeDropdown'
                            toSelect='wineType'
                        />
                    </div>
                    {wineDirectoryState.wineType && wineDirectoryState.wineType !== 'Fortificado' &&
                        <div className='wineOrigin'>
                            <h2>País:</h2>
                            <Dropdown
                                options={Object.keys(regionsByCountry)}
                                selectedValue={wineDirectoryState.wineOrigin}
                                isDropdown={wineDirectoryState.dropdown === 'wineOriginDropdown'}
                                label='País'
                                value='wineOriginDropdown'
                                toSelect='wineOrigin'
                            />
                        </div>
                    }
                    {wineDirectoryState.wineOrigin && wineDirectoryState.wineType !== 'Fortificado' &&
                        <div className='wineRegion'>
                            <h2>Região:</h2>
                            <Dropdown
                                options={regionsByCountry[wineDirectoryState.wineOrigin]}
                                selectedValue={wineDirectoryState.wineRegion}
                                isDropdown={wineDirectoryState.dropdown === 'wineRegionDropdown'}
                                label='Região'
                                value='wineRegionDropdown'
                                toSelect='wineRegion'
                            />
                        </div>
                    }
                    {wineDirectoryState.wineType && wineDirectoryState.wineType === 'Fortificado' &&
                        <div className='wineFort'>
                            <h2>Tipo de fortificado:</h2>
                            <Dropdown
                                options={Object.keys(fortifiedWineStyles)}
                                selectedValue={wineDirectoryState.wineFort}
                                isDropdown={wineDirectoryState.dropdown === 'wineFortDropdown'}
                                label='Tipo'
                                value='wineFortDropdown'
                                toSelect='wineFort'
                            />
                        </div>
                    }
                    {wineDirectoryState.wineFort && wineDirectoryState.wineType === 'Fortificado' &&
                        <div className='wineFortStyle'>
                            <h2>Estilo:</h2>
                            <Dropdown
                                options={fortifiedWineStyles[wineDirectoryState.wineFort]}
                                selectedValue={wineDirectoryState.wineFortStyle}
                                isDropdown={wineDirectoryState.dropdown === 'wineFortStyleDropdown'}
                                label='Estilo'
                                value='wineFortStyleDropdown'
                                toSelect='wineFortStyle'
                            />
                        </div>
                    }
                </div>
                <div className='wineImage'>
                    <h2>Fotografia</h2>
                    <div className='wineImage-input'>
                        <input type="file" id="file" onChange={(e) => {uploadImage(e.target.files[0], "directoryWine")}} />
                        <label htmlFor="file" className="custom-file-upload">
                            <img src={plus} alt='plus' />
                        </label>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddWineDirectoryPopup;