import "./services.css";
import React, { useState, useEffect } from "react";
import { PageTabs, Loading } from "../../components";
import { ClosingPeriods, ServiceSettings, ServicesPage } from "../index.js";
import { Service, Availability } from "../../restaurantModels/RestaurantPublic";
import {
  getAllServicesEndpoint,
  updateAllServicesEndpoint,
  getAllPeriodsEndpoint,
  updateAllPeriodsEndpoint,
  getServicePreferencesEndpoint,
  updateTimeStampEndpoint,
  updateAutomaticAcceptanceEndpoint,
  updateAcceptanceTypeEndpoint,
  updateMaxClientsEndpoint,
} from "../../backendFunctions";
import { delay } from "../../utils/utils.js";

const tabs = ["Serviços", "Período de Encerramento", "Definições"];

const currentDate = new Date();
const formattedCurrentDate = currentDate.toISOString();

const oneWeekBefore = new Date(currentDate);
oneWeekBefore.setDate(currentDate.getDate() - 7);
const formattedBeforeDate = oneWeekBefore.toISOString();

const weekDays = [
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sabado",
  "Domingo",
];
const days = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"];

const Services = ({ restaurant, authorizationToken }) => {
  const [servicesSelectedTab, setServicesSelectedTab] = useState(tabs[0]);

  /* "Serviços" page state */
  const [isCreateServiceActive, setIsCreateServiceActive] = useState(false);
  const [isOptionsCreateActive, setIsOptionsCreateActive] = useState(false);
  const [isEditServiceActive, setIsEditServiceActive] =
    useState(-1); /* -1 - none active */
  const [timeOptionSelected, setTimeOptionSelected] =
    useState(1); /* 1(sempre) or 2(Período específico) */
  const [dropdownsSelected, setDropdownsSelected] = useState(
    Array(24).fill("12:00")
  );
  const [isDropdownActive, setIsDropdownActive] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isToggleChecked, setIsToggleChecked] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [maxClients, setMaxClients] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [calendarSelectedStart, setCalendarSelectedStart] =
    useState(formattedBeforeDate);
  const [calendarSelectedEnd, setCalendarSelectedEnd] =
    useState(formattedCurrentDate);
  const [isCalendarActive, setIsCalendarActive] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [services, setServices] = useState(null);

  /* "Período de Encerramento" page state */
  const [isCreatePeriodActive, setIsCreatePeriodActive] = useState(false);
  const [isOptionsCreatePeriodActive, setIsOptionsCreatePeriodActive] =
    useState(false);
  const [periodName, setPeriodName] = useState("");
  const [startDate, setStartDate] = useState(formattedBeforeDate);
  const [endDate, setEndDate] = useState(formattedCurrentDate);
  const [periodServices, setPeriodServices] = useState("");
  const [serviceButtonIsClicked, setServiceButtonIsClicked] = useState([]);
  const [isEditPeriodActive, setIsEditPeriodActive] =
    useState(-1); /* -1 - none active */
  const [periods, setPeriods] = useState(null);

  /* "Definições" page state */
  const [selectedTabBigDropdown, setSelectedTabBigDropdown] = useState(null);
  const [timeSlot, setTimeSlot] = useState(null); /* 15min or 30min */
  const [minutesDropdown, setMinutesDropdown] = useState(0);
  const [seatAmount, setSeatAmount] = useState(null);
  const [automaticAcception, setAutomaticAcception] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  /* DB functions */
  useEffect(() => {
    getAllServicesEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setServices(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getAllPeriodsEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setPeriods(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getServicePreferencesEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setTimeSlot(response.data.data.timeStamp);
        setAutomaticAcception(response.data.data.automaticAcceptance);
        setSeatAmount(response.data.data.maxClients);
        if (response.data.data.acceptanceType === -2) {
          setSelectedTabBigDropdown("Sempre");
        } else if (response.data.data.acceptanceType === -1) {
          setSelectedTabBigDropdown("Até ao início do serviço");
        } else {
          setSelectedTabBigDropdown("Customizar");
          setMinutesDropdown(response.data.data.acceptanceType);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([{}, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (services) updateServices();
  }, [services]);

  const updateServices = () => {
    updateAllServicesEndpoint(
      services,
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (periods) updatePeriods();
  }, [periods]);

  const updatePeriods = () => {
    updateAllPeriodsEndpoint(
      periods,
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const updateTimeSlot = () => {
      updateTimeStampEndpoint(
        timeSlot,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (timeSlot !== null) updateTimeSlot();
  }, [timeSlot]);

  useEffect(() => {
    const updateAutomaticAcceptance = () => {
      updateAutomaticAcceptanceEndpoint(
        automaticAcception,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (automaticAcception !== null) updateAutomaticAcceptance();
  }, [automaticAcception]);

  useEffect(() => {
    const updateAcceptanceType = () => {
      let arg = null;
      if (selectedTabBigDropdown === "Sempre") {
        arg = -2;
      } else if (selectedTabBigDropdown === "Até ao início do serviço") {
        arg = -1;
      } else {
        arg = minutesDropdown;
      }

      updateAcceptanceTypeEndpoint(
        arg,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (selectedTabBigDropdown !== null) updateAcceptanceType();
  }, [selectedTabBigDropdown, minutesDropdown]);

  useEffect(() => {
    const updateMaxClients = () => {
      updateMaxClientsEndpoint(
        seatAmount,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (seatAmount !== null) updateMaxClients();
  }, [seatAmount]);

  /* ------------------------------------------------------------------------------------------- */

  /* Services page functions */
  useEffect(() => {
    const selectedServiceNames = getSelectedServicesNames(
      serviceButtonIsClicked,
      services
    );
    setPeriodServices(selectedServiceNames.join(", "));
  }, [serviceButtonIsClicked, services]);

  function addService(newService) {
    setServices((prevServices) => [...prevServices, newService]);
    setServiceButtonIsClicked((prev) => [...prev, false]);
  }

  const parseDropdownValue = (index, j, day) => {
    const [openHourValue, openMinuteValue] =
      dropdownsSelected[index].split(":");
    const [closeHourValue, closeMinuteValue] =
      dropdownsSelected[index + 1].split(":");
    const [lastHourValue, lastMinuteValue] =
      dropdownsSelected[index + 2].split(":");

    const openTime = new Date(2024, 1, 1, openHourValue, openMinuteValue);
    const closeTime = new Date(2024, 1, 1, closeHourValue, closeMinuteValue);
    const lastClient = new Date(2024, 1, 1, lastHourValue, lastMinuteValue);

    return {
      isOpen: isToggleChecked[j], // Adjust the index to get the correct toggle value
      openTime: openTime.toISOString(),
      closeTime: closeTime.toISOString(),
      lastClient: lastClient.toISOString(),
      maxClients: Number(maxClients[j]), // Adjust the index to get the correct maxClients value
      name: day,
    };
  };

  const handleSave = () => {
    setIsCreateServiceActive(false);
    setIsOptionsCreateActive(false);

    const serviceDays = [];
    let j = 0;
    for (let i = 3; i < 24; i += 3) {
      serviceDays.push(parseDropdownValue(i, j, weekDays[j]));
      j += 1;
    }
    addService(
      new Service(
        new Availability(
          calendarSelectedStart,
          calendarSelectedEnd,
          timeOptionSelected,
          serviceName
        ),
        serviceDays
      )
    );
    resetFields();
  };

  const handleUpdate = () => {
    const serviceDays = [];
    let j = 0;
    for (let i = 3; i < 24; i += 3) {
      serviceDays.push(parseDropdownValue(i, j, weekDays[j]));
      j += 1;
    }

    setServices((prevServices) => {
      const newServices = [...prevServices];
      newServices[isEditServiceActive] = {
        service: {
          name: serviceName,
          status: timeOptionSelected,
          startDate: calendarSelectedStart,
          endDate: calendarSelectedEnd,
        },
        serviceDays,
      };
      return newServices;
    });
    resetFields();
    setIsOptionsCreateActive(false);
    setIsEditServiceActive(-1);
  };

  function setDisplayDays(daysArray, notCreated) {
    //console.log(daysArray)
    //console.log(notCreated)
    let res = "";
    if (notCreated) {
      for (let i = 0; i < daysArray.length; i++) {
        if (daysArray[i]) {
          res += days[i];
          res += ", ";
        }
      }
    } else {
      for (let i = 0; i < daysArray.length; i++) {
        if (daysArray[i].isOpen) {
          res += days[i];
          res += ", ";
        }
      }
    }

    // Remove last comma
    res = res.slice(0, -2);
    return res;
  }

  function setUnselectedDays(service = null) {
    let res = "";

    if (!service) {
      for (let i = 0; i < isToggleChecked.length; i++) {
        if (isToggleChecked[i] === false) {
          res += days[i];
          res += ", ";
        }
      }
    } else {
      for (let i = 0; i < service.serviceDays.length; i++) {
        if (service.serviceDays[i].isOpen === false) {
          res += days[i];
          res += ", ";
        }
      }
    }
    // Remove last comma
    res = res.slice(0, -2);
    return res;
  }

  const handleIsEditServiceActive = (idx) => {
    if (isEditServiceActive === idx) {
      setIsEditServiceActive(-1);
    } else {
      setServiceName(services[idx].service.name);
      setTimeOptionSelected(services[idx].service.status);

      const isOpenArray = services[idx].serviceDays.map((day) => day.isOpen);
      setIsToggleChecked(isOpenArray);
      //console.log(isToggleChecked)

      const initialHours = [
        formatTime(new Date(services[idx].serviceDays[0].openTime)),
        formatTime(new Date(services[idx].serviceDays[0].closeTime)),
        formatTime(new Date(services[idx].serviceDays[0].lastClient)),
      ];
      const hoursArray = [
        ...initialHours,
        ...services.flatMap((service) =>
          service.serviceDays.flatMap((day) => {
            const openTime = formatTime(new Date(day.openTime));
            const closeTime = formatTime(new Date(day.closeTime));
            const lastClientTime = formatTime(new Date(day.lastClient));
            return [openTime, closeTime, lastClientTime];
          })
        ),
      ];
      setDropdownsSelected(hoursArray);
      //console.log(dropdownsSelected)

      setMaxClients([
        services[idx].serviceDays[0].maxClients,
        services[idx].serviceDays[0].maxClients,
        services[idx].serviceDays[1].maxClients,
        services[idx].serviceDays[2].maxClients,
        services[idx].serviceDays[3].maxClients,
        services[idx].serviceDays[4].maxClients,
        services[idx].serviceDays[5].maxClients,
        services[idx].serviceDays[6].maxClients,
      ]);
      //console.log(maxClients)

      setIsEditServiceActive(idx);
      //console.log(isEditServiceActive)
    }
  };

  const handleDropdownSelection = (index, value) => {
    console.log(value);
    if (index === 0 || index === 1 || index === 2) {
      setDropdownsSelected((prevState) => {
        const newState = [...prevState];
        newState[index] = value;
        newState[index + 3] = value;
        newState[index + 2 * 3] = value;
        newState[index + 3 * 3] = value;
        newState[index + 4 * 3] = value;
        newState[index + 5 * 3] = value;
        newState[index + 6 * 3] = value;
        newState[index + 7 * 3] = value;
        return newState;
      });
    } else {
      setDropdownsSelected((prevState) => {
        const newState = [...prevState];
        newState[index] = value;
        return newState;
      });
    }
  };

  const handleDropdownClick = (index) => {
    setIsDropdownActive((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleToggle = (index) => {
    setIsToggleChecked((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const resetFields = () => {
    /* 1st page */
    setServiceName("");
    setTimeOptionSelected(1);
    setIsToggleChecked([true, true, true, true, true, true, true]);
    setDropdownsSelected(Array(24).fill("12:00"));
    setMaxClients([0, 0, 0, 0, 0, 0, 0, 0]);

    /* 2nd page */
    setPeriodName("");
    setStartDate(formattedBeforeDate);
    setEndDate(formattedCurrentDate);
    setPeriodServices("");
    setServiceButtonIsClicked(Array(services.length).fill(false));
  };

  const updateMaxClients = (index, value) => {
    /* index == 0 => master */
    if (index === 0) {
      setMaxClients((prevMaxClients) => {
        const updatedMaxClients = [...prevMaxClients];
        updatedMaxClients[0] = value;
        updatedMaxClients[1] = value;
        updatedMaxClients[2] = value;
        updatedMaxClients[3] = value;
        updatedMaxClients[4] = value;
        updatedMaxClients[5] = value;
        updatedMaxClients[6] = value;
        updatedMaxClients[7] = value;
        return updatedMaxClients;
      });
    } else {
      setMaxClients((prevMaxClients) => {
        const updatedMaxClients = [...prevMaxClients];
        updatedMaxClients[index] = value;
        return updatedMaxClients;
      });
    }
  };
  /* ------------------------------------------------------------------------------------------- */

  /* Periods page functions */
  function addPeriod(newPeriod) {
    setPeriods((prevPeriods) => [...prevPeriods, newPeriod]);
  }

  const handleServiceButtonClick = (index) => {
    setServiceButtonIsClicked((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleSavePeriod = () => {
    setIsCreatePeriodActive(false);
    setIsOptionsCreatePeriodActive(false);
    const servicesArray = stringToArray(periodServices);

    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    addPeriod({
      name: periodName,
      startDate: sDate.toISOString(),
      endDate: eDate.toISOString(),
      serviceNames: servicesArray,
    });
    resetFields();
  };

  const handleIsEditPeriodActive = (idx) => {
    if (isEditPeriodActive === idx) {
      setIsEditPeriodActive(-1);
      resetFields();
    } else {
      setIsEditPeriodActive(idx);

      setPeriodName(periods[idx].name);
      setStartDate(periods[idx].startDate);
      setEndDate(periods[idx].endDate);
      setPeriodServices(periods[idx].serviceNames);
      setServiceButtonIsClicked(
        getServiceButtonIsClicked(periods[idx].serviceNames, services)
      );
    }
  };

  function formatDateForInput(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSaveEditPeriod = () => {
    setIsCreatePeriodActive(false);
    setIsOptionsCreatePeriodActive(false);
    setIsEditPeriodActive(-1);

    periods[isEditPeriodActive].name = periodName;
    periods[isEditPeriodActive].startDate = startDate;
    periods[isEditPeriodActive].endDate = endDate;
    periods[isEditPeriodActive].serviceNames = periodServices;

    resetFields();
  };
  /* ------------------------------------------------------------------------------------------- */

  /* Other functions */
  function getSelectedServicesNames(serviceButtonIsClicked, services) {
    const selectedServiceIndexes = serviceButtonIsClicked.reduce(
      (indexes, value, index) => {
        if (value === true) {
          indexes.push(index);
        }
        return indexes;
      },
      []
    );

    const selectedServiceNames = selectedServiceIndexes.map(
      (index) => services[index].service.name
    );
    return selectedServiceNames;
  }

  function getServiceButtonIsClicked(selectedServiceNames, services) {
    const serviceButtonIsClicked = services.map((service) =>
      selectedServiceNames.includes(service.service.name)
    );
    return serviceButtonIsClicked;
  }

  function stringToArray(string) {
    // Remove leading and trailing whitespace
    const trimmedString = string.trim();

    // Split the string using commas as the separator
    const wordsArray = trimmedString.split(",");

    // Trim each word and remove empty strings
    const filteredArray = wordsArray.map((word) => word.trim()).filter(Boolean);

    return filteredArray;
  }

  function formatTime(date) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const setPageTab = (tab) => {
    if (tab === servicesSelectedTab) return "currentHomeTab";
    else return "";
  };

  const formatDateString = (isoString) => {
    const dateObject = new Date(isoString);
    const formattedDate = dateObject.toLocaleDateString("en-GB"); // Adjust the locale as needed
    return formattedDate;
  };

  return (
    <div className="services">
      {isLoading && <Loading />}

      <div className={`w-full h-full ${isLoading ? "invisible" : undefined}`}>
        <h1>Serviços</h1>

        {!isOptionsCreateActive && (
          <PageTabs
            tabs={tabs}
            setClass={setPageTab}
            setTab={setServicesSelectedTab}
          />
        )}

        {servicesSelectedTab === "Serviços" && (
          <ServicesPage
            isCreateServiceActive={isCreateServiceActive}
            setIsCreateServiceActive={setIsCreateServiceActive}
            isOptionsCreateActive={isOptionsCreateActive}
            setIsOptionsCreateActive={setIsOptionsCreateActive}
            isEditServiceActive={isEditServiceActive}
            setIsEditServiceActive={setIsEditServiceActive}
            timeOptionSelected={timeOptionSelected}
            setTimeOptionSelected={setTimeOptionSelected}
            dropdownsSelected={dropdownsSelected}
            isDropdownActive={isDropdownActive}
            isToggleChecked={isToggleChecked}
            maxClients={maxClients}
            calendarSelectedStart={calendarSelectedStart}
            setCalendarSelectedStart={setCalendarSelectedStart}
            calendarSelectedEnd={calendarSelectedEnd}
            setCalendarSelectedEnd={setCalendarSelectedEnd}
            isCalendarActive={isCalendarActive}
            setIsCalendarActive={setIsCalendarActive}
            serviceName={serviceName}
            setServiceName={setServiceName}
            services={services}
            setServices={setServices}
            setDisplayDays={setDisplayDays}
            setUnselectedDays={setUnselectedDays}
            timeSlot={timeSlot}
            handleIsEditServiceActive={handleIsEditServiceActive}
            handleDropdownSelection={handleDropdownSelection}
            handleDropdownClick={handleDropdownClick}
            updateMaxClients={updateMaxClients}
            handleToggle={handleToggle}
            resetFields={resetFields}
            handleUpdate={handleUpdate}
            handleSave={handleSave}
            formatDateString={formatDateString}
          />
        )}

        {servicesSelectedTab === "Período de Encerramento" && (
          <ClosingPeriods
            isCreatePeriodActive={isCreatePeriodActive}
            setIsCreatePeriodActive={setIsCreatePeriodActive}
            isOptionsCreatePeriodActive={isOptionsCreatePeriodActive}
            setIsOptionsCreatePeriodActive={setIsOptionsCreatePeriodActive}
            periodName={periodName}
            setPeriodName={setPeriodName}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            periodServices={periodServices}
            serviceButtonIsClicked={serviceButtonIsClicked}
            isEditPeriodActive={isEditPeriodActive}
            setIsEditPeriodActive={setIsEditPeriodActive}
            periods={periods}
            setPeriods={setPeriods}
            services={services}
            handleServiceButtonClick={handleServiceButtonClick}
            handleSavePeriod={handleSavePeriod}
            handleIsEditPeriodActive={handleIsEditPeriodActive}
            formatDateForInput={formatDateForInput}
            handleSaveEditPeriod={handleSaveEditPeriod}
            formatDateString={formatDateString}
          />
        )}

        {servicesSelectedTab === "Definições" && (
          <ServiceSettings
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            automaticAcception={automaticAcception}
            setAutomaticAcception={setAutomaticAcception}
            seatAmount={seatAmount}
            setSeatAmount={setSeatAmount}
            selectedTabBigDropdown={selectedTabBigDropdown}
            setSelectedTabBigDropdown={setSelectedTabBigDropdown}
            minutesDropdown={minutesDropdown}
            setMinutesDropdown={setMinutesDropdown}
          />
        )}
      </div>
    </div>
  );
};

export default Services;
