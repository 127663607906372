import menu from '../../utils/logos/menu.svg';

import './dish.css';

const Dish = ({ heading, description, price, onClick, image, sort=true }) => {
  return (
    <div className='dish' onClick={onClick}>
        <img src={menu} alt="sort" style={sort ? {} : {display: "none"}} />
        <img src={image} alt="image" className='dishPhoto' />
        <div className='dishInfo'>
            <h1>{heading}</h1>
            <p>{description}</p>
        </div>
        <p className='dishPrice'>{price}€</p>
    </div>
  )
}

export default Dish;