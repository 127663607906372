import whiteHome from './logos/whiteHome.svg';
import whiteStar from './logos/whiteStar.svg';
import card from './logos/card.svg';
import fork from './logos/fork.svg';
import servicesSidebar from './logos/servicesSidebar.svg';
import userSidebar from './logos/userSidebar.svg';
import picturesSidebar from './logos/picturesSidebar.svg';
import infoSidebar from './logos/infoSidebar.svg';

import blackHome from './logos/blackHome.svg';
import blackStar from './logos/blackStar.svg';
import filledCard from './logos/filledCard.svg';
import filledFork from './logos/filledFork.svg';
import selectedServicesSidebar from './logos/selectedServicesSidebar.svg';
import selectedUserSidebar from './logos/selectedUserSidebar.svg';
import selectedPicturesSidebar from './logos/selectedPicturesSidebar.svg';
import selectedInfoSidebar from './logos/selectedInfoSidebar.svg';




export const icons = [
    {id: "home", title: "Home", icon: [whiteHome, blackHome]},
    {id: "feedback", title: "Feedback", icon: [whiteStar, blackStar]},
    {id: "menu", title: "Ementa", icon: [fork, filledFork]},
    {id: "services", title: "Serviços", icon: [servicesSidebar, selectedServicesSidebar]},
    {id: "pictures", title: "Fotografias", icon: [picturesSidebar, selectedPicturesSidebar]},
    {id: "payments", title: "Pagamentos", icon: [card, filledCard]},
    {id: "users", title: "Usuários", icon: [userSidebar, selectedUserSidebar]},
    {id: "info", title: "Informações", icon: [infoSidebar, selectedInfoSidebar]}
];