import "./image.css";
import { useState } from "react";
import deleteButton from "../../utils/logos/deleteButton.png";
import editButton from "../../utils/logos/editButton.png";
import editSelectedButton from "../../utils/logos/editSelectedButton.png";
import isVisibleBtn from "../../utils/logos/isVisibleBtn.png";
import isNotVisibleBtn from "../../utils/logos/isNotVisibleBtn.png";
import menu from "../../utils/logos/menu.svg";

const Image = ({
  image,
  isEditActive,
  setIsEditActive,
  idx,
  onClick,
  updateDescription,
  updateVisibilityState,
  sort = true,
  editImage,
}) => {
  const [newDescription, setNewDescription] = useState("");
  const inputId = `file-input-${idx}`;

  return (
    <div className="Image">
      <div className="leftSide">
        <img
          className="sort"
          src={menu}
          alt="sort"
          style={/*TODO*/ sort ? {} : { display: "none" }}
        />
        <label
          htmlFor={inputId}
          className={
            image.isDish && !image.isVisible ? "opacity-40" : undefined
          }
        >
          <input
            type="file"
            id={inputId}
            className="hidden"
            onChange={(e) => editImage(e.target.files[0], image.pictureId)}
          />
          <div className="dishPhotoDiv relative group">
            <img
              src={image.pictureURL}
              className="dishPhoto"
              alt="restaurant image"
            />

            <img
              src={editButton}
              className="w-7 absolute inset-0 m-auto opacity-0 group-hover:opacity-100 cursor-pointer"
              style={{
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </label>

        {isEditActive ? (
          <input
            className="descriptionInput w-60 rounded-full indent-3 bg-gray-100"
            placeholder={image.pictureName}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        ) : (
          <h1
            className={
              image.isDish && !image.isVisible ? "opacity-40" : undefined
            }
          >
            {image.pictureName}
          </h1>
        )}
      </div>

      {image.isDish && image.pictureName !== "logotipo-restaurante" && (
        <div className="rightSide">
          <img
            onClick={() => updateVisibilityState(idx - 1)}
            src={image.isVisible ? isVisibleBtn : isNotVisibleBtn}
            alt="visible"
          />
        </div>
      )}

      {!image.isDish && image.pictureName !== "logotipo-restaurante" && (
        <div className="rightSide">
          <img
            onClick={
              isEditActive
                ? () => {
                    setIsEditActive(0);
                    updateDescription(idx - 1, newDescription);
                  }
                : () => setIsEditActive(idx)
            }
            src={isEditActive ? editSelectedButton : editButton}
            alt="options"
          />
          <img
            onClick={onClick}
            src={deleteButton}
            alt="delete"
            className="ml-[2.813rem]"
          />
        </div>
      )}
    </div>
  );
};

export default Image;
