import "./users.css";
import plus from "../../utils/logos/plus.svg";
import optionsButton from "../../utils/logos/optionsButton.png";
import crossButton from "../../utils/logos/crossButton.png";
import React, { useState, useEffect } from "react";
import { UsersPopup, Loading } from "../../components";
import { getAllUsersByRestaurantIdEndpoint } from "../../backendFunctions";
import { delay } from "../../utils/utils.js";

const Users = ({ restaurant, authorizationToken }) => {
  const [loggedUser, setLoggedUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({});
  const [password, setPassword] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onClose = () => {
    setPassword(null);
    setSelectedRow(null);
    setFormData(null);
    setSelectedUser(null);
  };

  useEffect(() => {
    const fetchData = getAllUsersByRestaurantIdEndpoint(
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log("todos os users:", response.data.data);
        setUsers(response.data.data);
        //setLoggedUser(restaurant.);
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([fetchData, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  async function encrypt(password, data) {
    const salt = window.crypto.getRandomValues(new Uint8Array(16));

    const encoder = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
      "raw",
      encoder.encode(password),
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"]
    );

    const key = await window.crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: salt,
        iterations: 100000,
        hash: "SHA-256",
      },
      keyMaterial,
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"]
    );

    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await window.crypto.subtle.encrypt(
      { name: "AES-GCM", iv: iv },
      key,
      encoder.encode(data)
    );

    const encryptedDataString = arrayBufferToBase64(encryptedData);
    const ivString = arrayBufferToBase64(iv);
    const saltString = arrayBufferToBase64(salt);

    return {
      encryptedData: encryptedDataString,
      salt: saltString,
      iv: ivString,
    };
  }

  function arrayBufferToBase64(buffer) {
    const binary = new Uint8Array(buffer);
    let base64 = "";
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

    for (let i = 0; i < binary.length; i += 3) {
      const a = binary[i];
      const b = binary[i + 1];
      const c = binary[i + 2];

      const triplet = (a << 16) | (b << 8) | c;

      base64 += chars[(triplet >> 18) & 63];
      base64 += chars[(triplet >> 12) & 63];
      base64 += chars[(triplet >> 6) & 63];
      base64 += chars[triplet & 63];
    }

    const padding = (3 - (binary.length % 3)) % 3;

    return base64.slice(0, base64.length - padding) + "=".repeat(padding);
  }

  /*

  if (users < 1) {
    return (
      <div className='users'>
        <div className='landing-page'>
          <h1>Usuários</h1>
          <p className='top-note'>Dê acesso à sua equipa as páginas do Twinkle Manager adicionando utilizadores e funcionalidades   </p>
          <button className='create-new-user-button' onClick={handleFormPopup} style={{ background: formPopup ? '#77b9b4' : '#E9E9E9' }}>
            <img src={plus} alt="plus" /><p>Criar novo usuário</p>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className='users'>
        <div className='landing-page'>
          <h1>Usuários</h1>
          <p className='top-note'>Dê acesso à sua equipa as páginas do Twinkle Manager adicionando utilizadores e funcionalidades   </p>
          <button className='create-new-user-button' onClick={handleFormPopup} style={{ background: formPopup ? '#77b9b4' : '#E9E9E9' }}>
            <img src={plus} alt="plus" /><p>Criar novo usuário</p>
          </button>
          {formPopup && 
            <UsersPopup 
              selectedRow={selectedRow}
              formData={formData} 
              setFormData={setFormData} 
              setPassword={setPassword} 
              handleButtonClick={handleButtonClick} 
              handleSubmit={handleSubmit} 
              dutyData={dutyData}
              clickedDuty={clickedDuty}
              updateUser={updateUser} 
              onClose={onClose}
              deleteAccount={deleteAccount}
            />
          }
          <table>
            <thead>
              <tr className='thead-tr'>
                {heading.map(heading => (
                  <th key={heading}>{heading}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((map) => (
                <React.Fragment key={map.id}>
                  <tr className='tbody-tr'>
                    <td style={{ marginLeft: '4.75rem', width: '12.2rem' }}>{map.name}</td>
                    <td style={{ width: '12.3rem' }}>{map.email}</td>
                    <td style={{ width: '12.8rem' }}>
                      <button className='duty-button'>{map.permissionType}</button>
                    </td>
                    <td style={{ width: '11.8rem' }}>{map.isAuthenticationCreated ? 'Sim' : 'Não'}</td>
                    <td className='edit-img'><img src={selectedRow && selectedRow.id === map.id ? crossButton : optionsButton} alt="options" onClick={selectedRow && selectedRow.id === map.id ? onClose : () => handleEdit(map)}/></td>
                  </tr>
                  <tr>
                    <td>
                        {selectedRow && selectedRow.id === map.id && 
                          <UsersPopup 
                            selectedRow={selectedRow}
                            formData={formData} 
                            setFormData={setFormData} 
                            setPassword={setPassword} 
                            handleButtonClick={handleButtonClick} 
                            handleSubmit={handleSubmit} 
                            dutyData={dutyData}
                            clickedDuty={clickedDuty}
                            updateUser={updateUser} 
                            onClose={onClose} 
                            deleteAccount={deleteAccount}
                          />
                        }
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  */

  const selectUser = (user) => {
    console.log("selectedUser: ", user);
    setSelectedUser((prevSelectedUser) =>
      prevSelectedUser === user ? null : user
    );
    setFormData(user);
  };

  const updateUser = () => {
    console.log(formData);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    onClose();
  };

  return (
    <div className="users">
      {isLoading && <Loading />}
      <div className={`w-full h-full ${isLoading ? "invisible" : undefined}`}>
        <div className="landing-page">
          <h1>Usuários</h1>
          <h2>Twinkle Operations</h2>
          <p>
            Veja o nome de utilizador ou atualize a palavra-passe do Twinkle
            Operations.
          </p>
          <table style={{ marginBottom: "3.125rem" }}>
            <thead>
              <tr className="thead-tr">
                <th className="username-header">Nome de utilizador</th>
              </tr>
            </thead>
            <tbody>
              <tr className="tbody-tr">
                <td className="username">{users ? users[0].username : null}</td>
                <td>
                  <button className="passwordChanger" onClick={() => {}}>
                    Alterar a palavra-passe
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Twinkle Manager</h2>
          <p>
            Dê acesso à sua equipa às páginas do Twinkle Manager adicionando
            utilizadores e atribuindo funções.
          </p>
          <button className="addUser">
            <img src={plus} alt="plus" />
            <span>Adicionar usuário</span>
          </button>
          <table>
            <thead>
              <tr className="thead-tr">
                <th className="username-header">Nome</th>
                <th>Função</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users
                ? users.map((user, index) => (
                    <React.Fragment key={index}>
                      <tr className="tbody-tr">
                        <td className="username">{user.username}</td>
                        <td className="function">
                          <button className="function-btn">
                            {user.function}
                          </button>
                        </td>
                        <td className="editUser">
                          <img
                            src={optionsButton}
                            alt="editUser"
                            onClick={() => {
                              selectUser(user);
                            }}
                          />
                        </td>
                      </tr>
                      {selectedUser && selectedUser === user && (
                        <tr>
                          <td colSpan="3">
                            <UsersPopup
                              selectedUser={selectedUser}
                              formData={formData}
                              setFormData={setFormData}
                              handleSubmit={handleSubmit}
                              updateUser={updateUser}
                              onClose={onClose}
                              deleteAccount={selectedUser} // Assuming this should be deleteAccount function
                            />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Users;
