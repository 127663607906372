import './searchBar.css';

import search from '../../utils/logos/search.svg';

const SearchBar = ({ width, text, margin, background }) => {
  return (
    <div className="searchBar" style={{ width: width, margin: margin, background: background }}>
        <img src={search} alt="search" />
        <input type="text" className="inputss" placeholder={text} style={{ background: background }}/>
    </div>
  )
}

export default SearchBar