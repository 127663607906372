import { SearchBar, Dish, DishTypeButtons } from '../../components';
import plus from '../../utils/logos/plus.svg';
import whitePlus from '../../utils/logos/whitePlus.svg';
import crossButton from '../../utils/logos/crossButton.png';
import minus from '../../utils/logos/minus.svg';
import { getDishByIdItemListEndpoint, getDrinkByIdItemListEndpoint } from '../../backendFunctions';



const ItemList = ({ itemTypeSelected, setItemTypeSelected, itemList, setItemList, itemVariations, setItemVariations, isAddItemActive, setIsAddItemActive, addNewItem, setAddNewItem, isEditItemSlideInActive, setIsEditItemSlideInActive, addMiniDescription, uploadImage, caracteristics, handleCaracteristicClick, drinkCaracteristics, resetDish, saveNewItem, deleteItem, updateItem, authorizationToken, isAddDrinkActive, setIsAddDrinkActive, drinks, newDrink, setNewDrink, itemDrinkVariations, setItemDrinkVariations, isEditDrinkActive, setIsEditDrinkActive }) => {
    
    const getItemById = (type, id, idx) => {
        if (type === "dish") {
            getDishByIdItemListEndpoint(id, authorizationToken).then(response => {
                console.log(response.data);
                setAddNewItem(response.data.data);
                setItemVariations(response.data.data.prices)
                setIsEditItemSlideInActive(idx);
            })
            .catch(error => {
                console.error(error);
            });
        }
        else if (type === "drink") {
            getDrinkByIdItemListEndpoint(id, authorizationToken).then(response => {
                console.log(response.data);
                setNewDrink(response.data.data);
                setItemDrinkVariations(response.data.data.prices)
                setIsEditDrinkActive(idx);
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
    
    return (
        <div className='ementaPage listPage'>
            <h1 className="pageTitle">Lista de artigos</h1>
            <DishTypeButtons dishTypeSelected={itemTypeSelected} setDishTypeSelected={setItemTypeSelected} categories={false} />

            {/*TODO<SearchBar width="54.25rem" text="Pesquisar no arquivo" margin="1.875rem 0 0 3.125rem" background="#E9E9E9" />*/}

            <div className="categoryDiv">
                <button onClick={itemTypeSelected === "comida" ? () => setIsAddItemActive(!isAddItemActive) : () => setIsAddDrinkActive(true)} style={isAddItemActive ? {background: "#77B9B4"} : {}} className='addItem'><img src={plus} alt="addItem" /><span>Adicionar item</span></button>

                { itemList && itemTypeSelected === "comida" && itemList.map((dish, idx) => (
                    <div className="dishDiv" key={idx}>
                        <Dish heading={dish.dishName} description={dish.dishDescription} price={dish.prices[0].price} image={dish.dishImage} onClick={() => getItemById("dish", dish.dishId, idx)} sort={false} />
                    </div>
                ))}

                { drinks && itemTypeSelected === "bebida" && drinks.map((drink, idx) => (
                    <div className="dishDiv" key={idx}>
                        <Dish heading={drink.drinkName} description={drink.drinkDescription} price={drink.prices[0].price} image={drink.drinkImage} onClick={() => getItemById("drink", drink.drinkId, idx)} sort={false} />
                    </div>
                ))}
            </div>


            { isAddItemActive &&
                <div className='slideIn add-dish'>
                    <h1>Nome Prato</h1>
                    <div className='inputs'>
                        <div className='name'>
                            <h2>Nome</h2>
                            <input onChange={(e) => setAddNewItem((prev) => ({...prev, dishName: e.target.value}))} placeholder='Nome item'/>
                        </div>
                        <div className='priceContainer'>
                            { itemVariations.map((dish, idx) => (
                                <div className='priceDiv' key={idx}>
                                    <div className='price'>
                                        <span>€</span>
                                        <input value={itemVariations[idx].price === 0 ? "" : itemVariations[idx].price.toString()} onChange={(e) => setItemVariations(addMiniDescription(itemVariations, idx, "price", Number(e.target.value)))} type="number" />
                                    </div>
                                    <button onClick={() => {if (itemVariations.length === idx + 1) {setItemVariations((prev) => ([...prev, {price: 0, description: ""}]))} else {setItemVariations(prevArr => idx >= 0 && idx < prevArr.length ? [...prevArr.slice(0, idx), ...prevArr.slice(idx + 1)] : prevArr)}}}><img src={itemVariations.length === idx + 1 ? whitePlus : minus} /></button>
                                    <input value={itemVariations[idx].description} onChange={(e) => setItemVariations(addMiniDescription(itemVariations, idx, "description", e.target.value))} style={itemVariations.length === 1 ? {display: "none"} : {}} placeholder="Descrição" className='secondaryDescription' />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='description'>
                        <h2>Descrição</h2>
                        <textarea onChange={(e) => setAddNewItem((prev) => ({...prev, dishDescription: e.target.value}))} placeholder='Descrição prato...'/>
                    </div>

                    <div className='accompaniments'>
                        <div className='title-button'>
                            <h2>Acompanhamentos</h2>
                            <button onClick={() => alert("In Construction")}><img src={plus} alt="plus" /><span>Adicionar acompanhamento</span></button>
                        </div>
                        <p>Adicione um acompanhamento com identidade a partir da sua lista de pratos</p>
                    </div>

                    <div className='dishOptions'>
                        <h2>Opções</h2>
                        <button onClick={() => alert("In Construction")}><img src={plus} alt="plus" /><span>Adicionar opções</span></button>
                    </div>

                    <div className='photos'>
                        <h2>Fotos</h2>
                        <div className='album'>
                            { addNewItem.dishImage && 
                                <img className='photo' src={addNewItem.dishImage} alt="dish image" />
                            }
                            <label htmlFor="file"><img className='addPhotoBtn' src={crossButton} /></label>
                            <input type="file" id="file" onChange={(e) => {uploadImage(e.target.files[0], "item")}} />
                        </div>
                    </div>

                    <div className='caracteristics'>
                        <h2>Características</h2>
                        <div className='row'>
                            { caracteristics.map((caracteristic, idx) => (
                                <button onClick={() => handleCaracteristicClick(caracteristic, 1)} className='cat' style={addNewItem.dishCaracteristics.includes(caracteristic) ? {background: "#77B9B4"} : {}} key={idx}>
                                    <p>{caracteristic}</p>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className='code'>
                        <div className='firstLine'>
                            <h2>Código</h2>
                            <input onChange={(e) => setAddNewItem((prev) => ({...prev, dishCode: e.target.value}))} />
                        </div>
                        <p>Adicione um código ao prato (não obrigatório)</p>
                    </div>

                    <div className='buttons'>
                        <button onClick={() => {setIsAddItemActive(false); resetDish("item"); setItemVariations([{price: 0, description: ""}])}} className='cancel'>Cancelar</button>
                        <button onClick={() => saveNewItem("item")} className='save'>Guardar</button>
                    </div>
                </div>
            }

            { isEditItemSlideInActive !== -1 &&
                <div className='slideIn add-dish'>
                    <h1>Nome Prato</h1>
                    <div className='inputs'>
                        <div className='name'>
                            <h2>Nome</h2>
                            <input value={addNewItem.dishName} onChange={(e) => setAddNewItem((prev) => ({...prev, dishName: e.target.value}))} placeholder='Nome item'/>
                        </div>
                        <div className='priceContainer'>
                            { itemVariations.map((dish, idx) => (
                                <div className='priceDiv' key={idx}>
                                    <div className='price'>
                                        <span>€</span>
                                        <input value={itemVariations[idx].price === 0 ? "" : itemVariations[idx].price.toString()} onChange={(e) => setItemVariations(addMiniDescription(itemVariations, idx, "price", Number(e.target.value)))} type="number" />
                                    </div>
                                    <button onClick={() => {if (itemVariations.length === idx + 1) {setItemVariations((prev) => ([...prev, {price: 0, description: ""}]))} else {setItemVariations(prevArr => idx >= 0 && idx < prevArr.length ? [...prevArr.slice(0, idx), ...prevArr.slice(idx + 1)] : prevArr)}}}><img src={itemVariations.length === idx + 1 ? whitePlus : minus} /></button>
                                    <input value={itemVariations[idx].description} onChange={(e) => setItemVariations(addMiniDescription(itemVariations, idx, "description", e.target.value))} style={itemVariations.length === 1 ? {display: "none"} : {}} placeholder="Descrição" className='secondaryDescription' />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='description'>
                        <h2>Descrição</h2>
                        <textarea value={addNewItem.dishDescription} onChange={(e) => setAddNewItem((prev) => ({...prev, dishDescription: e.target.value}))} />
                    </div>

                    <div className='accompaniments'>
                        <div className='title-button'>
                            <h2>Acompanhamentos</h2>
                            <button onClick={() => alert("In Construction")}><img src={plus} alt="plus" /><span>Adicionar acompanhamento</span></button>
                        </div>
                        <p>Adicione um acompanhamento com identidade a partir da sua lista de pratos</p>
                    </div>

                    <div className='dishOptions'>
                        <h2>Opções</h2>
                        <button onClick={() => alert("In Construction")}><img src={plus} alt="plus" /><span>Adicionar opções</span></button>
                    </div>

                    <div className='photos'>
                        <h2>Fotos</h2>
                        <div className='album'>
                            { addNewItem.dishImage &&
                                <img className='photo' src={addNewItem.dishImage} alt="dish image" />
                            }
                            <label htmlFor="file"><img className='addPhotoBtn' src={crossButton} /></label>
                            <input type="file" id="file" onChange={(e) => {uploadImage(e.target.files[0], "menu")}} />
                        </div>
                    </div>

                    <div className='caracteristics'>
                        <h2>Características</h2>
                        <div className='row'>
                            { caracteristics.map((caracteristic, idx) => (
                                <button onClick={() => handleCaracteristicClick(caracteristic, 1)} className='cat' style={addNewItem.dishCaracteristics.includes(caracteristic) ? {background: "#77B9B4"} : {}} key={idx}>
                                    <p>{caracteristic}</p>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className='code'>
                        <div className='firstLine'>
                            <h2>Código</h2>
                            <input value={addNewItem.dishCode ? addNewItem.dishCode : ""} onChange={(e) => setAddNewItem((prev) => ({...prev, dishCode: e.target.value}))} />
                        </div>
                        <p>Adicione um código ao prato (não obrigatório)</p>
                    </div>

                    <div className='buttons'>
                        <button onClick={() => {setIsEditItemSlideInActive(-1); resetDish("item"); setItemVariations([{price: 0, description: ""}])}} className='cancel'>Cancelar</button>
                        <button onClick={() => {updateItem("comida")}} className='save'>Guardar</button>
                    </div>

                    <p onClick={() => deleteItem(itemTypeSelected)} className='deleteDish'>Eliminar prato</p>

                </div>
            }

            { isAddDrinkActive &&
                <div className='slideIn add-dish'>
                    <h1>Nova Bebida</h1>
                    <div className='inputs'>
                        <div className='name'>
                            <h2>Nome</h2>
                            <input onChange={(e) => setNewDrink((prev) => ({...prev, drinkName: e.target.value}))} placeholder='Nome item'/>
                        </div>
                        <div className='priceContainer'>
                            { itemDrinkVariations.map((drink, idx) => (
                                <div className='priceDiv' key={idx}>
                                    <div className='price'>
                                        <span>€</span>
                                        <input value={itemDrinkVariations[idx].price === 0 ? "" : itemDrinkVariations[idx].price.toString()} onChange={(e) => setItemDrinkVariations(addMiniDescription(itemDrinkVariations, idx, "price", Number(e.target.value)))} type="number" />
                                    </div>
                                    <button onClick={() => {if (itemDrinkVariations.length === idx + 1) {setItemDrinkVariations((prev) => ([...prev, {price: 0, description: ""}]))} else {setItemDrinkVariations(prevArr => idx >= 0 && idx < prevArr.length ? [...prevArr.slice(0, idx), ...prevArr.slice(idx + 1)] : prevArr)}}}><img src={itemDrinkVariations.length === idx + 1 ? whitePlus : minus} /></button>
                                    <input value={itemDrinkVariations[idx].description} onChange={(e) => setItemDrinkVariations(addMiniDescription(itemDrinkVariations, idx, "description", e.target.value))} style={itemDrinkVariations.length === 1 ? {display: "none"} : {}} placeholder="Descrição" className='secondaryDescription' />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='description'>
                        <h2>Descrição</h2>
                        <textarea onChange={(e) => setNewDrink((prev) => ({...prev, drinkDescription: e.target.value}))} placeholder='Descrição prato...'/>
                    </div>

                    <div className='dishOptions'>
                        <h2>Opções</h2>
                        <button onClick={() => alert("In Construction")}><img src={plus} alt="plus" /><span>Adicionar opções</span></button>
                    </div>

                    <div className='photos'>
                        <h2>Fotos</h2>
                        <div className='album'>
                            { newDrink.drinkImage && 
                                <img className='photo' src={newDrink.drinkImage} alt="dish image" />
                            }
                            <label htmlFor="file"><img className='addPhotoBtn' src={crossButton} /></label>
                            <input type="file" id="file" onChange={(e) => {uploadImage(e.target.files[0], "drinkItem")}} />
                        </div>
                    </div>

                    <div className='caracteristics'>
                        <h2>Características</h2>
                        <div className='row'>
                            { drinkCaracteristics.map((caracteristic, idx) => (
                                <button onClick={() => handleCaracteristicClick(caracteristic, 4)} className='cat' style={newDrink.drinkCaracteristics.includes(caracteristic) ? {background: "#77B9B4"} : {}} key={idx}>
                                    <p>{caracteristic}</p>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className='buttons'>
                        <button onClick={() => {setIsAddDrinkActive(false); resetDish("itemDrink"); setItemDrinkVariations([{price: 0, description: ""}])}} className='cancel'>Cancelar</button>
                        <button onClick={() => saveNewItem("drinkItem")} className='save'>Guardar</button>
                    </div>
                </div>
            }

            { isEditDrinkActive !== -1 &&
                <div className='slideIn add-dish'>
                    <h1>Nome Bebida</h1>
                    <div className='inputs'>
                        <div className='name'>
                            <h2>Nome</h2>
                            <input value={newDrink.drinkName} onChange={(e) => setNewDrink((prev) => ({...prev, drinkName: e.target.value}))} placeholder='Nome item'/>
                        </div>
                        <div className='priceContainer'>
                            { itemDrinkVariations.map((drink, idx) => (
                                <div className='priceDiv' key={idx}>
                                    <div className='price'>
                                        <span>€</span>
                                        <input value={itemDrinkVariations[idx].price === 0 ? "" : itemDrinkVariations[idx].price.toString()} onChange={(e) => setItemDrinkVariations(addMiniDescription(itemDrinkVariations, idx, "price", Number(e.target.value)))} type="number" />
                                    </div>
                                    <button onClick={() => {if (itemDrinkVariations.length === idx + 1) {setItemDrinkVariations((prev) => ([...prev, {price: 0, description: ""}]))} else {setItemDrinkVariations(prevArr => idx >= 0 && idx < prevArr.length ? [...prevArr.slice(0, idx), ...prevArr.slice(idx + 1)] : prevArr)}}}><img src={itemDrinkVariations.length === idx + 1 ? whitePlus : minus} /></button>
                                    <input value={itemDrinkVariations[idx].description} onChange={(e) => setItemDrinkVariations(addMiniDescription(itemDrinkVariations, idx, "description", e.target.value))} style={itemDrinkVariations.length === 1 ? {display: "none"} : {}} placeholder="Descrição" className='secondaryDescription' />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='description'>
                        <h2>Descrição</h2>
                        <textarea value={newDrink.drinkDescription} onChange={(e) => setNewDrink((prev) => ({...prev, drinkDescription: e.target.value}))} />
                    </div>

                    <div className='dishOptions'>
                        <h2>Opções</h2>
                        <button onClick={() => alert("In Construction")}><img src={plus} alt="plus" /><span>Adicionar opções</span></button>
                    </div>

                    <div className='photos'>
                        <h2>Fotos</h2>
                        <div className='album'>
                            { newDrink.drinkImage &&
                                <img className='photo' src={newDrink.drinkImage} alt="dish image" />
                            }
                            <label htmlFor="file"><img className='addPhotoBtn' src={crossButton} /></label>
                            <input type="file" id="file" onChange={(e) => {uploadImage(e.target.files[0], "drinkItem")}} />
                        </div>
                    </div>

                    <div className='caracteristics'>
                        <h2>Características</h2>
                        <div className='row'>
                            { drinkCaracteristics.map((caracteristic, idx) => (
                                <button onClick={() => handleCaracteristicClick(caracteristic, 4)} className='cat' style={newDrink.drinkCaracteristics.includes(caracteristic) ? {background: "#77B9B4"} : {}} key={idx}>
                                    <p>{caracteristic}</p>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className='buttons'>
                        <button onClick={() => {setIsEditDrinkActive(-1); resetDish("itemDrink"); setItemDrinkVariations([{price: 0, description: ""}])}} className='cancel'>Cancelar</button>
                        <button onClick={() => {updateItem("bebida")}} className='save'>Guardar</button>
                    </div>

                    <p onClick={() => deleteItem(itemTypeSelected)} className='deleteDish'>Eliminar prato</p>

                </div>
            }

        </div>
    )
}

export default ItemList