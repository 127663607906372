import './ratingStars.css';

import ratingStar from '../../utils/logos/ratingStar.svg';
import filledStar from '../../utils/logos/filledStar.svg';
import halfFilledStar from '../../utils/logos/halfFilledStar.svg';

const RatingStars = ({ value }) => {
  return (
    <div className='RatingStars'>
        <img src={value === 0.5 ? halfFilledStar : value >= 1 ? filledStar : ratingStar} alt="star"/>
        <img src={value === 1.5 ? halfFilledStar : value >= 2 ? filledStar : ratingStar} alt="star"/>
        <img src={value === 2.5 ? halfFilledStar : value >= 3 ? filledStar : ratingStar} alt="star"/>
        <img src={value === 3.5 ? halfFilledStar : value >= 4 ? filledStar : ratingStar} alt="star"/>
        <img src={value === 4.5 ? halfFilledStar : value === 5 ? filledStar : ratingStar} alt="star"/>
    </div>
  )
}

export default RatingStars