import './usersPopup.css';
import React from 'react';

const roles = ['Administrador', 'Gestor', 'Financeiro', 'Sommelier'];

const UsersPopup = ({ selectedUser, formData, setFormData, handleSubmit, updateUser, onClose, deleteAccount }) => {
    return (
        <div className="popup">
            <div className='popup-alignment'>
                <div className='name'>
                    <h3>Nome: </h3>
                    <span>{formData.username}</span>
                </div>
                <div className='roles'>
                    <h3>Função:</h3>
                    <div className='role-buttons'>
                        {roles.map((role, index) => (
                            <button className='role-btn' type='button' onClick={() => setFormData({ ...formData, function: role })} style={{ background: formData.function === role ? '#248E86' : '#E9E9E9', color: formData.function === role ? '#FFFFFF' : '#000000' }} key={index}>
                                {role}
                            </button>
                        ))}
                    </div>
                </div>
                <div className='label-save_cancel'>
                    <button className='cancel' onClick={onClose}>Cancelar</button>
                    <button className='save' onClick={updateUser}>Guardar</button>
                </div>
                <span className='delete-user' onClick={() => {deleteAccount(selectedUser)}}>
                    Eliminar usuário
                </span>
            </div>
        </div>
    )
}

export default UsersPopup;