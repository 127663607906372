import { useState } from 'react';
import './adminAccess.css';

const AdminAccess = ({ onSuccess }) => {
  const [password, setPassword] = useState('');

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    // Compare entered password with master password
    if (password === '123') {
      onSuccess(true);
    } else {
      alert('Incorrect password');
      setPassword("");
    }
  };

  return (
    <div className='adminAccess'>
      <form className='adminForm' onSubmit={handleSubmit}>
        <input
          className='password'
          type="password"
          placeholder="Enter master password"
          value={password}
          onChange={handlePasswordChange}
        />
        <button className="submitBtn" type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AdminAccess;
