import './multiLayerDropdown2.css';
import blackArrow from '../../utils/logos/blackArrow.svg';
import arrow from '../../utils/logos/arrow.svg';
import { useState, useEffect } from 'react';


function MultiLayerDropdown2({ tabs, selected, setSelected, isActive, setIsActive, order, width }) {

    const [isFirstLayerSelected, setIsFirstLayerSelected] = useState(-1);  /* -1 -> none */
    const [firstLayerSelected, setFirstLayerSelected] = useState("");

    useEffect(() => {
        if (firstLayerSelected === tabs[1].main) {
            setSelected(order, {main: tabs[1].main, secondary: ""});
            setIsActive(order, false);
            setFirstLayerSelected("");
        }
    }, [firstLayerSelected]);

    useEffect(() => {
        if (isFirstLayerSelected !== -1)
            setSelected(order, {main: selected.main, secondary: ""});
    }, [isFirstLayerSelected]);

    const secondLayerSelected = (idx) => {
        if (selected.secondary === tabs[isFirstLayerSelected].secondary[idx])
            return "selected";
        else
            return "";
    };

    return (
        <div className="multiLayerDropdown2">
            <div className="dropdown-btn" onClick={() => setIsActive(order, !isActive)} style={ isActive ? { backgroundColor: "#2F2F2F", color: "#FFFFFF", width: width } : { width: width }} >
                <p style={isActive ? {color: "#FFFFFF"} : {}}>{isFirstLayerSelected !== -1 ? selected.main : "Adicionar prato"}</p>
                <img src={isActive ? arrow : blackArrow} alt="arrow" style={isActive ? { transform: "rotate(180deg)" } : {}} />
            </div>

            {isActive &&
                <div className="dropdown-content" style={{ width: width }}>
                    {tabs.map((item, idx) => (
                        <div key={idx}>
                            <div className="dropdown-item" style={isFirstLayerSelected === idx ? {background: "#2F2F2F", width: width} : { width: width }} >
                                <p onClick={idx !== 1 ? () => {setIsFirstLayerSelected(idx)} : () => {setFirstLayerSelected(tabs[1].main)}} style={isFirstLayerSelected === idx ? {color: "#FFFFFF"} : {}} className='main'>{item.main}</p>
                            </div>
                            {isFirstLayerSelected !== -1 && firstLayerSelected !== tabs[1].main &&
                                <div>
                                    <div onClick={() => {setSelected(order, {main: tabs[isFirstLayerSelected].main, secondary: tabs[isFirstLayerSelected].secondary[0]}); setIsActive(order, false); setIsFirstLayerSelected(-1)}} className={`extension ${secondLayerSelected(0)}`} style={isFirstLayerSelected === 0 ? { top: "20%" } : isFirstLayerSelected === 1 ? { top: "48%" } : isFirstLayerSelected === 2 ? { top: "76%" } : {}}>
                                        <p>{item.secondary[0]}</p>
                                    </div>
                                    <div onClick={() => {setSelected(order, {main: tabs[isFirstLayerSelected].main, secondary: tabs[isFirstLayerSelected].secondary[1]}); setIsActive(order, false); setIsFirstLayerSelected(-1)}} className={`extension ${secondLayerSelected(1)}`} style={isFirstLayerSelected === 0 ? { top: "60%" } : isFirstLayerSelected === 1 ? { top: "88%" } : isFirstLayerSelected === 2 ? { top: "117%" } : {}}>
                                        <p>{item.secondary[1]}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

export default MultiLayerDropdown2;