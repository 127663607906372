/* This file is used to create functions that call and use the backend endpoints */

import { API_VERSION } from './config.js';
import axios from 'axios';

const serverDomain = "https://api.twinkle.com.pt";
const AUTH_ENDPOINT = `${serverDomain}/api/${API_VERSION}/auth`;
const USER_ENDPOINT = `${serverDomain}/api/${API_VERSION}/user`;
const RESTAURANT_ENDPOINT = `${serverDomain}/api/${API_VERSION}/manager/restaurants`;
const STORAGE_ENDPOINT = `${serverDomain}/storage/${API_VERSION}/images`;

/* Auxiliar function to generate the header object */
const generateHeaders = (token) => {
    return {
        Authorization: `Bearer ${token}`
    };
};

/* Request Code endpoint */
export const requestCodeEndpoint = (phoneNumber) => {
    const requestData = {
        phoneNumber: phoneNumber
    };

    return axios.post(`${AUTH_ENDPOINT}/requestCode`, requestData)
}

/* Verify Code endpoint */
export const verifyCodeEndpoint = (phoneNumber, OTPCode, role) => {
    const requestData = {
        phoneNumber: phoneNumber,
        code: OTPCode,
        role: role
    };

    return axios.post(`${AUTH_ENDPOINT}/verifyCode`, requestData)
}

/* Setup user endpoint */
export const setupUserEndpoint = (userInfo, tokenForUserSetup) => {
    const requestData = userInfo;

    return axios.post(`${USER_ENDPOINT}/userSetup`, requestData, {headers: generateHeaders(tokenForUserSetup)})
}

/* Create Restaurant endpoint */
export const createRestaurantEndpoint = (restaurant, authorizationToken) => {
    const requestData = restaurant;

    return axios.post(`${RESTAURANT_ENDPOINT}/create`, requestData, {headers: generateHeaders(authorizationToken)})
}

/* List Restaurants by User */
export const listRestaurantsByUserEndpoint = (authorizationToken) => {
    return axios.get(`${RESTAURANT_ENDPOINT}/all`, {headers: generateHeaders(authorizationToken)})
}

/* Get all restaurant feedbacks */
export const getAllFeedbacksEndpoint = (restaurantID, authorizationToken) => {
    const params = {
        restaurantId: restaurantID
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/feedbacks/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Upload image endpoint */
export const uploadImageEndpoint = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`${STORAGE_ENDPOINT}/uploadImage`, formData);
}

/* Get restaurant info for page "Informações" */
export const getRestaurantInfoEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/info`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update restaurant info for page "Informações" */
export const updateRestaurantInfoEndpoint = (info, restaurantId, authorizationToken) => {
    const requestData = info;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/info/update`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get restaurant Billing Information for page "Payments" */
export const getRestaurantBillingInfoEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/payments/billingInfo/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update restaurant Billing Information for page "Payments" */
export const updateRestaurantBillingInfoEndpoint = (billingInfo, restaurantId, authorizationToken) => {
    const requestData = billingInfo;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/payments/billingInfo/edit`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get restaurant Bank Information for page "Payments" */
export const getRestaurantBankInfoEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/payments/bankData/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update restaurant Bank Information for page "Payments" */
export const updateRestaurantBankInfoEndpoint = (bankData, restaurantId, authorizationToken) => {
    const requestData = bankData;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/payments/bankData/edit`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get Services list for page "Services" */
export const getAllServicesEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/services`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update Services list for page "Services" */
export const updateAllServicesEndpoint = (services, restaurantId, authorizationToken) => {
    const requestData = services;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/services`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update image list on Pictures page */
export const changeImagesList = (picturesList, restaurantId, authorizationToken) => {
    const requestData = picturesList;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/pictures/change`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get image list on Pictures page */
export const getImagesList = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/pictures/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get Periods list for page "Periods" */
export const getAllPeriodsEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/closingPeriods`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update Periods list for page "Periods" */
export const updateAllPeriodsEndpoint = (periods, restaurantId, authorizationToken) => {
    const requestData = periods;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/closingPeriods`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get Services Preferences for page "Services/Preferences" */
export const getServicePreferencesEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/services/preferences`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update timeStamp on page "Services/Preferences" */
export const updateTimeStampEndpoint = (timeStamp, restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId,
        timeStamp: timeStamp
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/services/preferences/timeStamp`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update automatic acceptance on page "Services/Preferences" */
export const updateAutomaticAcceptanceEndpoint = (automaticAcceptance , restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId,
        automaticAcceptance: automaticAcceptance
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/services/preferences/automaticAcceptance`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update acceptance type on page "Services/Preferences" */
export const updateAcceptanceTypeEndpoint = (acceptanceType , restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId,
        acceptanceType: acceptanceType
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/services/preferences/acceptanceType`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update max clients on page "Services/Preferences" */
export const updateMaxClientsEndpoint = (maxClients, restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId,
        maxClients: maxClients
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/services/preferences/maxClients`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get Restaurant Information by id */
export const getRestaurantByIdEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId,
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/restaurant`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get all dish categories */
export const getDishCategoriesEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/categories/all/dish`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add dish categories */
export const addDishCategoryEndpoint = (categoryNames, restaurantId, authorizationToken) => {
    const requestData = categoryNames;
    const params = {
        restaurantId: restaurantId,
    };
    return axios.post(`${RESTAURANT_ENDPOINT}/categories/add/dish`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* delete dish Categories */
export const deleteDishCategoryEndpoint = (categoryIds, authorizationToken) => {
    const requestData = categoryIds;
  
    const config = {
      headers: generateHeaders(authorizationToken),
      data: requestData
    };
  
    return axios.delete(`${RESTAURANT_ENDPOINT}/categories/delete/dish`, config);
};

/* Get all drink categories */
export const getDrinkCategoriesEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/categories/all/drink`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add drink categories */
export const addDrinkCategoryEndpoint = (categoryNames, restaurantId, authorizationToken) => {
    const requestData = categoryNames;
    const params = {
        restaurantId: restaurantId,
    };
    return axios.post(`${RESTAURANT_ENDPOINT}/categories/add/drink`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* delete drink Categories */
export const deleteDrinkCategoryEndpoint = (categoryIds, authorizationToken) => {
    const requestData = categoryIds;
  
    const config = {
      headers: generateHeaders(authorizationToken),
      data: requestData
    };
  
    return axios.delete(`${RESTAURANT_ENDPOINT}/categories/delete/drink`, config);
};

/* Get all dishes to the page "Ementa" */
export const getAllDishesItemListEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/dishes/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add dish to the Item List */
export const addDishItemListEndpoint = (dish, restaurantId, authorizationToken) => {
    const requestData = dish;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/dishes/add`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Delete dish to the Item List */
export const deleteDishItemListEndpoint = (dishId, authorizationToken) => {
    const params = {
        dishId: dishId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/dishes/delete`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update dish to the Item List */
export const updateDishItemListEndpoint = (dish, authorizationToken) => {
    const requestData = dish;

    return axios.post(`${RESTAURANT_ENDPOINT}/dishes/update`, requestData, {headers: generateHeaders(authorizationToken)})
}

/* Get a dish from Item List by id */
export const getDishByIdItemListEndpoint = (dishId, authorizationToken) => {
    const params = {
        dishId: dishId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/dishes/dish`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get all drinks to the Item List" */
export const getDrinksItemListEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/drinks/all`, {params: params, headers: generateHeaders(authorizationToken)})
}


/* Get all house wines */
export const getAllHouseWinesEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };
    return axios.get(`${RESTAURANT_ENDPOINT}/houseWines/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get a house wine by id */
export const getHouseWineByIdEndpoint = (houseWineId, authorizationToken) => {
    const params = {
        houseWineId: houseWineId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/houseWines/houseWine`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Delete house wine */
export const deleteHouseWineByIdEndpoint = (houseWineId, authorizationToken) => {
    const params = {
        houseWineId: houseWineId
    };

    return axios.delete(`${RESTAURANT_ENDPOINT}/houseWines/delete`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add house wine */
export const addHouseWineEndpoint = (wine, restaurantId, authorizationToken) => {
    const requestData = wine;
    const params = {
        restaurantId: restaurantId
    };
    
    return axios.post(`${RESTAURANT_ENDPOINT}/houseWines/add`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update house wine */
export const updateHouseWineEndpoint = (wine, authorizationToken) => {
    const requestData = wine;

    return axios.post(`${RESTAURANT_ENDPOINT}/houseWines/update`, requestData, {headers: generateHeaders(authorizationToken)})
}

/* Get drink by id to the Item List" */
export const getDrinkByIdItemListEndpoint = (drinkId, authorizationToken) => {
    const params = {
        drinkId: drinkId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/drinks/drink`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add drink to the Item List" */
export const addDrinkItemListEndpoint = (drink, restaurantId, authorizationToken) => {
    const requestData = drink;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/drinks/add`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Delete drink from the Item List" */
export const deleteDrinkItemListEndpoint = (drinkId, authorizationToken) => {
    const params = {
        drinkId: drinkId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/drinks/delete`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update drink from the Item List" */
export const updateDrinkItemListEndpoint = (drink, authorizationToken) => {
    const requestData = drink;

    return axios.post(`${RESTAURANT_ENDPOINT}/drinks/update`, requestData, {headers: generateHeaders(authorizationToken)})
}

/* Get all Cart Dishes */
export const getAllDishesCartEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/dishesCart/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get dish cart by Id */
export const getDishCartByIdEndpoint = (dishCartId, authorizationToken) => {
    const params = {
        dishCartId: dishCartId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/dishesCart/dishCart`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add fixed Dish */
export const addFixedDishEndpoint = (dish, restaurantId, authorizationToken) => {
    const requestData = dish;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/dishesCart/new/dish`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add Dish from Item list */
export const adddDishFromItemListEndpoint = (dishId, categoryId, restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId,
        dishId: dishId,
        categoryId: categoryId,
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/dishesCart/new/itemList`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update Dish Cart */
export const updateDishCartEndpoint = (dishCartId, dish, authorizationToken) => {
    const requestData = dish;
    const params = {
        dishCartId: dishCartId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/dishesCart/update`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Delete Dish Cart */
export const deleteDishCartEndpoint = (dishCartId, authorizationToken) => {
    const params = {
        dishCartId: dishCartId
    };

    return axios.delete(`${RESTAURANT_ENDPOINT}/dishesCart/delete`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get all dishes of the day */
export const getDishesOfTheDayEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/dishOfTheDay/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get dishe of the day */
export const getDishOfTheDayEndpoint = (dishOfTheDayId, authorizationToken) => {
    const params = {
        dishOfTheDayId: dishOfTheDayId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/dishOfTheDay/dish`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Create dish of the day */
export const createDishOfTheDayEndpoint = (dishOfTheDay, authorizationToken) => {
    console.log(dishOfTheDay)
    const requestData = dishOfTheDay;

    return axios.post(`${RESTAURANT_ENDPOINT}/dishOfTheDay/new`, requestData, {headers: generateHeaders(authorizationToken)})
}

/* delete dish of the day */
export const deleteDishOfTheDayEndpoint = (dishOfTheDayId, authorizationToken) => {
    const params = {
        dishOfTheDayId: dishOfTheDayId
    };

    return axios.delete(`${RESTAURANT_ENDPOINT}/dishOfTheDay/delete`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update dish of the day */
export const updateDishOfTheDayEndpoint = (dishOfTheDay, restaurantId, authorizationToken) => {
    const requestData = dishOfTheDay;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/dishOfTheDay/update`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get all Cart drinks */
export const getCartDrinksEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/drinksCart/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add New Cart drink from Item List drinks */
export const addCartDrinksFromItemListEndpoint = (drinkId, categoryId, restaurantId, authorizationToken) => {
    const params = {
        drinkId: drinkId,
        categoryId: categoryId,
        restaurantId: restaurantId,
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/drinksCart/new/itemList`, null, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add New Cart drink */
export const addCartDrinkEndpoint = (drink, restaurantId, authorizationToken) => {
    const requestData = drink;
    const params = {
        restaurantId: restaurantId,
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/drinksCart/new/drink`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get Cart drink by id */
export const getCartDrinksByIdEndpoint = (drinkCartId, authorizationToken) => {
    const params = {
        drinkCartId: drinkCartId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/drinksCart/drinkCart`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update Cart Drink */
export const updateCartDrinkEndpoint = (drinkCartId, drink, authorizationToken) => {
    const requestData = drink;
    const params = {
        drinkCartId: drinkCartId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/drinksCart/update`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Delete Cart Drink */
export const deleteCartDrinkEndpoint = (drinkCartId, authorizationToken) => {
    const params = {
        drinkCartId: drinkCartId
    };

    return axios.delete(`${RESTAURANT_ENDPOINT}/drinksCart/delete`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get Drinks of the day */
export const getDrinksOfTheDayEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/drinkOfTheDay/all`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get Drink of the day */
export const getDrinkOfTheDayEndpoint = (drinkOfTheDayId, authorizationToken) => {
    console.log(drinkOfTheDayId)
    const params = {
        drinkOfTheDayId: drinkOfTheDayId
    };

    return axios.get(`${RESTAURANT_ENDPOINT}/drinkOfTheDay/drink`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Create Drink of the day */
export const createDrinkOfTheDayEndpoint = (drinkOfTheDay, authorizationToken) => {
    const requestData = drinkOfTheDay;

    return axios.post(`${RESTAURANT_ENDPOINT}/drinkOfTheDay/new`, requestData, {headers: generateHeaders(authorizationToken)})
}

/* Delete Drink of the day */
export const deleteDrinkOfTheDayEndpoint = (drinkOfTheDayId, authorizationToken) => {
    const params = {
        drinkOfTheDayId: drinkOfTheDayId
    };

    return axios.delete(`${RESTAURANT_ENDPOINT}/drinkOfTheDay/delete`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Update Drink of the day */
export const updateDrinkOfTheDayEndpoint = (drinkOfTheDay, restaurantId, authorizationToken) => {
    console.log(drinkOfTheDay)
    const requestData = drinkOfTheDay;
    const params = {
        restaurantId: restaurantId
    };

    return axios.post(`${RESTAURANT_ENDPOINT}/drinkOfTheDay/update`, requestData, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Get all directory wines */
export const getAllDirectoryWinesEndpoint = (authorizationToken) => {
    return axios.get(`${RESTAURANT_ENDPOINT}/wines/all`, {headers: generateHeaders(authorizationToken)})
}

/* Delete wine from wine directory by id */
export const deleteDirectoryWineEndpoint = (wineId, authorizationToken) => {
    const params = {
        wineId: wineId
    };

    return axios.delete(`${RESTAURANT_ENDPOINT}/wines/delete`, {params: params, headers: generateHeaders(authorizationToken)})
}

/* Add wine to wine directory */
export const addDirectoryWineEndpoint = (wine, authorizationToken) => {
    const requestData = wine;

    return axios.post(`${RESTAURANT_ENDPOINT}/wines/add`, requestData, {headers: generateHeaders(authorizationToken)})
}


// Users

/* Get all users */
export const getAllUsersByRestaurantIdEndpoint = (restaurantId, authorizationToken) => {
    const params = {
        restaurantId: restaurantId
    };
    return axios.get(`${RESTAURANT_ENDPOINT}/users/all`, {params: params, headers: generateHeaders(authorizationToken)})
}
