import './wineSizePriceDropdown.css';
import blackArrow from '../../utils/logos/blackArrow.svg';
import whitePlus from '../../utils/logos/whitePlus.svg';
import React from 'react';

const bottleVolume = ['Copo', '25 cl', '37,5 cl', '50 cl', '75 cl', '1 l', '1,5 l', '3 l', '4,5 l', '6 l', '9 l', '12 l', '18 l'];

const WineSizePriceDropdown = ({ isEdit, wineState, handleDropdownToggle, handleWineSizeSelection, handleWinePriceSelection, handleAddPriceSize }) => {
    return (
        <div className='wineSizePriceDropdown'>
            {wineState.winePriceSizes.map((item, index) => (
                <div className='wineSizePrice' key={index}>
                    <div className='wineSizeDropdown'>
                        <button
                            className='wineSizeDropdown-btn'
                            onClick={() => handleDropdownToggle(index)}
                        >
                            <span>{item.wineSize}</span>
                            <img
                                src={blackArrow}
                                alt='arrow'
                                style={wineState.dropdownStatus[index] ? { transform: 'rotate(180deg)' } : {}}
                            />
                        </button>
                        {wineState.dropdownStatus[index] && (
                            <div className='wineSizeDropdown-content'>
                                {bottleVolume.map((vol) => (
                                    <button
                                        className='wineSizeDropdown-item'
                                        onClick={() => isEdit ? handleWineSizeSelection(item.winePriceSizeId, index, vol) : handleWineSizeSelection(null, index, vol)}
                                        key={vol}
                                    >
                                        <span>{vol}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <input
                        placeholder={item.winePrice ? '€ ' + item.winePrice : '€ 0.00'}
                        type='number'
                        dir='rtl'
                        onBlur={(event) => isEdit ? handleWinePriceSelection(item.winePriceSizeId, index, event.target.value) : handleWinePriceSelection(null, index, event.target.value)}
                    />
                    <button className='addWineSizePrice' onClick={() => handleAddPriceSize()}>
                        <img src={whitePlus} alt='plus'/>
                    </button>
                </div>
            ))}
        </div>
    )
}

export default WineSizePriceDropdown;