import "./payment.css";
import { useState, useEffect } from "react";
import {
  Dropdown,
  PageTabs,
  CalendarDropdown,
  Loading,
} from "../../components";
import {
  getRestaurantBillingInfoEndpoint,
  updateRestaurantBillingInfoEndpoint,
  getRestaurantBankInfoEndpoint,
  updateRestaurantBankInfoEndpoint,
} from "../../backendFunctions";
import editButton from "../../utils/logos/editButton.png";
import greenArrow from "../../utils/logos/greenArrow.svg";
import { delay } from "../../utils/utils.js";

const tabs = ["Pagamentos", "Faturas", "Dados bancários", "Dados faturação"];
const dropdownTabs = ["Semanal", "Mensal", "Dois meses", "Ano", "Sempre"];

const currentDate = new Date();
const formattedCurrentDate = `${(currentDate.getMonth() + 1)
  .toString()
  .padStart(2, "0")}/${currentDate
  .getDate()
  .toString()
  .padStart(2, "0")}/${currentDate.getFullYear()}`;

const oneWeekBefore = new Date(currentDate);
oneWeekBefore.setDate(currentDate.getDate() - 7);
const formattedBeforeDate = `${(oneWeekBefore.getMonth() + 1)
  .toString()
  .padStart(2, "0")}/${oneWeekBefore
  .getDate()
  .toString()
  .padStart(2, "0")}/${oneWeekBefore.getFullYear()}`;

/* Taxa Twinkle p/ refeição */
const twinkleTax = 0.1;

const Payments = ({ restaurant, authorizationToken }) => {
  /* DB data (Bank Data) */
  const [fullName, setFullName] = useState(null);
  const [locality, setLocality] = useState(null);
  const [IBAN, setIBAN] = useState(null);
  const [paymentFrequency, setPaymentFrequency] = useState(null);
  const [address, setAddress] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [SWIFTBIC, setSWIFTBIC] = useState(null);

  /* DB data (Invoice Data) */
  const [company, setCompany] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [invoicePostCode, setInvoicePostCode] = useState(null);
  const [NIPC, setNIPC] = useState(null);
  const [checkboxes, setCheckboxes] = useState(null);

  const [paymentsSelectedTab, setPaymentsSelectedTab] = useState(tabs[0]);
  const [dropdownSelected, setDropdownSelected] = useState("Semanal");
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const [calendarSelectedStart, setCalendarSelectedStart] =
    useState(formattedBeforeDate);
  const [calendarSelectedEnd, setCalendarSelectedEnd] =
    useState(formattedCurrentDate);
  const [isCalendarActive, setIsCalendarActive] = useState(false);

  const [editIsActiveBank, setEditIsActiveBank] = useState(false);
  const [editIsActiveInvoicing, setEditIsActiveInvoicing] = useState(false);

  const [reservations, setReservations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //setReservations(dataOperations.reservations);
    getRestaurantBankInfoEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);

        /* Banking data */
        setFullName(
          response.data.data.companyName ? response.data.data.companyName : ""
        );
        setIBAN(response.data.data.iban ? response.data.data.iban : 0);
        setPaymentFrequency(
          response.data.data.paymentFrequency
            ? response.data.data.paymentFrequency
            : ""
        );
        setSWIFTBIC(
          response.data.data.swiftBic ? response.data.data.swiftBic : ""
        );
        setAddress(
          response.data.data.address
            ? response.data.data.address.addressLine
            : ""
        );
        setPostCode(
          response.data.data.address
            ? response.data.data.address.postalCode
            : ""
        );
        setLocality(
          response.data.data.address ? response.data.data.address.city : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });

    getRestaurantBillingInfoEndpoint(
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data);

        /* Billing data */
        setCompany(
          response.data.data.jurisdictionName
            ? response.data.data.jurisdictionName
            : ""
        );
        setNIPC(response.data.data.nipc ? response.data.data.nipc : "");
        setCheckboxes([
          response.data.data.smallRegime,
          response.data.data.marketingDeal,
          response.data.data.permissionsDeal,
        ]);
        setInvoiceAddress(
          response.data.data.address
            ? response.data.data.address.addressLine
            : ""
        );
        setInvoicePostCode(
          response.data.data.address
            ? response.data.data.address.postalCode
            : ""
        );
        setCity(
          response.data.data.address ? response.data.data.address.city : ""
        );
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([{}, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  /* Update bank data on DB */
  const saveBankInfo = () => {
    setEditIsActiveBank(false);

    const bankData = {
      companyName: fullName,
      swiftBic: SWIFTBIC,
      iban: IBAN,
      paymentFrequency: paymentFrequency,
      address: {
        addressLine: address,
        city: locality,
        postalCode: postCode,
      },
    };

    updateRestaurantBankInfoEndpoint(
      bankData,
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* Update billing data on DB */
  const saveInvoiceInfo = () => {
    setEditIsActiveInvoicing(false);

    const billingInfo = {
      address: {
        addressLine: invoiceAddress,
        city: city,
        postalCode: invoicePostCode,
      },
      jurisdictionName: company,
      nipc: NIPC,
      smallRegime: checkboxes[0],
      marketingDeal: checkboxes[1],
      permissionsDeal: checkboxes[2],
    };

    updateRestaurantBillingInfoEndpoint(
      billingInfo,
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setPageTab = (tab) => {
    if (tab === paymentsSelectedTab) return "currentHomeTab";
    else return "";
  };

  const updateCheckbox = (index) => {
    setCheckboxes((prevCheckboxes) => {
      const updatedCheckboxes = [...prevCheckboxes];
      updatedCheckboxes[index] = !checkboxes[index];
      return updatedCheckboxes;
    });
  };

  const convertDateToString = (date) => {
    const padZero = (number) => {
      return number.toString().padStart(2, "0");
    };

    let day;
    let month;
    let year;

    if (date instanceof Date) {
      day = date.getDate();
      month = date.getMonth() + 1;
      year = date.getFullYear();
    } else {
      day = date.slice(0, 2);
      month = date.slice(3, 5);
      year = date.slice(6, 10);
    }
    return `${padZero(day)}/${padZero(month)}/${year}`;
  };

  const getReservationTotals = () => {
    const { sales, tip } = reservations.reduce(
      (accumulator, reservation) => {
        return {
          sales: accumulator.sales + parseFloat(reservation.total),
          tip: accumulator.tip + parseFloat(reservation.tip),
        };
      },
      { sales: 0, tip: 0 }
    );
    return { sales, tip };
  };

  return (
    <div className="payment">
      {isLoading && <Loading />}
      <div className={`w-full h-full ${isLoading ? "invisible" : undefined}`}>
        <h1>Pagamentos</h1>

        <PageTabs
          tabs={tabs}
          setClass={setPageTab}
          setTab={setPaymentsSelectedTab}
        />

        {/* If "Pagamentos" tab is selected */}
        {paymentsSelectedTab === "Pagamentos" && (
          <div className="pagamentosTab">
            <div className="dropdownsDiv">
              {/*TODO<p className='date'>{convertDateToString(calendarSelectedStart) + " - " + convertDateToString(calendarSelectedEnd)}</p>*/}
              <div style={/*TODO*/ { display: "none" }} className="dropdowns">
                <Dropdown
                  tabs={dropdownTabs}
                  selected={dropdownSelected}
                  setSelected={setDropdownSelected}
                  isActive={isDropdownActive}
                  setIsActive={setIsDropdownActive}
                />
                <CalendarDropdown
                  selectedStart={calendarSelectedStart}
                  setSelectedStart={setCalendarSelectedStart}
                  selectedEnd={calendarSelectedEnd}
                  setSelectedEnd={setCalendarSelectedEnd}
                  isActive={isCalendarActive}
                  setIsActive={setIsCalendarActive}
                />
              </div>
            </div>

            <div style={isCalendarActive ? { filter: "blur(5px)" } : {}}>
              <div style={isDropdownActive ? { filter: "blur(5px)" } : {}}>
                <div className="firstTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Vendas</th>
                        <th>Taxa Twinkle</th>
                        <th>Gorjeta</th>
                        <th>Pagamento Total</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{`${
                          reservations ? getReservationTotals().sales : 0
                        }€`}</td>
                        <td>{`${
                          reservations
                            ? getReservationTotals().sales * twinkleTax
                            : 0
                        }€`}</td>
                        <td>{`${
                          reservations ? getReservationTotals().tip : 0
                        }€`}</td>
                        <td>{`${
                          reservations
                            ? getReservationTotals().sales -
                              getReservationTotals().sales * twinkleTax
                            : 0
                        }€`}</td>
                        <td className="doc">
                          Documento
                          <img src={greenArrow} alt="arrow" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                style={
                  !reservations || (reservations && reservations.length === 0)
                    ? { display: "none" }
                    : {}
                }
              >
                <p className="secondTableTitle">Pagamentos recentes</p>

                <div className="secondTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Dia</th>
                        <th>Vendas</th>
                        <th>Taxa Twinkle</th>
                        <th>Gorjeta</th>
                        <th>Pagamento total</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservations &&
                        reservations.map((reservation, idx) => (
                          <tr key={idx}>
                            <td>
                              {convertDateToString(reservation.date.toDate())}
                            </td>
                            <td>{`${reservation.total}€`}</td>
                            <td>{`${reservation.total * twinkleTax}€`}</td>
                            <td>{`${reservation.tip}€`}</td>
                            <td>{`${
                              reservation.total - reservation.total * twinkleTax
                            }€`}</td>
                            <td className="doc">
                              Documento
                              <img src={greenArrow} alt="arrow" />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* If "Faturas" tab is selected */}
        {paymentsSelectedTab === "Faturas" && (
          <div>
            <h1>In Construction...</h1>
          </div>
        )}

        {/* If "Dados bancários" tab is selected */}
        {paymentsSelectedTab === "Dados bancários" && (
          <div className="DadosBancariosTab">
            <div className="fullName">
              <div className="textAndIcon">
                <span>
                  Introduz o nome completo do titular da tua conta ou o nome da
                  empresa tal como consta no extrato bancário. Não utilizes
                  abreviaturas
                </span>
                <img
                  src={editButton}
                  onClick={() => setEditIsActiveBank(true)}
                  style={editIsActiveBank ? { display: "none" } : {}}
                  alt="edit"
                />
              </div>
              <input
                value={fullName ? fullName : ""}
                onChange={(e) => setFullName(e.target.value)}
                readOnly={editIsActiveBank ? false : true}
              />
            </div>

            <div className="inputDiv">
              <span>Localidade do beneficiário</span>
              <input
                value={locality ? locality : ""}
                onChange={(e) => setLocality(e.target.value)}
                readOnly={editIsActiveBank ? false : true}
              />
            </div>

            <div className="inputDiv">
              <span>IBAN</span>
              <input
                value={IBAN ? IBAN : ""}
                onChange={(e) => setIBAN(e.target.value)}
                readOnly={editIsActiveBank ? false : true}
              />
            </div>

            <div className="inputDiv">
              <span>Frequência do pagamento</span>
              <input
                value={paymentFrequency ? paymentFrequency : ""}
                onChange={(e) => setPaymentFrequency(e.target.value)}
                placeholder="ex: Semanal"
                readOnly={editIsActiveBank ? false : true}
              />
            </div>

            <div className="inputDiv">
              <span>Endereço do beneficiário</span>
              <input
                value={address ? address : ""}
                onChange={(e) => setAddress(e.target.value)}
                readOnly={editIsActiveBank ? false : true}
              />
            </div>

            <div className="inputDiv">
              <span>Código postal do beneficiário</span>
              <input
                value={postCode ? postCode : ""}
                onChange={(e) => setPostCode(e.target.value)}
                readOnly={editIsActiveBank ? false : true}
              />
            </div>

            <div className="inputDiv">
              <span>SWIFT/BIC</span>
              <input
                value={SWIFTBIC ? SWIFTBIC : ""}
                onChange={(e) => setSWIFTBIC(e.target.value)}
                readOnly={editIsActiveBank ? false : true}
              />
            </div>

            {editIsActiveBank && (
              <button onClick={() => saveBankInfo()} className="saveBtn">
                Guardar
              </button>
            )}
          </div>
        )}

        {/* If "Dados faturação" tab is selected */}
        {paymentsSelectedTab === "Dados faturação" && (
          <div className="DadosFaturacaoTab">
            <div className="inputs">
              <div className="inputDiv" style={{ marginTop: "0" }}>
                <span>Empresa / Denominação Jurídica *</span>
                <input
                  value={company ? company : ""}
                  onChange={(e) => setCompany(e.target.value)}
                  readOnly={editIsActiveInvoicing ? false : true}
                />
              </div>

              <div className="inputDiv">
                <span>Morada *</span>
                <input
                  value={invoiceAddress ? invoiceAddress : ""}
                  onChange={(e) => setInvoiceAddress(e.target.value)}
                  readOnly={editIsActiveInvoicing ? false : true}
                />
              </div>

              <div className="inputDiv">
                <span>Cidade *</span>
                <input
                  value={city ? city : ""}
                  onChange={(e) => setCity(e.target.value)}
                  readOnly={editIsActiveInvoicing ? false : true}
                />
              </div>

              <div className="inputDiv">
                <span>Código Postal *</span>
                <input
                  value={invoicePostCode ? invoicePostCode : ""}
                  onChange={(e) => setInvoicePostCode(e.target.value)}
                  readOnly={editIsActiveInvoicing ? false : true}
                />
              </div>

              <div className="inputDiv">
                <span>NIPC *</span>
                <input
                  value={NIPC ? NIPC : ""}
                  onChange={(e) => setNIPC(e.target.value)}
                  readOnly={editIsActiveInvoicing ? false : true}
                />
              </div>

              {editIsActiveInvoicing && (
                <button onClick={() => saveInvoiceInfo()} className="saveBtn">
                  Guardar
                </button>
              )}
            </div>

            <form>
              <div className="checkbox" style={{ marginBottom: "3.875rem" }}>
                <input
                  type="checkbox"
                  checked={checkboxes ? checkboxes[0] : false}
                  onChange={() => {
                    updateCheckbox(0);
                  }}
                  disabled={editIsActiveInvoicing ? false : true}
                />
                <span>Regime de pequenas e médias empresas</span>
              </div>

              <div className="checkbox">
                <input
                  type="checkbox"
                  checked={checkboxes ? checkboxes[1] : false}
                  onChange={() => {
                    updateCheckbox(1);
                  }}
                  disabled={editIsActiveInvoicing ? false : true}
                />
                <span>Acordo de Marketing</span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In odio
                purus, iaculis vitae auctor ut, lacinia vel leo. Morbi ante
                orci, molestie nec justo quis, blandit iaculis mauris. Morbi
                commodo ultricies dui id sollicitudin.
              </p>

              <div className="checkbox">
                <input
                  type="checkbox"
                  checked={checkboxes ? checkboxes[2] : false}
                  onChange={() => {
                    updateCheckbox(2);
                  }}
                  disabled={editIsActiveInvoicing ? false : true}
                />
                <span>Acordo de Marketing</span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In odio
                purus, iaculis vitae auctor ut, lacinia vel leo. Morbi ante
                orci, molestie nec justo quis, blandit iaculis mauris. Morbi
                commodo ultricies dui id sollicitudin.
              </p>
            </form>

            <img
              src={editButton}
              onClick={() => setEditIsActiveInvoicing(true)}
              style={editIsActiveInvoicing ? { display: "none" } : {}}
              alt="edit"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Payments;
