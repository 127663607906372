import './pageTabs.css';

const PageTabs = ({ tabs, setClass, setTab }) => {
  return (
    <div className="pageTabs">
        {tabs.map((tabName) => (
            <p key={tabName} className={setClass(tabName)} onClick={() => setTab(tabName)}>{tabName}</p>
        ))}
    </div>
  )
}

export default PageTabs;