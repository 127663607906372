import "./authenticationAndSetup.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  requestCodeEndpoint,
  verifyCodeEndpoint,
} from "../../backendFunctions";
import backButton from "../../utils/logos/backButton.svg";
import { useAuth } from "../../context/AuthContext";
import { Spinner } from "flowbite-react";
import { delay } from "../../utils/utils.js";

const Authentication = ({
  setCompletePhoneNumber,
  setTokenForUserSetup,
  setRestaurant,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("917208323");
  const [countryCode, setCountryCode] = useState("+351");
  const [OTPCode, setOTPCode] = useState("123123");
  const [isVerifyCodeScreenActive, setIsVerifyCodeScreenActive] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const requestOTPCode = () => {
    setIsLoading(true);
    if (!phoneNumber || !countryCode) alert("Fill all information");

    const fetchData = requestCodeEndpoint(`${countryCode}${phoneNumber}`)
      .then((response) => {
        console.log(response.data);
        if (
          !(
            (
              response.data.httpStatus === 200
            ) /*&& response.data.message === "OK"*/
          )
        )
          alert("Try again");
        else {
          Promise.all([fetchData, delay()]).then(() => {
            setIsLoading(false);
            setIsVerifyCodeScreenActive(true);
            setRestaurant(null); // to prevent sidebar to open on "/panel"
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const verifyOTPCode = () => {
    setIsLoading(true);
    if (!phoneNumber || !countryCode || !OTPCode) alert("Fill all information");

    const fetchData = verifyCodeEndpoint(
      `${countryCode}${phoneNumber}`,
      OTPCode,
      "manager"
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.httpStatus === 200 && response.data.data.isNewUser) {
          Promise.all([fetchData, delay()]).then(() => {
            setIsLoading(false);

            setCompletePhoneNumber(response.data.data.phoneNumber);
            setTokenForUserSetup(response.data.data.token);
            navigate("/setup");
          });
        } else if (
          response.data.httpStatus === 200 &&
          !response.data.data.isNewUser
        ) {
          Promise.all([fetchData, delay()]).then(() => {
            setIsLoading(false);

            //setAuthorizationToken(response.data.data.token);
            login(response.data.data.token); // Set the token and log in
            navigate("/panel");
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="authentication">
      {!isVerifyCodeScreenActive && (
        <div className="auth-box">
          <img src={backButton} alt="back" className="backButton" />
          <h1 className="title auth-title">Número de Telemóvel</h1>
          <div className="phoneDiv">
            <input
              className="phoneCode"
              value={countryCode}
              placeholder="Country code"
              onChange={(e) => setCountryCode(e.target.value)}
            />
            <input
              className="phone"
              value={phoneNumber}
              placeholder="Telemóvel"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <button className="next-btn" onClick={() => requestOTPCode()}>
            {isLoading ? (
              <Spinner
                className="fill-green-700"
                aria-label="Spinner button example"
                size="md"
              />
            ) : (
              "Próximo"
            )}
          </button>
          <p className="termsAndConditions">
            Ao clicar em próximo aceita os{" "}
            <u className="cursor-pointer">termos e condições</u> da aplicação
          </p>
        </div>
      )}
      {isVerifyCodeScreenActive && (
        <div className="auth-box">
          <img
            onClick={() => setIsVerifyCodeScreenActive(false)}
            src={backButton}
            alt="back"
            className="backButton"
          />
          <h1 className="title auth-title">Código de Confirmação</h1>
          <input
            value={OTPCode}
            style={{
              width: "12.12919rem",
              height: "2.5625rem",
              flexShrink: "0",
              borderRadius: "2.13544rem",
              background: "#E5E5E5",
              color: "#000",
              textAlign: "center",
              fontSize: "1.11044rem",
            }}
            placeholder="SMS Code"
            onChange={(e) => setOTPCode(e.target.value)}
          />
          <p className="didntReceive">Não recebi o código de confirmação</p>
          <button className="sendAgain-btn" onClick={() => requestOTPCode()}>
            Enviar Novamente
          </button>
          <button className="next-btn" onClick={() => verifyOTPCode()}>
            {isLoading ? (
              <Spinner
                className="fill-green-700"
                aria-label="Spinner button example"
                size="md"
              />
            ) : (
              "Próximo"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default Authentication;
