import {
    SearchBar,
    MultiLayerDropdown2,
    DishForSlideIn,
    Dish,
    CalendarDropdown,
    DishTypeButtons,
} from "../../components";
import { useState } from "react";
import plus from "../../utils/logos/plus.svg";
import whitePlus from "../../utils/logos/whitePlus.svg";
import crossButton from "../../utils/logos/crossButton.png";
import minus from "../../utils/logos/minus.svg";
import optionsButton from "../../utils/logos/optionsButton.png";
import backButton from "../../utils/logos/backButton.svg";
import pencil from "../../utils/logos/pencil.svg";
import menu from "../../utils/logos/menu.svg";
import Switch from "react-switch";

const MenusPage = ({
    isAddMenuActive,
    setIsAddMenuActive,
    isOptionsCreateActive,
    setIsOptionsCreateActive,
    menus,
    newMenu,
    setNewMenu,
    calendarSelectedStart,
    setCalendarSelectedStart,
    calendarSelectedEnd,
    setCalendarSelectedEnd,
    isEditMenuActive,
    setIsEditMenuActive,
    weekdays,
    isCalendarActive,
    setIsCalendarActive,
    menuDishType,
    setMenuDishType,
    multiLayerDropdownTabs,
    dropdownSelectedMenus,
    handleMenusDropdownSelect,
    isDropdownActiveMenus,
    dishActiveDays,
    setDishActiveDays,
    handleMenusActivate,
    variableDishDescription,
    setVariableDishDescription,
    handleKeyDown,
    addDishFromListMenus,
    itemList,
    handleAddDishFromListMenu,
    isAddMenuDishActive,
    setAddNewMenuDish,
    menuDishVariations,
    setMenuDishVariations,
    addMiniDescription,
    addNewMenuDish,
    uploadImage,
    caracteristics,
    handleCaracteristicClick,
    caracteristicsCorrespondency,
    setIsAddMenuDishActive,
    resetDish,
    saveNewItem,
    clearNewMenu,
    handleSaveMenu,
    handleEditMenu,
}) => {
    const [isEditItemsCreateMenuActive, setIsEditItemsCreateMenuActive] =
        useState(false);
    const [dishTypeSelected, setDishTypeSelected] = useState("comida");
    const [isEditCategoriesActive, setIsEditCategoriesActive] = useState(false);
    const [menuDishCategories, setMenuDishCategories] = useState([]);
    const [menuDrinkCategories, setMenuDrinkCategories] = useState([]);
    const [menuCategoriesToBeSaved, setMenuCategoriesToBeSaved] = useState([]);
    const [menuCategoriesToBeDeleted, setMenuCategoriesToBeDeleted] = useState(
        []
    );
    const [editCategoryExtensionIsActive, setEditCategoryExtensionIsActive] =
        useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");

    const handleToggle = (idx, dinner = false) => {
        if (!dinner) {
            setNewMenu((prev) => {
                const newLunch = [...prev.lunch];
                newLunch[idx] = !newLunch[idx];
                return { ...prev, lunch: newLunch };
            });
        } else {
            setNewMenu((prev) => {
                const newDinner = [...prev.dinner];
                newDinner[idx] = !newDinner[idx];
                return { ...prev, dinner: newDinner };
            });
        }
    };

    function boolArrayToWeekdays(boolArray) {
        const weekdays = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"];
        const selectedWeekdays = [];

        boolArray.forEach((value, index) => {
            if (value) {
                selectedWeekdays.push(weekdays[index]);
            }
        });
        return selectedWeekdays.join(", ");
    }

    const convertDateToString = (date) => {
        const padZero = (number) => {
            return number.toString().padStart(2, "0");
        };

        let day;
        let month;
        let year;

        if (date instanceof Date) {
            day = date.getDate();
            month = date.getMonth() + 1;
            year = date.getFullYear();
        } else {
            day = date.toDate().getDate();
            month = date.toDate().getMonth() + 1;
            year = date.toDate().getFullYear();
        }
        return `${padZero(day)}/${padZero(month)}/${year}`;
    };

    const handleCategorySave = () => {
        if (dishTypeSelected === "comida") {
            if (menuCategoriesToBeDeleted.length > 0) {
                /*deleteDishCategoryEndpoint(menuCategoriesToBeDeleted, authorizationToken).then(response => {
                    console.log(response.data);
                    setMenuDishCategories(prev => {
                        const responseCategoryIds = response.data.data.map(item => item.categoryId);
                        return prev.filter(category => !responseCategoryIds.includes(category.categoryId));
                    });
                    setMenuCategoriesToBeDeleted([]);
                })
                .catch(error => {
                    console.error(error);
                });*/
            }
            if (menuCategoriesToBeSaved.length > 0) {
                /*addDishCategoryEndpoint(menuCategoriesToBeSaved, restaurant.restaurantId, authorizationToken).then(response => {
                    console.log(response.data);
                    setMenuDishCategories((prev) => prev.concat(response.data.data));
                    setmenuCategoriesToBeSaved([]);
                })
                .catch(error => {
                    console.error(error);
                });*/
            }
        } else {
            if (menuCategoriesToBeDeleted.length > 0) {
                /*deleteDrinkCategoryEndpoint(menuCategoriesToBeDeleted, authorizationToken).then(response => {
                    console.log(response.data);
                    setMenuDrinkCategories(prev => {
                        const responseCategoryIds = response.data.data.map(item => item.categoryId);
                        return prev.filter(category => !responseCategoryIds.includes(category.categoryId));
                    });
                    setMenuCategoriesToBeDeleted([]);
                })
                .catch(error => {
                    console.error(error);
                });*/
            }
            if (menuCategoriesToBeSaved.length > 0) {
                /*addDrinkCategoryEndpoint(menuCategoriesToBeSaved, restaurant.restaurantId, authorizationToken).then(response => {
                    console.log(response.data);
                    setMenuDrinkCategories((prev) => prev.concat(response.data.data));
                    setMenuCategoriesToBeSaved([]);
                })
                .catch(error => {
                    console.error(error);
                });*/
            }
        }
        setIsEditCategoriesActive(false);
    };

    if (!isEditItemsCreateMenuActive)
        return (
            <div className="menuPage">
                <h1 className="pageTitle">Menus</h1>
                <button
                    className="createNew"
                    onClick={() => setIsAddMenuActive(!isAddMenuActive)}
                    style={isAddMenuActive ? { background: "#77B9B4" } : {}}
                >
                    <img src={plus} alt="plus" />
                    <p>Adicionar menu</p>
                </button>

                <table className="table">
                    <thead
                        style={
                            !menus || (menus.length === 0 && !isAddMenuActive)
                                ? { display: "none" }
                                : {}
                        }
                    >
                        <tr>
                            <th className="firstColumn"></th>
                            <th className="name">Nome</th>
                            <th className="timeOption">Início/Fim</th>
                            <th className="days">Dias da semana</th>
                            <th className="">Preço</th>
                        </tr>
                    </thead>

                    <tbody>
                        {isAddMenuActive && (
                            <tr>
                                <td className="firstColumn">
                                    <img
                                        onClick={() =>
                                            setIsOptionsCreateActive(
                                                !isOptionsCreateActive
                                            )
                                        }
                                        src={
                                            isOptionsCreateActive
                                                ? crossButton
                                                : optionsButton
                                        }
                                        alt="options"
                                    />
                                </td>
                                <td className="name">
                                    {newMenu.name !== ""
                                        ? newMenu.name
                                        : "Nome Menu"}
                                </td>
                                <td className="timeOption">
                                    {newMenu.availability.status === 1
                                        ? "Sempre válido"
                                        : calendarSelectedEnd
                                        ? convertDateToString(
                                              calendarSelectedStart
                                          ) +
                                          " - " +
                                          convertDateToString(
                                              calendarSelectedEnd
                                          )
                                        : convertDateToString(
                                              calendarSelectedStart
                                          ) + " - "}
                                </td>
                                <td className="days">
                                    <span>Almoço:</span>
                                    <span>
                                        {boolArrayToWeekdays(newMenu.lunch)
                                            .length !== 0
                                            ? boolArrayToWeekdays(newMenu.lunch)
                                            : "-"}
                                    </span>
                                    <span>Jantar:</span>
                                    <span>
                                        {boolArrayToWeekdays(newMenu.dinner)
                                            .length !== 0
                                            ? boolArrayToWeekdays(
                                                  newMenu.dinner
                                              )
                                            : "-"}
                                    </span>
                                </td>
                                <td className="priceCol">
                                    <div className="infos">
                                        <span>Com bebida:</span>
                                        <span>
                                            {newMenu.prices.drink.isActive
                                                ? `${newMenu.prices.drink.price}€`
                                                : "-"}
                                        </span>
                                        <span>Sem bebida:</span>
                                        <span>
                                            {newMenu.prices.noDrink.isActive
                                                ? `${newMenu.prices.noDrink.price}€`
                                                : "-"}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {menus &&
                            menus.map((menu, idx) => (
                                <tr key={idx}>
                                    <td className="firstColumn">
                                        <img
                                            onClick={
                                                isEditMenuActive === -1
                                                    ? () => {
                                                          setNewMenu(
                                                              menus[idx]
                                                          );
                                                          setIsEditMenuActive(
                                                              idx
                                                          );
                                                      }
                                                    : () => {
                                                          clearNewMenu();
                                                          setIsEditMenuActive(
                                                              -1
                                                          );
                                                      }
                                            }
                                            src={
                                                isEditMenuActive === idx
                                                    ? crossButton
                                                    : optionsButton
                                            }
                                            alt="options"
                                        />
                                    </td>
                                    <td className="name">{menu.name}</td>
                                    <td className="timeOption">
                                        {menu.availability.status === 1
                                            ? "Sempre válido"
                                            : convertDateToString(
                                                  menu.availability.startDate
                                              ) +
                                              " - " +
                                              convertDateToString(
                                                  menu.availability.endDate
                                              )}
                                    </td>
                                    <td className="days">
                                        <span>Almoço:</span>
                                        <span>
                                            {boolArrayToWeekdays(menu.lunch)
                                                .length !== 0
                                                ? boolArrayToWeekdays(
                                                      menu.lunch
                                                  )
                                                : "-"}
                                        </span>
                                        <span>Jantar:</span>
                                        <span>
                                            {boolArrayToWeekdays(menu.dinner)
                                                .length !== 0
                                                ? boolArrayToWeekdays(
                                                      menu.dinner
                                                  )
                                                : "-"}
                                        </span>
                                    </td>
                                    <td className="priceCol">
                                        <div className="infos">
                                            <span>Com bebida:</span>
                                            <span>
                                                {menu.prices.drink.isActive
                                                    ? `${menu.prices.drink.price}€`
                                                    : "-"}
                                            </span>
                                            <span>Sem bebida:</span>
                                            <span>
                                                {menu.prices.drink.isActive
                                                    ? `${menu.prices.drink.price}€`
                                                    : "-"}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>

                {isOptionsCreateActive && (
                    <div className="optionsContainer">
                        <div className="top">
                            <div className="menuName">
                                <span>Nome</span>
                                <input
                                    onChange={(e) =>
                                        setNewMenu((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))
                                    }
                                    placeholder="Nome do menu"
                                />
                            </div>
                            <div className="pricesDrink">
                                <div className="drinkLine first">
                                    <Switch
                                        checked={newMenu.prices.drink.isActive}
                                        onChange={() =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    drink: {
                                                        ...prev.prices.drink,
                                                        isActive:
                                                            !prev.prices.drink
                                                                .isActive,
                                                    },
                                                },
                                            }))
                                        }
                                        onColor="#77B9B4"
                                        offColor="#E9E9E9"
                                        onHandleColor="#000000"
                                        offHandleColor="#000000"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        width={40}
                                        height={20}
                                        handleDiameter={20}
                                    />
                                    <span>Preço com bebida</span>
                                    <input
                                        onChange={(e) =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    drink: {
                                                        ...prev.prices.drink,
                                                        price: Number(
                                                            e.target.value
                                                        ),
                                                    },
                                                },
                                            }))
                                        }
                                        type="number"
                                        className="priceDrink"
                                    />
                                </div>
                                <div className="drinkLine">
                                    <Switch
                                        checked={
                                            newMenu.prices.noDrink.isActive
                                        }
                                        onChange={() =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    noDrink: {
                                                        ...prev.prices.noDrink,
                                                        isActive:
                                                            !prev.prices.noDrink
                                                                .isActive,
                                                    },
                                                },
                                            }))
                                        }
                                        onColor="#77B9B4"
                                        offColor="#E9E9E9"
                                        onHandleColor="#000000"
                                        offHandleColor="#000000"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        width={40}
                                        height={20}
                                        handleDiameter={20}
                                    />
                                    <span>Preço sem bebida</span>
                                    <input
                                        onChange={(e) =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    noDrink: {
                                                        ...prev.prices.noDrink,
                                                        price: Number(
                                                            e.target.value
                                                        ),
                                                    },
                                                },
                                            }))
                                        }
                                        type="number"
                                        className="priceDrink"
                                    />
                                </div>
                            </div>
                        </div>

                        <h2>Disponibilidade</h2>
                        <div className="timeLine">
                            <div className="timeButtons">
                                <button
                                    onClick={() =>
                                        setNewMenu((prev) => ({
                                            ...prev,
                                            availability: {
                                                ...prev.availability,
                                                status: 1,
                                            },
                                        }))
                                    }
                                    style={
                                        newMenu.availability.status === 1
                                            ? {
                                                  marginRight: "0.938rem",
                                                  background: "#2F2F2F",
                                              }
                                            : { marginRight: "0.938rem" }
                                    }
                                >
                                    <span
                                        style={
                                            newMenu.availability.status === 1
                                                ? { color: "#FFFFFF" }
                                                : {}
                                        }
                                    >
                                        Sempre
                                    </span>
                                </button>
                                <button
                                    onClick={() =>
                                        setNewMenu((prev) => ({
                                            ...prev,
                                            availability: {
                                                ...prev.availability,
                                                status: 2,
                                            },
                                        }))
                                    }
                                    style={
                                        newMenu.availability.status === 2
                                            ? { background: "#2F2F2F" }
                                            : {}
                                    }
                                >
                                    <span
                                        style={
                                            newMenu.availability.status === 2
                                                ? { color: "#FFFFFF" }
                                                : {}
                                        }
                                    >
                                        Período específico
                                    </span>
                                </button>
                            </div>
                            <div
                                className="dateDiv"
                                style={
                                    newMenu.availability.status === 1
                                        ? { display: "none" }
                                        : {}
                                }
                            >
                                <CalendarDropdown
                                    selectedStart={calendarSelectedStart}
                                    setSelectedStart={setCalendarSelectedStart}
                                    selectedEnd={calendarSelectedEnd}
                                    setSelectedEnd={setCalendarSelectedEnd}
                                    isActive={isCalendarActive}
                                    setIsActive={setIsCalendarActive}
                                    circularButtton={true}
                                />
                                <p className="date">
                                    {calendarSelectedEnd
                                        ? convertDateToString(
                                              calendarSelectedStart
                                          ) +
                                          " - " +
                                          convertDateToString(
                                              calendarSelectedEnd
                                          )
                                        : convertDateToString(
                                              calendarSelectedStart
                                          ) + " - "}
                                </p>
                            </div>
                        </div>

                        <div className="middle">
                            <div className="togglesSection">
                                <div
                                    className="toggleColumn"
                                    style={{ marginRight: "5.188rem" }}
                                >
                                    <h3>Almoço</h3>
                                    {weekdays.map((weekday, idx) => (
                                        <div
                                            className="toggleDay"
                                            key={idx}
                                            style={
                                                weekday === "Domingo"
                                                    ? { marginBottom: "0" }
                                                    : {}
                                            }
                                        >
                                            <Switch
                                                checked={newMenu.lunch[idx]}
                                                onChange={() =>
                                                    handleToggle(idx)
                                                }
                                                onColor="#77B9B4"
                                                offColor="#E9E9E9"
                                                onHandleColor="#000000"
                                                offHandleColor="#000000"
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                width={40}
                                                height={20}
                                                handleDiameter={20}
                                            />
                                            <span className="day">
                                                {weekday}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="toggleColumn">
                                    <h3>Jantar</h3>
                                    {weekdays.map((weekday, idx) => (
                                        <div
                                            className="toggleDay"
                                            key={idx}
                                            style={
                                                weekday === "Domingo"
                                                    ? { marginBottom: "0" }
                                                    : {}
                                            }
                                        >
                                            <Switch
                                                checked={newMenu.dinner[idx]}
                                                onChange={() =>
                                                    handleToggle(idx, true)
                                                }
                                                onColor="#77B9B4"
                                                offColor="#E9E9E9"
                                                onHandleColor="#000000"
                                                offHandleColor="#000000"
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                width={40}
                                                height={20}
                                                handleDiameter={20}
                                            />
                                            <span className="day">
                                                {weekday}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button
                                onClick={() =>
                                    setIsEditItemsCreateMenuActive(true)
                                }
                                className="defineItems"
                            >
                                <span>Definir Artigos</span>
                            </button>{" "}
                            {/*TODO: style span*/}
                        </div>

                        <div className="menuFooter">
                            <span
                                onClick={() => {
                                    setIsOptionsCreateActive(false);
                                    setIsAddMenuActive(false);
                                    clearNewMenu();
                                    setMenuDishType("comida");
                                }}
                                className="deleteMenu"
                            >
                                Cancelar
                            </span>
                            <button
                                onClick={handleSaveMenu}
                                className="saveMenu"
                            >
                                <span>Guardar</span>
                            </button>
                        </div>
                    </div>
                )}

                {isEditMenuActive !== -1 && (
                    <div className="optionsContainer">
                        <div className="top">
                            <div className="menuName">
                                <span>Nome</span>
                                <input
                                    value={newMenu.name}
                                    onChange={(e) =>
                                        setNewMenu((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))
                                    }
                                    placeholder="Nome do menu"
                                />
                            </div>
                            <div className="pricesDrink">
                                <div className="drinkLine first">
                                    <Switch
                                        checked={newMenu.prices.drink.isActive}
                                        onChange={() =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    drink: {
                                                        ...prev.prices.drink,
                                                        isActive:
                                                            !prev.prices.drink
                                                                .isActive,
                                                    },
                                                },
                                            }))
                                        }
                                        onColor="#77B9B4"
                                        offColor="#E9E9E9"
                                        onHandleColor="#000000"
                                        offHandleColor="#000000"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        width={40}
                                        height={20}
                                        handleDiameter={20}
                                    />
                                    <span>Preço com bebida</span>
                                    <input
                                        value={newMenu.prices.drink.price}
                                        onChange={(e) =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    drink: {
                                                        ...prev.prices.drink,
                                                        price: Number(
                                                            e.target.value
                                                        ),
                                                    },
                                                },
                                            }))
                                        }
                                        type="number"
                                        className="priceDrink"
                                    />
                                </div>
                                <div className="drinkLine">
                                    <Switch
                                        checked={
                                            newMenu.prices.noDrink.isActive
                                        }
                                        onChange={() =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    noDrink: {
                                                        ...prev.prices.noDrink,
                                                        isActive:
                                                            !prev.prices.noDrink
                                                                .isActive,
                                                    },
                                                },
                                            }))
                                        }
                                        onColor="#77B9B4"
                                        offColor="#E9E9E9"
                                        onHandleColor="#000000"
                                        offHandleColor="#000000"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        width={40}
                                        height={20}
                                        handleDiameter={20}
                                    />
                                    <span>Preço sem bebida</span>
                                    <input
                                        value={newMenu.prices.noDrink.price}
                                        onChange={(e) =>
                                            setNewMenu((prev) => ({
                                                ...prev,
                                                prices: {
                                                    ...prev.prices,
                                                    noDrink: {
                                                        ...prev.prices.noDrink,
                                                        price: Number(
                                                            e.target.value
                                                        ),
                                                    },
                                                },
                                            }))
                                        }
                                        type="number"
                                        className="priceDrink"
                                    />
                                </div>
                            </div>
                        </div>

                        <h2>Disponibilidade</h2>
                        <div className="timeLine">
                            <div className="timeButtons">
                                <button
                                    onClick={() =>
                                        setNewMenu((prev) => ({
                                            ...prev,
                                            availability: {
                                                ...prev.availability,
                                                status: 1,
                                            },
                                        }))
                                    }
                                    style={
                                        newMenu.availability.status === 1
                                            ? {
                                                  marginRight: "0.938rem",
                                                  background: "#2F2F2F",
                                              }
                                            : { marginRight: "0.938rem" }
                                    }
                                >
                                    <span
                                        style={
                                            newMenu.availability.status === 1
                                                ? { color: "#FFFFFF" }
                                                : {}
                                        }
                                    >
                                        Sempre
                                    </span>
                                </button>
                                <button
                                    onClick={() =>
                                        setNewMenu((prev) => ({
                                            ...prev,
                                            availability: {
                                                ...prev.availability,
                                                status: 2,
                                            },
                                        }))
                                    }
                                    style={
                                        newMenu.availability.status === 2
                                            ? { background: "#2F2F2F" }
                                            : {}
                                    }
                                >
                                    <span
                                        style={
                                            newMenu.availability.status === 2
                                                ? { color: "#FFFFFF" }
                                                : {}
                                        }
                                    >
                                        Período específico
                                    </span>
                                </button>
                            </div>
                            <div
                                className="dateDiv"
                                style={
                                    newMenu.availability.status === 1
                                        ? { display: "none" }
                                        : {}
                                }
                            >
                                <CalendarDropdown
                                    selectedStart={
                                        newMenu.availability.startDate
                                    }
                                    setSelectedStart={setCalendarSelectedStart}
                                    selectedEnd={newMenu.availability.endDate}
                                    setSelectedEnd={setCalendarSelectedEnd}
                                    isActive={isCalendarActive}
                                    setIsActive={setIsCalendarActive}
                                    circularButtton={true}
                                />
                                <p className="date">
                                    {newMenu.availability.endDate
                                        ? convertDateToString(
                                              newMenu.availability.startDate
                                          ) +
                                          " - " +
                                          convertDateToString(
                                              newMenu.availability.endDate
                                          )
                                        : convertDateToString(
                                              newMenu.availability.startDate
                                          ) + " - "}
                                </p>
                            </div>
                        </div>

                        <div className="middle">
                            <div className="togglesSection">
                                <div
                                    className="toggleColumn"
                                    style={{ marginRight: "5.188rem" }}
                                >
                                    <h3>Almoço</h3>
                                    {weekdays.map((weekday, idx) => (
                                        <div
                                            className="toggleDay"
                                            key={idx}
                                            style={
                                                weekday === "Domingo"
                                                    ? { marginBottom: "0" }
                                                    : {}
                                            }
                                        >
                                            <Switch
                                                checked={newMenu.lunch[idx]}
                                                onChange={() =>
                                                    handleToggle(idx)
                                                }
                                                onColor="#77B9B4"
                                                offColor="#E9E9E9"
                                                onHandleColor="#000000"
                                                offHandleColor="#000000"
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                width={40}
                                                height={20}
                                                handleDiameter={20}
                                            />
                                            <span className="day">
                                                {weekday}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="toggleColumn">
                                    <h3>Jantar</h3>
                                    {weekdays.map((weekday, idx) => (
                                        <div
                                            className="toggleDay"
                                            key={idx}
                                            style={
                                                weekday === "Domingo"
                                                    ? { marginBottom: "0" }
                                                    : {}
                                            }
                                        >
                                            <Switch
                                                checked={newMenu.dinner[idx]}
                                                onChange={() =>
                                                    handleToggle(idx, true)
                                                }
                                                onColor="#77B9B4"
                                                offColor="#E9E9E9"
                                                onHandleColor="#000000"
                                                offHandleColor="#000000"
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                width={40}
                                                height={20}
                                                handleDiameter={20}
                                            />
                                            <span className="day">
                                                {weekday}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="buttonsSection">
                                {newMenu.dishes.length === 0 && (
                                    <div className="typeButtons">
                                        <button
                                            onClick={() =>
                                                setMenuDishType("comida")
                                            }
                                            style={
                                                menuDishType === "comida"
                                                    ? { background: "#77B9B4" }
                                                    : {}
                                            }
                                        >
                                            <span>Comida</span>
                                        </button>
                                        <button
                                            onClick={() =>
                                                setMenuDishType("bebida")
                                            }
                                            style={
                                                menuDishType === "bebida"
                                                    ? {
                                                          marginLeft: "2.25rem",
                                                          background: "#77B9B4",
                                                      }
                                                    : { marginLeft: "2.25rem" }
                                            }
                                        >
                                            <span>Bebidas</span>
                                        </button>
                                    </div>
                                )}
                                <div className="menuDropdown">
                                    <MultiLayerDropdown2
                                        tabs={multiLayerDropdownTabs}
                                        selected={dropdownSelectedMenus[0]}
                                        setSelected={handleMenusDropdownSelect}
                                        isActive={isDropdownActiveMenus[0]}
                                        setIsActive={handleMenusActivate}
                                        order={0}
                                        width="11.938rem"
                                    />
                                </div>

                                {dishActiveDays.isActive === 0 && (
                                    <div className="weeklyFixedDish">
                                        {weekdays.map((weekday, idx) => (
                                            <span
                                                className="day"
                                                key={idx}
                                                onClick={() =>
                                                    setDishActiveDays(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            days: prevState.days.map(
                                                                (day, i) =>
                                                                    i === idx
                                                                        ? !day
                                                                        : day
                                                            ),
                                                        })
                                                    )
                                                }
                                                style={
                                                    dishActiveDays.days[idx]
                                                        ? {
                                                              backgroundColor:
                                                                  "#0E4B40",
                                                              color: "white",
                                                          }
                                                        : {}
                                                }
                                            >
                                                {weekday.slice(0, 3)}
                                            </span>
                                        ))}
                                    </div>
                                )}

                                {variableDishDescription.isActive === 0 && (
                                    <div className="variableDish">
                                        <span className="title">Descrição</span>
                                        <input
                                            className="input"
                                            onChange={(e) =>
                                                setVariableDishDescription(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        description:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                            onKeyDown={(e) => handleKeyDown(e)}
                                        />
                                    </div>
                                )}

                                {newMenu.dishes &&
                                    newMenu.dishes[0] &&
                                    newMenu.dishes[0].hasOwnProperty(
                                        "name"
                                    ) && (
                                        <div className="menuDish">
                                            <Dish
                                                heading={newMenu.dishes[0].name}
                                                description={
                                                    newMenu.dishes[0]
                                                        .description
                                                }
                                                price={
                                                    newMenu.dishes[0].prices[0]
                                                        .price
                                                }
                                                image={
                                                    newMenu.dishes[0].images &&
                                                    newMenu.dishes[0].images[0]
                                                }
                                                sort={false}
                                            />
                                        </div>
                                    )}

                                {newMenu.dishes.length > 0 &&
                                    newMenu.dishes.map((dish, idx) => (
                                        <div key={idx}>
                                            {newMenu.dishes.length ===
                                                idx + 1 && (
                                                <div className="typeButtons">
                                                    <button
                                                        onClick={() =>
                                                            setMenuDishType(
                                                                "comida"
                                                            )
                                                        }
                                                        style={
                                                            menuDishType ===
                                                            "comida"
                                                                ? {
                                                                      background:
                                                                          "#77B9B4",
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        <span>Comida</span>
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            setMenuDishType(
                                                                "bebida"
                                                            )
                                                        }
                                                        style={
                                                            menuDishType ===
                                                            "bebida"
                                                                ? {
                                                                      marginLeft:
                                                                          "2.25rem",
                                                                      background:
                                                                          "#77B9B4",
                                                                  }
                                                                : {
                                                                      marginLeft:
                                                                          "2.25rem",
                                                                  }
                                                        }
                                                    >
                                                        <span>Bebidas</span>
                                                    </button>
                                                </div>
                                            )}
                                            <div className="menuDropdown">
                                                <MultiLayerDropdown2
                                                    tabs={
                                                        multiLayerDropdownTabs
                                                    }
                                                    selected={
                                                        dropdownSelectedMenus[
                                                            idx + 1
                                                        ]
                                                    }
                                                    setSelected={
                                                        handleMenusDropdownSelect
                                                    }
                                                    isActive={
                                                        isDropdownActiveMenus[
                                                            idx + 1
                                                        ]
                                                    }
                                                    setIsActive={
                                                        handleMenusActivate
                                                    }
                                                    order={idx + 1}
                                                    width="11.938rem"
                                                />
                                            </div>

                                            {dishActiveDays.isActive ===
                                                idx && (
                                                <div className="weeklyFixedDish">
                                                    {weekdays.map(
                                                        (weekday, idx) => (
                                                            <span
                                                                className="day"
                                                                key={idx}
                                                                onClick={() =>
                                                                    setDishActiveDays(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            ...prevState,
                                                                            days: prevState.days.map(
                                                                                (
                                                                                    day,
                                                                                    i
                                                                                ) =>
                                                                                    i ===
                                                                                    idx
                                                                                        ? !day
                                                                                        : day
                                                                            ),
                                                                        })
                                                                    )
                                                                }
                                                                style={
                                                                    dishActiveDays
                                                                        .days[
                                                                        idx
                                                                    ]
                                                                        ? {
                                                                              backgroundColor:
                                                                                  "#0E4B40",
                                                                              color: "white",
                                                                          }
                                                                        : {}
                                                                }
                                                            >
                                                                {weekday.slice(
                                                                    0,
                                                                    3
                                                                )}
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            {variableDishDescription.isActive ===
                                                idx && (
                                                <div className="variableDish">
                                                    <span className="title">
                                                        Descrição
                                                    </span>
                                                    <input
                                                        className="input"
                                                        onChange={(e) =>
                                                            setVariableDishDescription(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    description:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            )
                                                        }
                                                        onKeyDown={(e) =>
                                                            handleKeyDown(e)
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {newMenu.dishes[idx + 1] &&
                                                newMenu.dishes[
                                                    idx + 1
                                                ].hasOwnProperty("name") && (
                                                    <div className="menuDish">
                                                        <Dish
                                                            heading={
                                                                newMenu.dishes[
                                                                    idx + 1
                                                                ].name
                                                            }
                                                            description={
                                                                newMenu.dishes[
                                                                    idx + 1
                                                                ].description
                                                            }
                                                            price={
                                                                newMenu.dishes[
                                                                    idx + 1
                                                                ].prices[0]
                                                                    .price
                                                            }
                                                            image={
                                                                newMenu.dishes[
                                                                    idx + 1
                                                                ].images[0]
                                                            }
                                                            sort={false}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className="menuFooter">
                            <span
                                onClick={() => {
                                    setIsEditMenuActive(-1);
                                    clearNewMenu();
                                }}
                                className="deleteMenu"
                            >
                                Cancelar
                            </span>
                            <button
                                onClick={handleEditMenu}
                                className="saveMenu"
                            >
                                <span>Guardar</span>
                            </button>
                        </div>
                    </div>
                )}

                {addDishFromListMenus !== -1 && (
                    <div className="slideIn fixedPlate-list">
                        <h1>Lista de artigos</h1>
                        <SearchBar
                            width="32.688rem"
                            text="Pesquisar na lista de pratos"
                            margin="0 0 0 3.875rem"
                            background="#E9E9E9"
                        />

                        <div className="dishes">
                            {itemList.map((item, idx) => (
                                <DishForSlideIn
                                    title={item.name}
                                    description={item.description}
                                    price={item.prices[0].price}
                                    image={item.images[0]}
                                    margin="1.188rem 7.625rem 0 3.875rem"
                                    onClick={() =>
                                        handleAddDishFromListMenu(idx)
                                    }
                                    key={idx}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {isAddMenuDishActive !== -1 && (
                    <div className="slideIn add-dish">
                        <h1>Nome Prato</h1>
                        <div className="inputs">
                            <div className="name">
                                <h2>Nome</h2>
                                <input
                                    onChange={(e) =>
                                        setAddNewMenuDish((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))
                                    }
                                    placeholder="Nome item"
                                />
                            </div>
                            <div className="priceContainer">
                                {menuDishVariations.map((dish, idx) => (
                                    <div className="priceDiv" key={idx}>
                                        <div className="price">
                                            <span>€</span>
                                            <input
                                                value={
                                                    menuDishVariations[idx]
                                                        .price === 0
                                                        ? ""
                                                        : menuDishVariations[
                                                              idx
                                                          ].price.toString()
                                                }
                                                onChange={(e) =>
                                                    setMenuDishVariations(
                                                        addMiniDescription(
                                                            menuDishVariations,
                                                            idx,
                                                            "price",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    )
                                                }
                                                type="number"
                                            />
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (
                                                    menuDishVariations.length ===
                                                    idx + 1
                                                ) {
                                                    setMenuDishVariations(
                                                        (prev) => [
                                                            ...prev,
                                                            {
                                                                price: 0,
                                                                description: "",
                                                            },
                                                        ]
                                                    );
                                                } else {
                                                    setMenuDishVariations(
                                                        (prevArr) =>
                                                            idx >= 0 &&
                                                            idx < prevArr.length
                                                                ? [
                                                                      ...prevArr.slice(
                                                                          0,
                                                                          idx
                                                                      ),
                                                                      ...prevArr.slice(
                                                                          idx +
                                                                              1
                                                                      ),
                                                                  ]
                                                                : prevArr
                                                    );
                                                }
                                            }}
                                        >
                                            <img
                                                src={
                                                    menuDishVariations.length ===
                                                    idx + 1
                                                        ? whitePlus
                                                        : minus
                                                }
                                            />
                                        </button>
                                        <input
                                            value={
                                                menuDishVariations[idx]
                                                    .description
                                            }
                                            onChange={(e) =>
                                                setMenuDishVariations(
                                                    addMiniDescription(
                                                        menuDishVariations,
                                                        idx,
                                                        "description",
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            style={
                                                menuDishVariations.length === 1
                                                    ? { display: "none" }
                                                    : {}
                                            }
                                            placeholder="Descrição"
                                            className="secondaryDescription"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="description">
                            <h2>Descrição</h2>
                            <textarea
                                onChange={(e) =>
                                    setAddNewMenuDish((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                    }))
                                }
                                placeholder="Descrição prato..."
                            />
                        </div>

                        <div className="accompaniments">
                            <div className="title-button">
                                <h2>Acompanhamentos</h2>
                                <button
                                    onClick={() => alert("In Construction")}
                                >
                                    <img src={plus} alt="plus" />
                                    <span>Adicionar acompanhamento</span>
                                </button>
                            </div>
                            <p>
                                Adicione um acompanhamento com identidade a
                                partir da sua lista de pratos
                            </p>
                        </div>

                        <div className="dishOptions">
                            <h2>Opções</h2>
                            <button onClick={() => alert("In Construction")}>
                                <img src={plus} alt="plus" />
                                <span>Adicionar opções</span>
                            </button>
                        </div>

                        <div className="photos">
                            <h2>Fotos</h2>
                            <div className="album">
                                {addNewMenuDish.images &&
                                    addNewMenuDish.images.map((url, idx) => (
                                        <img
                                            className="photo"
                                            src={url}
                                            alt="dish image"
                                            key={idx}
                                        />
                                    ))}
                                <label htmlFor="file">
                                    <img
                                        className="addPhotoBtn"
                                        src={crossButton}
                                    />
                                </label>
                                <input
                                    type="file"
                                    id="file"
                                    onChange={(e) => {
                                        uploadImage(e.target.files[0], "menu");
                                    }}
                                />
                            </div>
                        </div>

                        <div className="caracteristics">
                            <h2>Características</h2>
                            <div className="row">
                                {caracteristics.map((caracteristic, idx) => (
                                    <button
                                        onClick={() =>
                                            handleCaracteristicClick(
                                                caracteristic,
                                                3
                                            )
                                        }
                                        className="cat"
                                        style={
                                            addNewMenuDish.caracteristics[
                                                caracteristicsCorrespondency[
                                                    caracteristic
                                                ]
                                            ]
                                                ? { background: "#77B9B4" }
                                                : {}
                                        }
                                        key={idx}
                                    >
                                        <p>{caracteristic}</p>
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="code">
                            <div className="firstLine">
                                <h2>Código</h2>
                                <input
                                    onChange={(e) =>
                                        setAddNewMenuDish((prev) => ({
                                            ...prev,
                                            code: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                            <p>Adicione um código ao prato (não obrigatório)</p>
                        </div>

                        <div className="buttons">
                            <button
                                onClick={() => {
                                    setIsAddMenuDishActive(-1);
                                    resetDish("menuDish");
                                    setMenuDishVariations([
                                        { price: 0, description: "" },
                                    ]);
                                }}
                                className="cancel"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={() => saveNewItem("menu")}
                                className="save"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    else if (isEditItemsCreateMenuActive)
        return (
            <div className="menuPage">
                <div className="addItems">
                    <div className="header">
                        <img
                            src={backButton}
                            onClick={() =>
                                setIsEditItemsCreateMenuActive(false)
                            }
                            alt="back"
                            className="backIcon"
                        />
                        <span className="title">Menu do Dia</span>
                    </div>
                </div>

                <DishTypeButtons
                    dishTypeSelected={dishTypeSelected}
                    setDishTypeSelected={setDishTypeSelected}
                    isEditCategoriesActive={isEditCategoriesActive}
                    setIsEditCategoriesActive={setIsEditCategoriesActive}
                />

                <div className="categoryDiv">
                    <div>
                        <h2>Teste</h2>
                    </div>
                </div>

                {isEditCategoriesActive && (
                    <div className="slideIn edit-categories">
                        <h1>Editar categorias</h1>

                        <div className="categories">
                            <h2
                                style={
                                    (dishTypeSelected === "comida"
                                        ? menuDishCategories &&
                                          menuDishCategories.length > 0
                                        : menuDrinkCategories &&
                                          menuDrinkCategories.length > 0) ||
                                    (menuCategoriesToBeSaved &&
                                        menuCategoriesToBeSaved.length > 0)
                                        ? {}
                                        : { display: "none" }
                                }
                            >
                                Ordem categorias
                            </h2>
                            <div className="list">
                                {menuDishCategories &&
                                    menuDrinkCategories &&
                                    (dishTypeSelected === "comida"
                                        ? menuDishCategories
                                        : menuDrinkCategories
                                    ).map((category, idx) => (
                                        <div key={idx}>
                                            {!menuCategoriesToBeDeleted.includes(
                                                category.categoryId
                                            ) && (
                                                <div className="category">
                                                    <img
                                                        src={menu}
                                                        alt="sort"
                                                        style={{
                                                            /*TODO*/ display:
                                                                "none",
                                                        }}
                                                    />
                                                    <p>
                                                        {category.categoryName}
                                                    </p>
                                                    <img
                                                        onClick={() => {
                                                            setMenuCategoriesToBeDeleted(
                                                                (prev) => [
                                                                    ...prev,
                                                                    category.categoryId,
                                                                ]
                                                            );
                                                        }}
                                                        className="delete"
                                                        src={plus}
                                                        alt="delete"
                                                    />
                                                    <img
                                                        src={pencil}
                                                        alt="edit"
                                                        className="edit"
                                                        style={{
                                                            /*TODO*/ display:
                                                                "none",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                {menuCategoriesToBeSaved &&
                                    menuCategoriesToBeSaved.map(
                                        (category, idx) => (
                                            <div className="category" key={idx}>
                                                <img
                                                    src={menu}
                                                    alt="sort"
                                                    style={{
                                                        /*TODO*/ display:
                                                            "none",
                                                    }}
                                                />
                                                <p>{category}</p>
                                                <img
                                                    className="delete"
                                                    src={plus}
                                                    alt="delete"
                                                />
                                                <img
                                                    src={pencil}
                                                    alt="edit"
                                                    className="edit"
                                                    style={{
                                                        /*TODO*/ display:
                                                            "none",
                                                    }}
                                                />
                                            </div>
                                        )
                                    )}
                            </div>
                            <button
                                onClick={() =>
                                    setEditCategoryExtensionIsActive(
                                        !editCategoryExtensionIsActive
                                    )
                                }
                                style={
                                    editCategoryExtensionIsActive
                                        ? { backgroundColor: "#2F2F2F" }
                                        : {}
                                }
                            >
                                <img
                                    src={
                                        editCategoryExtensionIsActive
                                            ? whitePlus
                                            : plus
                                    }
                                    alt="plus"
                                />
                                <p
                                    style={
                                        editCategoryExtensionIsActive
                                            ? { color: "#FFFFFF" }
                                            : {}
                                    }
                                >
                                    Adicionar categoria
                                </p>
                            </button>
                        </div>
                        {editCategoryExtensionIsActive && (
                            <div className="extension">
                                <h2>Nome</h2>
                                <input
                                    onChange={(e) =>
                                        setNewCategoryName(e.target.value)
                                    }
                                    placeholder="Nome categoria"
                                />
                                <button
                                    onClick={() => {
                                        setMenuCategoriesToBeSaved(
                                            (prevCategorys) => [
                                                ...prevCategorys,
                                                newCategoryName,
                                            ]
                                        );
                                        setNewCategoryName("");
                                        setEditCategoryExtensionIsActive(false);
                                    }}
                                    className="save"
                                >
                                    Guardar
                                </button>
                            </div>
                        )}

                        <div className="buttons">
                            <button
                                onClick={() => {
                                    setIsEditCategoriesActive(false);
                                    setMenuCategoriesToBeSaved([]);
                                    setMenuCategoriesToBeDeleted([]);
                                    setEditCategoryExtensionIsActive(false);
                                }}
                                className="cancel"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={() => handleCategorySave()}
                                className="save"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
};

export default MenusPage;
