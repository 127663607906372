import React from 'react';
import './calendarDropdown.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import blackArrow from '../../utils/logos/blackArrow.svg';
import arrow from '../../utils/logos/arrow.svg';
import calendarButton from '../../utils/logos/calendarButton.png';

const CalendarDropdown = ({ selectedStart, setSelectedStart, selectedEnd, setSelectedEnd, isActive, setIsActive, circularButtton = false }) => {
  const currentDateStart = selectedStart ? new Date(selectedStart) : null;
  const currentDateEnd = selectedEnd ? new Date(selectedEnd) : null

  const onChange = (dates) => {
    const [start, end] = dates;
    setSelectedStart(start.toISOString());
    setSelectedEnd(end !== null ? end.toISOString() : null);

    if (start && end)
      setIsActive(false);
  };

  return (
    <div className="calendarDropdown">
      { !circularButtton ? (
        <div className="dropdown-btn" onClick={() => setIsActive(!isActive)} style={isActive ? { backgroundColor: '#2F2F2F', color: '#FFFFFF' } : {}}>
          <p style={isActive ? { color: '#FFFFFF' } : {}}>{selectedStart.toLocaleString().slice(0, 10)}</p>
          <img src={isActive ? arrow : blackArrow} alt="arrow" />
        </div>
      ) : (
        <img className="circular-btn" onClick={() => setIsActive(!isActive)} src={calendarButton} alt="calendário" />
      )}

      {isActive && (
        <div className="dropdown-content">
          <DatePicker selected={currentDateStart} onChange={onChange} startDate={currentDateStart} endDate={currentDateEnd} selectsRange inline />
        </div>
      )}
    </div>
  );
};

export default CalendarDropdown;
