import "../authentication/authenticationAndSetup.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { emailValidate, passwordValidate } from "../../utils/form-validate";
import { setupUserEndpoint, uploadImageEndpoint } from "../../backendFunctions";
import backButton from "../../utils/logos/backButton.svg";
import appleLogo from "../../utils/logos/appleLogo.png";
import googleLogo from "../../utils/logos/googleLogo.png";
import { useAuth } from "../../context/AuthContext";
import { Spinner } from "flowbite-react";
import { delay } from "../../utils/utils.js";

const Setup = ({ completePhoneNumber, tokenForUserSetup }) => {
  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);

  const [error, setError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = () => {
    setIsLoading(true);
    setError("");
    setUsernameError("");
    setEmailError("");

    if (!username) {
      setUsernameError(username.required.message);
      return;
    }

    if (!email) {
      setEmailError(emailValidate.required.message);
      return;
    }

    if (!emailValidate.pattern.value.test(email)) {
      setEmailError(emailValidate.pattern.message);
      return;
    }

    const userInfo = {
      phoneNumber: completePhoneNumber,
      username: username,
      email: email,
      image: image,
      role: "manager",
    };

    const fetchData = setupUserEndpoint(userInfo, tokenForUserSetup)
      .then((response) => {
        console.log(response.data);
        if (
          response.data.httpStatus === 200 &&
          response.data.message ===
            "user setup successful and manager level unlocked" &&
          !response.data.data.isNewUser
        ) {
          //setAuthorizationToken(response.data.data.token);
          Promise.all([fetchData, delay()]).then(() => {
            setIsLoading(false);
            login(response.data.data.token); // Set the token and log in
            navigate("/panel");
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleEmailSubmit = () => {
    setIsLoading(true);

    Promise.all([{}, delay()]).then(() => {
      setIsLoading(false);
      setIsEmailEntered(true);
    });
  };

  const uploadImage = (image) => {
    console.log(image);
    uploadImageEndpoint(image)
      .then((response) => {
        setImage(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      {!isEmailEntered && (
        <div className="authentication">
          <div className="auth-box">
            <img
              src={backButton}
              alt="back"
              className="backButton"
              onClick={() => navigate("/")}
            />
            <h1 className="title emailTitle">Email</h1>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="inputAndBtn emailInput"
              placeholder="Escreva o seu email"
            />
            <h1 className="title orTitle">ou</h1>
            <button className="inputAndBtn socialBtn">
              <img
                src={appleLogo}
                alt="apple logo"
                className="socialLogo w-[2.16063rem] h-[2.39381rem]"
              />
              Continuar com a Apple
            </button>
            <button className="inputAndBtn socialBtn secondSocial">
              <img
                src={googleLogo}
                alt="google logo"
                className="socialLogo w-[2.13738rem] h-[2.13738rem]"
              />
              Continuar com o Google
            </button>
            <button onClick={handleEmailSubmit} className="next-btn">
              {isLoading ? (
                <Spinner
                  className="fill-green-700"
                  aria-label="Spinner button example"
                  size="md"
                />
              ) : (
                "Próximo"
              )}
            </button>
          </div>
        </div>
      )}
      {isEmailEntered && (
        <div className="authentication">
          <div className="auth-box">
            <img
              src={backButton}
              onClick={() => {
                setIsEmailEntered(false);
              }}
              alt="back"
              className="backButton"
            />
            <h1 className="title emailTitle">Nome</h1>
            <input
              onChange={(e) => setUsername(e.target.value)}
              className="inputAndBtn emailInput nameInput"
              placeholder="Username"
            />
            <h1 className="title orTitle mb-0">Fotografia</h1>
            <span className="mt-[0.3rem]">(opcional)</span>

            {!image && (
              <div>
                <label htmlFor="file">
                  <div className="profilePicInput">+</div>
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => uploadImage(e.target.files[0])}
                  className="hidden"
                />
              </div>
            )}
            {image && <img src={image} className="profilePic" />}

            <button onClick={() => handleSubmit()} className="next-btn">
              {isLoading ? (
                <Spinner
                  className="fill-green-700"
                  aria-label="Spinner button example"
                  size="md"
                />
              ) : (
                "Guardar"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Setup;
