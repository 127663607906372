import "./feedback.css";
import { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { getAllFeedbacksEndpoint } from "../../backendFunctions";
import { Loading } from "../../components";
import { delay } from "../../utils/utils.js";

const ratingAreaRatesStyles = {
  fontSize: "1.313rem",
  lineHeight: "2.813rem",
};

const miniGraphsStyles = {
  textColor: "#000000",
  pathColor: "#77B9B4",
  textSize: "1.375rem",
};

const ratingAreaGraphStyles = {
  textColor: "#000000",
  pathColor: "#77B9B4",
  textSize: "1.875rem",
};

const Feedback = ({ restaurant, authorizationToken }) => {
  const [feedbacks, setFeedbacks] = useState(null);
  const [means, setMeans] = useState([0, 0, 0, 0, 0, 0]);
  const [feedbacksOverallRating, setFeedbacksOverallRating] = useState([
    0, 0, 0, 0, 0, 0,
  ]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = getAllFeedbacksEndpoint(
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.data) setFeedbacks(response.data.data.feedbacks);
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([fetchData, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (feedbacks) {
      setMeans(calculateMean());
      setFeedbacksOverallRating(countFeedbacksOverallRatings());
    }
  }, [feedbacks]);

  const calculateMean = () => {
    if (feedbacks) {
      const length = feedbacks.length;

      if (length === 0) return [0, 0, 0, 0, 0, 0];

      const foodTotal = feedbacks.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.food;
      }, 0);

      const spaceTotal = feedbacks.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.atmosphere;
      }, 0);

      const noiseTotal = feedbacks.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.noiseLevel;
      }, 0);

      const serviceTotal = feedbacks.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.service;
      }, 0);

      const qualityPriceTotal = feedbacks.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.priceQualityRatio;
        },
        0
      );

      const speedTotal = feedbacks.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.speed;
      }, 0);

      return [
        Math.round(foodTotal / length),
        Math.round(spaceTotal / length),
        Math.round(noiseTotal / length),
        Math.round(serviceTotal / length),
        Math.round(qualityPriceTotal / length),
        Math.round(speedTotal / length),
      ];
    }
  };

  const calculateMainRating = () => {
    if (feedbacks) {
      if (feedbacks.length === 0) return 0;

      const rating = feedbacks.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.overall;
      }, 0);

      return Math.round(rating / feedbacks.length);
    }
  };

  function countFeedbacksOverallRatings() {
    let counts = [0, 0, 0, 0, 0];

    if (feedbacks) {
      for (const feedback of feedbacks) {
        if (feedback.overall === 10) counts[0]++;
        else if (feedback.overall === 9) counts[1]++;
        else if (feedback.overall === 8) counts[2]++;
        else if (feedback.overall === 7) counts[3]++;
        else counts[4]++;
      }
    }

    return [counts[0], counts[1], counts[2], counts[3], counts[4]];
  }

  return (
    <div className="feedback">
      {isLoading && <Loading />}
      <div className={`w-full h-full ${isLoading ? "invisible" : undefined}`}>
        {feedbacks && (
          <div>
            <h1>Feedback</h1>
            <div className="graphArea">
              <CircularProgressbar
                className="mainGraph"
                value={feedbacks ? (100 / 10) * calculateMainRating() : 0}
                text={`${feedbacks ? calculateMainRating() : 0}/10`}
                strokeWidth={3}
                styles={buildStyles({
                  textColor: "#000000",
                  pathColor: "#77B9B4",
                  textSize: "0.9375rem",
                })}
              />

              <div className="parent">
                <div className="horizontal-progressBars">
                  <div className="categories">
                    <p>10</p>
                    <p>9</p>
                    <p>8</p>
                    <p>7</p>
                    <p>&lt;6</p>
                  </div>
                  <div>
                    <div className="progressBar">
                      <ProgressBar
                        completed={
                          feedbacks
                            ? (100 / feedbacks.length) *
                              feedbacksOverallRating[0]
                            : 0
                        }
                        width="10.5625rem"
                        height="0.4375rem"
                        isLabelVisible={false}
                        baseBgColor="#D9D9D9"
                        bgColor="#77B9B4"
                      />
                      <p className="value">
                        {feedbacks ? feedbacksOverallRating[0] : 0}
                      </p>
                    </div>
                    <div className="progressBar">
                      <ProgressBar
                        completed={
                          feedbacks
                            ? (100 / feedbacks.length) *
                              feedbacksOverallRating[1]
                            : 0
                        }
                        width="10.5625rem"
                        height="0.4375rem"
                        isLabelVisible={false}
                        baseBgColor="#D9D9D9"
                        bgColor="#77B9B4"
                      />
                      <p className="value">
                        {feedbacks ? feedbacksOverallRating[1] : 0}
                      </p>
                    </div>
                    <div className="progressBar">
                      <ProgressBar
                        completed={
                          feedbacks
                            ? (100 / feedbacks.length) *
                              feedbacksOverallRating[2]
                            : 0
                        }
                        width="10.5625rem"
                        height="0.4375rem"
                        isLabelVisible={false}
                        baseBgColor="#D9D9D9"
                        bgColor="#77B9B4"
                      />
                      <p className="value">
                        {feedbacks ? feedbacksOverallRating[2] : 0}
                      </p>
                    </div>
                    <div className="progressBar">
                      <ProgressBar
                        completed={
                          feedbacks
                            ? (100 / feedbacks.length) *
                              feedbacksOverallRating[3]
                            : 0
                        }
                        width="10.5625rem"
                        height="0.4375rem"
                        isLabelVisible={false}
                        baseBgColor="#D9D9D9"
                        bgColor="#77B9B4"
                      />
                      <p className="value">
                        {feedbacks ? feedbacksOverallRating[3] : 0}
                      </p>
                    </div>
                    <div className="progressBar">
                      <ProgressBar
                        completed={
                          feedbacks
                            ? (100 / feedbacks.length) *
                              feedbacksOverallRating[4]
                            : 0
                        }
                        width="10.5625rem"
                        height="0.4375rem"
                        isLabelVisible={false}
                        baseBgColor="#D9D9D9"
                        bgColor="#77B9B4"
                      />
                      <p className="value">
                        {feedbacks ? feedbacksOverallRating[4] : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <p>Total de {feedbacks ? feedbacks.length : 0} reviews.</p>
              </div>

              <div className="miniGraphs">
                <div className="column">
                  <div className="miniGraphDiv mb-[1.875rem]">
                    <CircularProgressbar
                      className="miniGraph"
                      value={feedbacks ? (100 / 10) * means[0] : 0}
                      text={feedbacks && means[0].toString()}
                      strokeWidth={4}
                      styles={buildStyles(miniGraphsStyles)}
                    />
                    <p>Comida</p>
                  </div>
                  <div className="miniGraphDiv">
                    <CircularProgressbar
                      className="miniGraph"
                      value={feedbacks ? (100 / 10) * means[3] : 0}
                      text={feedbacks && means[3].toString()}
                      strokeWidth={4}
                      styles={buildStyles(miniGraphsStyles)}
                    />
                    <p>Serviço</p>
                  </div>
                </div>
                <div className="column ml-[5.0625rem] mr-[3.375rem]">
                  <div className="miniGraphDiv mb-[1.875rem]">
                    <CircularProgressbar
                      className="miniGraph"
                      value={feedbacks ? (100 / 10) * means[1] : 0}
                      text={feedbacks && means[1].toString()}
                      strokeWidth={4}
                      styles={buildStyles(miniGraphsStyles)}
                    />
                    <p>Ambiente</p>
                  </div>
                  <div className="miniGraphDiv">
                    <CircularProgressbar
                      className="miniGraph"
                      value={feedbacks ? (100 / 10) * means[4] : 0}
                      text={feedbacks && means[4].toString()}
                      strokeWidth={4}
                      styles={buildStyles(miniGraphsStyles)}
                    />
                    <p>Preço/Qualidade</p>
                  </div>
                </div>
                <div className="column">
                  <div className="miniGraphDiv mb-[1.875rem]">
                    <CircularProgressbar
                      className="miniGraph"
                      value={feedbacks ? (100 / 10) * means[2] : 0}
                      text={feedbacks && means[2].toString()}
                      strokeWidth={4}
                      styles={buildStyles(miniGraphsStyles)}
                    />
                    <p>Noise level</p>
                  </div>
                  <div className="miniGraphDiv">
                    <CircularProgressbar
                      className="miniGraph"
                      value={feedbacks ? (100 / 10) * means[5] : 0}
                      text={feedbacks && means[5].toString()}
                      strokeWidth={4}
                      styles={buildStyles(miniGraphsStyles)}
                    />
                    <p>Tempo de espera</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="ratingArea"
              style={
                !feedbacks || feedbacks.length === 0 ? { display: "none" } : {}
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Reserva</th>
                    <th>Overall</th>
                    <th>Comida</th>
                    <th>Serviço</th>
                    <th>Ambiente</th>
                    <th>Preço / Qualidade</th>
                    <th>Noise level</th>
                    <th>Tempo de espera</th>
                    <th className="text-left">Comentário</th>
                  </tr>
                </thead>

                {feedbacks &&
                  feedbacks.map((feedback, idx) => (
                    <tbody key={idx}>
                      <tr>
                        <td className="date">{feedback.createdAt}</td>
                        <td className="graph">
                          <CircularProgressbar
                            value={(100 / 10) * feedback.overall}
                            text={feedback.overall}
                            strokeWidth={4}
                            styles={buildStyles(ratingAreaGraphStyles)}
                          />
                        </td>
                        <td>
                          <span style={ratingAreaRatesStyles}>
                            {feedback.food}
                          </span>
                          /10
                        </td>
                        <td>
                          <span style={ratingAreaRatesStyles}>
                            {feedback.service}
                          </span>
                          /10
                        </td>
                        <td>
                          <span style={ratingAreaRatesStyles}>
                            {feedback.atmosphere}
                          </span>
                          /10
                        </td>
                        <td>
                          <span style={ratingAreaRatesStyles}>
                            {feedback.priceQualityRatio}
                          </span>
                          /10
                        </td>
                        <td>
                          <span style={ratingAreaRatesStyles}>
                            {feedback.noiseLevel}
                          </span>
                          /10
                        </td>
                        <td>
                          <span style={ratingAreaRatesStyles}>
                            {feedback.speed}
                          </span>
                          /10
                        </td>
                        <td className="comment">{feedback.comment}</td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </div>
          </div>
        )}
        {!feedbacks && <h1>Sem Feedbacks...</h1>}
      </div>
    </div>
  );
};

export default Feedback;
