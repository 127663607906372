import "./menu.css";
import { useState, useEffect } from "react";
import { Loading, PageTabs } from "../../components";
import { ItemList, MenusPage, EmentaPage, WinePage } from "../index.js";
import {
  Dish,
  MenuDish,
  DishWithoutCategory,
  DishPrice,
  VariableDishes,
  DrinkCart,
  HouseWine,
  WinePriceSizes,
} from "../../restaurantModels/RestaurantPublic";
import { Item, Drink } from "../../restaurantModels/RestaurantPrivate";
import {
  getAllDishesItemListEndpoint,
  getDishCategoriesEndpoint,
  addDishCategoryEndpoint,
  deleteDishCategoryEndpoint,
  uploadImageEndpoint,
  addDishItemListEndpoint,
  deleteDishItemListEndpoint,
  updateDishItemListEndpoint,
  getAllHouseWinesEndpoint,
  addHouseWineEndpoint,
  getDrinksItemListEndpoint,
  addDrinkItemListEndpoint,
  deleteDrinkItemListEndpoint,
  updateDrinkItemListEndpoint,
  getAllDishesCartEndpoint,
  addFixedDishEndpoint,
  adddDishFromItemListEndpoint,
  updateDishCartEndpoint,
  createDishOfTheDayEndpoint,
  getDishesOfTheDayEndpoint,
  getCartDrinksEndpoint,
  addCartDrinksFromItemListEndpoint,
  addCartDrinkEndpoint,
  updateCartDrinkEndpoint,
  createDrinkOfTheDayEndpoint,
  getDrinksOfTheDayEndpoint,
  updateHouseWineEndpoint,
  deleteHouseWineByIdEndpoint,
  getAllDirectoryWinesEndpoint,
  addDirectoryWineEndpoint,
  deleteDirectoryWineEndpoint,
  getDrinkCategoriesEndpoint,
  addDrinkCategoryEndpoint,
  deleteDrinkCategoryEndpoint,
} from "../../backendFunctions";
import { v4 } from "uuid";
import { delay } from "../../utils/utils.js";

const tabs = ["Ementa", "Carta de vinhos", "Lista de artigos", "Menus"];
const multiLayerDropdownTabs = [
  {
    main: "Prato fixo",
    secondary: ["Da lista de pratos", "Criar novo prato"],
  },
  {
    main: "Prato do dia",
    secondary: ["Da lista de pratos", "Criar novo prato"],
  },
];
const weekdays = [
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado",
  "Domingo",
];
const caracteristics = ["Picante", "Sem glúten", "Vegetariano", "Vegan"];
const drinkCaracteristics = ["Alcoólica"];

const currentDate = new Date();
const formattedCurrentDate = currentDate;

const oneWeekBefore = new Date(currentDate);
oneWeekBefore.setDate(currentDate.getDate() - 7);
const formattedBeforeDate = oneWeekBefore;

const defaultDropdownTab = { main: "Adicionar prato", secondary: "" };

const Menu = ({ restaurant, authorizationToken }) => {
  const [menuSelectedTab, setMenuSelectedTab] = useState(tabs[0]);

  /* "Carta de vinhos" page state */
  const [addWineIsClicked, setAddWineIsClicked] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isBottleDropdownActive, setIsBottleDropdownActive] = useState(false);
  const [isMainYearDropdownActive, setIsMainYearDropdownActive] =
    useState(false);
  const [isYearDropdownActive, setIsYearDropdownActive] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [suggestionIsClicked, setSuggestionIsClicked] = useState(null);
  const [addWinePopupIsClicked, setAddWinePopupIsClicked] = useState(false);
  const [wines, setWines] = useState(null);
  const [wineData, setWineData] = useState({});
  const [wine_Directory, setWine_Directory] = useState([]);
  const [wineVolume, setWineVolume] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState("Adicionar vinho");
  const [houseWineState, setHouseWineState] = useState({
    wines: null,
    addHouseWine: false,
    wineId: null,
    wineName: null,
    wineImage: null,
    wineType: null,
    winePriceSizes: [
      {
        winePriceSizeId: null,
        wineSize: "75 cl",
        winePrice: 0,
      },
    ],
    winePriceSizeId: null,
    dropdownStatus: [false],
    dropdown: null,
    expandedType: null,
    expandedVolume: null,
    editHouseWine: false,
  });
  const [wineDirectoryState, setWineDirectoryState] = useState({
    wines: null,
    addWineDirectory: false,
    addToWineDirectory: false,
    wineId: null,
    wineName: null,
    wineImage: null,
    wineType: null,
    wineOrigin: null,
    wineRegion: null,
    wineFort: null,
    wineFortStyle: null,
    winePriceSizes: [
      {
        winePriceSizeId: null,
        wineSize: "75 cl",
        winePrice: 0,
      },
    ],
    dropdown: null,
    dropdownStatus: [false],
    expandedType: null,
    expandedVolume: null,
    editDirectoryWine: false,
  });

  //const wineDirectoryRef = collection(db, "wine_directory");

  /* "Ementa" page state */
  const [dishTypeSelected, setDishTypeSelected] = useState("comida");
  const [isDropdownActive, setIsDropdownActive] = useState([]);
  const [selectedDropdownTab, setSelectedDropdownTab] = useState([]);
  const [selectedDrinkDropdownTab, setSelectedDrinkDropdownTab] = useState([]);
  const [isEditCategoriesActive, setIsEditCategoriesActive] = useState(false);
  const [editCategoryExtensionIsActive, setEditCategoryExtensionIsActive] =
    useState(false);
  const [isEditDishSlideInActive, setIsEditDishSlideInActive] = useState(-1);

  const [categories, setCategories] = useState(null);
  const [categoriesToBeSaved, setCategoriesToBeSaved] = useState([]);
  const [categoriesToBeDeleted, setCategoriesToBeDeleted] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");

  const [drinkCategories, setDrinkCategories] = useState(null);

  const [addDishFromList, setAddDishFromList] = useState(-1);
  const [addDish, setAddDish] = useState(-1);
  const [dishes, setDishes] = useState(null);
  const [addNewDish, setAddNewDish] = useState(new Dish());
  const [dishVariations, setDishVariations] = useState([new DishPrice()]);
  const [dishActiveDays, setDishActiveDays] = useState({
    isActive: -1,
    days: [false, true, false, false, false, false, false],
  });
  const [variableDishDescription, setVariableDishDescription] = useState({
    isActive: -1,
    description: "",
  });
  const [drinksCart, setDrinksCart] = useState(null);
  const [isAddDrinkCartActive, setIsAddDrinkCartActive] = useState(-1);
  const [isEditDrinkCartActive, setIsEditDrinkCartActive] = useState(-1);
  const [isAddFromListDrinkCart, setIsAddFromListDrinkCart] = useState(-1);
  const [newCartDrink, setNewCartDrink] = useState(new DrinkCart());
  const [cartDrinkVariations, setCartDrinkVariations] = useState([
    new DishPrice(),
  ]);
  const [drinksOfTheDay, setDrinksOfTheDay] = useState(null);

  /* "Lista de artigos" page state */
  const [itemTypeSelected, setItemTypeSelected] = useState("comida");
  const [isEditItemSlideInActive, setIsEditItemSlideInActive] = useState(-1);
  const [addNewItem, setAddNewItem] = useState(new Item());
  const [itemList, setItemList] = useState(null);
  const [isAddItemActive, setIsAddItemActive] = useState(false);
  const [itemVariations, setItemVariations] = useState([new DishPrice()]);
  const [variableDishes, setVariableDishes] = useState(null);
  const [drinks, setDrinks] = useState(null);
  const [isAddDrinkActive, setIsAddDrinkActive] = useState(false);
  const [isEditDrinkActive, setIsEditDrinkActive] = useState(-1);
  const [newDrink, setNewDrink] = useState(new Drink());
  const [itemDrinkVariations, setItemDrinkVariations] = useState([
    new DishPrice(),
  ]);

  /* "Menus" page state */
  const [isAddMenuActive, setIsAddMenuActive] = useState(false);
  const [calendarSelectedStart, setCalendarSelectedStart] =
    useState(formattedBeforeDate);
  const [calendarSelectedEnd, setCalendarSelectedEnd] =
    useState(formattedCurrentDate);
  const [isCalendarActive, setIsCalendarActive] = useState(false);
  const [isOptionsCreateActive, setIsOptionsCreateActive] = useState(false);
  const [isDropdownActiveMenus, setIsDropdownActiveMenus] = useState([]);
  const [dropdownSelectedMenus, setDropdownSelectedMenus] = useState([
    defaultDropdownTab,
  ]);
  const [isEditMenuActive, setIsEditMenuActive] = useState(-1);
  const [addDishFromListMenus, setAddDishFromListMenus] = useState(-1);
  const [isAddMenuDishActive, setIsAddMenuDishActive] = useState(-1);
  const [menuDishType, setMenuDishType] = useState("comida");
  const [newMenu, setNewMenu] = useState(new MenuDish());
  const [menus, setMenus] = useState([]); //aqui TODO: meter null em vez de []
  const [addNewMenuDish, setAddNewMenuDish] = useState(
    new DishWithoutCategory()
  );
  const [menuDishVariations, setMenuDishVariations] = useState([
    new DishPrice(),
  ]);

  const [isLoading, setIsLoading] = useState(true);

  /* DB functions */
  useEffect(() => {
    getAllDishesItemListEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log("todos os items:", response.data);
        setItemList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getDishCategoriesEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log("todas as categorias:", response.data);
        setCategories(response.data.data);

        const newArray = Array.from(
          { length: response.data.data.length },
          () => defaultDropdownTab
        );
        setSelectedDropdownTab(newArray);
      })
      .catch((error) => {
        console.error(error);
      });

    fetchHouseWines();

    fetchDirectoryWines();

    getDrinksItemListEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setDrinks(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getAllDishesCartEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setDishes(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getDishesOfTheDayEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setVariableDishes(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getCartDrinksEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setDrinksCart(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getDrinksOfTheDayEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setDrinksOfTheDay(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    getDrinkCategoriesEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setDrinkCategories(response.data.data);

        const newArray = Array.from(
          { length: response.data.data.length },
          () => defaultDropdownTab
        );
        setSelectedDrinkDropdownTab(newArray);
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([{}, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (categories) {
      const newArray = Array.from(
        { length: categories.length },
        () => defaultDropdownTab
      );
      setSelectedDropdownTab(newArray);
    }
  }, [categories]);

  /* -------------------------------------------------------------------------------------------------- */

  useEffect(() => {
    setNewMenu((prev) => ({
      ...prev,
      availability: {
        ...prev.availability,
        startDate: calendarSelectedStart,
        endDate: calendarSelectedEnd,
      },
    }));
  }, [calendarSelectedStart, calendarSelectedEnd]);

  useEffect(() => {
    setAddNewDish((prev) => ({
      ...prev,
      dish: { ...prev.dish, prices: dishVariations },
    }));
  }, [dishVariations]);

  useEffect(() => {
    setAddNewItem((prev) => ({ ...prev, prices: itemVariations }));
  }, [itemVariations]);

  useEffect(() => {
    setNewDrink((prev) => ({ ...prev, prices: itemDrinkVariations }));
  }, [itemDrinkVariations]);

  useEffect(() => {
    setNewCartDrink((prev) => ({
      ...prev,
      drink: { ...prev.drink, prices: cartDrinkVariations },
    }));
  }, [cartDrinkVariations]);

  useEffect(() => {
    setAddNewMenuDish((prev) => ({ ...prev, prices: menuDishVariations }));
  }, [menuDishVariations]);

  useEffect(() => {
    console.log("Dropdown Tabs:", selectedDropdownTab);
    const targetIndex = selectedDropdownTab.findIndex(
      (element) =>
        element.main === multiLayerDropdownTabs[0].main &&
        element.secondary === multiLayerDropdownTabs[0].secondary[0]
    );
    if (targetIndex !== -1) {
      if (dishTypeSelected === "comida") {
        setAddDishFromList(targetIndex);
      }
    }

    const targetIndex2 = selectedDropdownTab.findIndex(
      (element) =>
        element.main === multiLayerDropdownTabs[0].main &&
        element.secondary === multiLayerDropdownTabs[0].secondary[1]
    );
    if (targetIndex2 !== -1) {
      if (dishTypeSelected === "comida") {
        addNewDish.categoryId = categories[targetIndex2].categoryId;
        setAddDish(targetIndex2);
      }
    }

    const targetIndex5 = selectedDropdownTab.findIndex(
      (element) => element.main === multiLayerDropdownTabs[1].main
    );
    if (targetIndex5 !== -1) {
      if (dishTypeSelected === "comida")
        setVariableDishDescription((prevState) => ({
          ...prevState,
          isActive: targetIndex5,
        }));
    }
  }, [selectedDropdownTab]);

  useEffect(() => {
    console.log("Drink Dropdown Tabs:", selectedDrinkDropdownTab);
    const targetIndex = selectedDrinkDropdownTab.findIndex(
      (element) =>
        element.main === multiLayerDropdownTabs[0].main &&
        element.secondary === multiLayerDropdownTabs[0].secondary[0]
    );
    if (targetIndex !== -1) {
      if (dishTypeSelected === "bebida") {
        setIsAddFromListDrinkCart(targetIndex);
      }
    }

    const targetIndex2 = selectedDrinkDropdownTab.findIndex(
      (element) =>
        element.main === multiLayerDropdownTabs[0].main &&
        element.secondary === multiLayerDropdownTabs[0].secondary[1]
    );
    if (targetIndex2 !== -1) {
      if (dishTypeSelected === "bebida") {
        newCartDrink.categoryId = drinkCategories[targetIndex2].categoryId;
        setIsAddDrinkCartActive(targetIndex2);
      }
    }

    const targetIndex5 = selectedDrinkDropdownTab.findIndex(
      (element) => element.main === multiLayerDropdownTabs[1].main
    );
    if (targetIndex5 !== -1) {
      if (dishTypeSelected === "bebida")
        setVariableDishDescription((prevState) => ({
          ...prevState,
          isActive: targetIndex5,
        }));
    }
  }, [selectedDrinkDropdownTab]);

  useEffect(() => {
    const randomId = v4();

    const targetIndex = dropdownSelectedMenus.findIndex(
      (element) =>
        element.main === multiLayerDropdownTabs[0].main &&
        element.secondary === multiLayerDropdownTabs[0].secondary[0]
    );
    setAddDishFromListMenus(targetIndex);

    const targetIndex2 = dropdownSelectedMenus.findIndex(
      (element) =>
        element.main === multiLayerDropdownTabs[0].main &&
        element.secondary === multiLayerDropdownTabs[0].secondary[1]
    );
    if (targetIndex2 !== -1) {
      addNewMenuDish.id = randomId;
      setIsAddMenuDishActive(targetIndex2);
    }

    const targetIndex5 = dropdownSelectedMenus.findIndex(
      (element) => element.main === multiLayerDropdownTabs[1].main
    );
    if (targetIndex5 !== -1)
      setVariableDishDescription((prevState) => ({
        ...prevState,
        isActive: targetIndex5,
      }));
  }, [dropdownSelectedMenus]);

  useEffect(() => {
    if (newMenu.dishes && newMenu.dishes.length > 0) {
      setIsDropdownActiveMenus(Array(newMenu.dishes.length + 1).fill(false));
      setDropdownSelectedMenus(
        Array(newMenu.dishes.length + 1).fill(defaultDropdownTab)
      );
    }
  }, [newMenu.dishes]);

  const fetchHouseWines = () => {
    getAllHouseWinesEndpoint(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log("todos os house wines:", response.data);
        setHouseWineState({
          ...houseWineState,
          wines: response.data.data,
          addHouseWine: false,
          wineId: null,
          wineName: null,
          wineImage: null,
          wineType: null,
          winePriceSizes: [
            {
              winePriceSizeId: null,
              wineSize: "75 cl",
              winePrice: 0,
            },
          ],
          winePriceSizeId: null,
          dropdownStatus: [false],
          dropdown: null,
          editHouseWine: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchDirectoryWines = () => {
    getAllDirectoryWinesEndpoint(authorizationToken)
      .then((response) => {
        console.log("todos os wines do diretório:", response.data);
        setWineDirectoryState({
          ...wineDirectoryState,
          wines: response.data.data,
          addToWineDirectory: false,
          wineId: null,
          wineName: null,
          wineImage: null,
          wineType: null,
          wineOrigin: null,
          wineRegion: null,
          wineFort: null,
          wineFortStyle: null,
          winePriceSizes: [
            {
              winePriceSizeId: null,
              wineSize: "75 cl",
              winePrice: 0,
            },
          ],
          dropdown: null,
          dropdownStatus: [false],
          editDirectoryWine: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const uploadImage = (image, type) => {
    uploadImageEndpoint(image)
      .then((response) => {
        console.log(response.data);
        if (type === "item")
          setAddNewItem((prev) => ({
            ...prev,
            dishImage: response.data.data,
          }));
        else if (type === "dish")
          setAddNewDish((prev) => ({
            ...prev,
            dish: { ...prev.dish, dishImage: response.data.data },
          }));
        else if (type === "menu")
          setAddNewMenuDish((prev) => ({
            ...prev,
            dishImage: response.data.data,
          }));
        else if (type === "drinkItem")
          setNewDrink((prev) => ({
            ...prev,
            drinkImage: response.data.data,
          }));
        else if (type === "drinkCart")
          setNewCartDrink((prev) => ({
            ...prev,
            drink: {
              ...prev.drink,
              drinkImage: response.data.data,
            },
          }));
        else if (type === "houseWine")
          setHouseWineState((prev) => ({
            ...prev,
            wineImage: response.data.data,
          }));
        else if (type === "directoryWine")
          setWineDirectoryState((prev) => ({
            ...prev,
            wineImage: response.data.data,
          }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCaracteristicClick = (caracteristic, type) => {
    if (type === 1) {
      setAddNewItem((prev) => {
        const isAlreadyPresent =
          prev.dishCaracteristics.includes(caracteristic);

        if (isAlreadyPresent) {
          const updatedDishCaracteristics = prev.dishCaracteristics.filter(
            (item) => item !== caracteristic
          );

          return {
            ...prev,
            dishCaracteristics: updatedDishCaracteristics,
          };
        } else {
          return {
            ...prev,
            dishCaracteristics: [...prev.dishCaracteristics, caracteristic],
          };
        }
      });
    } else if (type === 2) {
      setAddNewDish((prev) => {
        const isAlreadyPresent =
          prev.dish.dishCaracteristics.includes(caracteristic);

        if (isAlreadyPresent) {
          const updatedDishCaracteristics = prev.dish.dishCaracteristics.filter(
            (item) => item !== caracteristic
          );

          return {
            ...prev,
            dish: {
              ...prev.dish,
              dishCaracteristics: updatedDishCaracteristics,
            },
          };
        } else {
          return {
            ...prev,
            dish: {
              ...prev.dish,
              dishCaracteristics: [
                ...prev.dish.dishCaracteristics,
                caracteristic,
              ],
            },
          };
        }
      });
    } else if (type === 3) {
      setAddNewMenuDish((prev) => {
        const isAlreadyPresent =
          prev.dishCaracteristics.includes(caracteristic);

        if (isAlreadyPresent) {
          const updatedDishCaracteristics = prev.dishCaracteristics.filter(
            (item) => item !== caracteristic
          );

          return {
            ...prev,
            dishCaracteristics: updatedDishCaracteristics,
          };
        } else {
          return {
            ...prev,
            dishCaracteristics: [...prev.dishCaracteristics, caracteristic],
          };
        }
      });
    } else if (type === 4) {
      setNewDrink((prev) => {
        const isAlreadyPresent =
          prev.drinkCaracteristics.includes(caracteristic);

        if (isAlreadyPresent) {
          const updatedDrinkCaracteristics = prev.drinkCaracteristics.filter(
            (item) => item !== caracteristic
          );

          return {
            ...prev,
            drinkCaracteristics: updatedDrinkCaracteristics,
          };
        } else {
          return {
            ...prev,
            drinkCaracteristics: [...prev.drinkCaracteristics, caracteristic],
          };
        }
      });
    } else if (type === 5) {
      setNewCartDrink((prev) => {
        const isAlreadyPresent =
          prev.drink.drinkCaracteristics.includes(caracteristic);

        if (isAlreadyPresent) {
          const updatedDrinkCaracteristics =
            prev.drink.drinkCaracteristics.filter(
              (item) => item !== caracteristic
            );

          return {
            ...prev,
            drink: {
              ...prev.drink,
              drinkCaracteristics: updatedDrinkCaracteristics,
            },
          };
        } else {
          return {
            ...prev,
            drink: {
              ...prev.drink,
              drinkCaracteristics: [
                ...prev.drink.drinkCaracteristics,
                caracteristic,
              ],
            },
          };
        }
      });
    }
  };

  const resetDish = (type) => {
    /* type: "dish"/"item"/"menuDish" */
    if (type === "dish") {
      setAddNewDish(new Dish());
      setDishVariations([{ price: 0, description: "" }]);
    } else if (type === "item") {
      setAddNewItem(new Item());
      setItemVariations([{ price: 0, description: "" }]);
    } else if (type === "menuDish") {
      setAddNewMenuDish(new DishWithoutCategory());
      setMenuDishVariations([{ price: 0, description: "" }]);
    } else if (type === "drinkItem") {
      setNewDrink(new Drink());
      setItemDrinkVariations([{ price: 0, description: "" }]);
    } else if (type === "drinkCart") {
      setNewCartDrink(new DrinkCart());
      setCartDrinkVariations([{ price: 0, description: "" }]);
    } else if (type === "dishOfTheDay") {
      setVariableDishDescription({ isActive: -1, description: "" });
    }
  };

  const isAllFilled = (type) => {
    /* type: "dish"/"item"/"menuDish" */
    const dishType =
      type === "dish"
        ? addNewDish
        : type === "item"
        ? addNewItem
        : type === "menuDish"
        ? addNewMenuDish
        : null;

    if (dishType.dishName === "") {
      alert("Insira um nome para o prato.");
      return false;
    }
    if (dishType.prices[0].price === 0) {
      alert("Insira um preço para o prato.");
      return false;
    }
    if (dishType.description === "") {
      alert("Insira uma descrição para o prato.");
      return false;
    }
    if (dishType.dishImage === "") {
      alert("Insira uma imagem para o prato.");
      return false;
    }
    return true;
  };

  const saveNewItem = (type) => {
    if (type === "item") {
      if (!isAllFilled("item")) return; //TODO: adicionar isto nos restantes abaixo

      addDishItemListEndpoint(
        addNewItem,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setItemList((prev) => [...prev, response.data.data]);
          resetDish("item");
          setIsAddItemActive(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "drinkItem") {
      addDrinkItemListEndpoint(
        newDrink,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setDrinks((prev) => [...prev, response.data.data]);
          resetDish("drinkItem");
          setIsAddDrinkActive(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "dish") {
      addFixedDishEndpoint(
        addNewDish,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);

          setDishes((prev) => [...prev, response.data.data]);
          setItemList((prev) => [
            ...prev,
            { ...addNewDish, type: dishTypeSelected },
          ]);
          setAddDish(-1);
          resetDish("dish");
        })
        .catch((error) => {
          console.error(error);
        }); /* if it is a weekly fixed dish */

      /*if (!isAllFilled("dish")) return;

            addNewDish.createdAt = new Date();
            addNewDish.isAvailable = true;
            addNewDish.rating = -1;
    
            if (dishActiveDays.isActive !== -1) {*/
      /*setDishes((prev) => [...prev, {...addNewDish, category: categories[addDish], type: dishTypeSelected, restaurantMenuType: dishActiveDays.days}]);
                setDishActiveDays({ isActive: -1, days: [false, true, false, false, false, false, false] });
            }
            else
                setDishes((prev) => [...prev, {...addNewDish, category: categories[addDish], type: dishTypeSelected, restaurantMenuType: null}]);
            setItemList((prev) => [...prev, {...addNewDish, type: dishTypeSelected}]);
            setAddDish(-1);
            resetDish("dish");*/
    } else if (type === "drinkCart") {
      addCartDrinkEndpoint(
        newCartDrink,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setDrinksCart((prev) => [...prev, response.data.data]);
          resetDish("drinkCart");
          setIsAddDrinkCartActive(-1);
        })
        .catch((error) => {
          console.error(error);
        });
      console.log("guaradr a babeida");
    } else if (type === "menu") {
      if (!isAllFilled("menuDish")) return;

      addNewMenuDish.createdAt = new Date();
      addNewMenuDish.type = menuDishType;
      addNewMenuDish.isAvailable = true;

      if (dishActiveDays.isActive !== -1) {
        addNewMenuDish.restaurantMenuType = dishActiveDays.days;
        setNewMenu((prevMenu) => ({
          ...prevMenu,
          dishes: [
            ...prevMenu.dishes.slice(0, isAddMenuDishActive),
            addNewMenuDish,
            ...prevMenu.dishes.slice(isAddMenuDishActive + 1),
          ],
        }));
        setDishActiveDays({
          isActive: -1,
          days: [false, true, false, false, false, false, false],
        });
      } else {
        addNewMenuDish.restaurantMenuType = null;
        setNewMenu((prevMenu) => ({
          ...prevMenu,
          dishes: [
            ...prevMenu.dishes.slice(0, isAddMenuDishActive),
            addNewMenuDish,
            ...prevMenu.dishes.slice(isAddMenuDishActive + 1),
          ],
        }));
      }

      const { restaurantMenuType, ...menuDishWithoutTest } = addNewMenuDish;
      setItemList((prev) => [...prev, menuDishWithoutTest]);

      resetDish("menuDish");
      setMenuDishType("comida");
      setIsAddMenuDishActive(-1);
    }
  };

  const updateItem = (type) => {
    if (type === "comida") {
      updateDishItemListEndpoint(addNewItem, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setItemList((prevDishes) => {
            const newDishes = prevDishes.map((existingDish) => {
              if (existingDish.dishId === response.data.data.dishId)
                return addNewItem;
              return existingDish;
            });
            return newDishes;
          });

          //addNewItem.prices = itemVariations;
          setIsEditItemSlideInActive(-1);
          resetDish("item");
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "bebida") {
      updateDrinkItemListEndpoint(newDrink, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setDrinks((prevDrinks) => {
            const newDrinks = prevDrinks.map((existingDrink) => {
              if (existingDrink.drinkId === response.data.data.drinkId)
                return newDrink;
              return existingDrink;
            });
            return newDrinks;
          });

          //addNewItem.prices = itemVariations;
          setIsEditDrinkActive(-1);
          resetDish("drinkItem");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const updateDish = (type) => {
    if (type === "comida") {
      updateDishCartEndpoint(
        addNewDish.dishCartId,
        addNewDish,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setDishes((prevDishes) => {
            const updatedDishes = prevDishes.map((existingDish) => {
              if (existingDish.dishCartId === response.data.data.dishCartId) {
                return {
                  ...existingDish,
                  ...addNewDish,
                };
              }
              return existingDish;
            });
            return updatedDishes;
          });

          resetDish("dish");
          setIsEditDishSlideInActive(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "bebida") {
      updateCartDrinkEndpoint(
        newCartDrink.drinkCartId,
        newCartDrink,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setDrinksCart((prev) => {
            const updatedDrinks = prev.map((existingDrink) => {
              if (
                existingDrink.drinkCartId === response.data.data.drinkCartId
              ) {
                return {
                  ...existingDrink,
                  ...newCartDrink,
                };
              }
              return existingDrink;
            });
            return updatedDrinks;
          });

          resetDish("drinkCart");
          setIsEditDrinkCartActive(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleAddDishFromListMenu = (idx) => {
    if (dishActiveDays.isActive !== -1) {
      setNewMenu((prevMenu) => ({
        ...prevMenu,
        dishes: [
          ...prevMenu.dishes.slice(0, addDishFromListMenus),
          {
            ...itemList[idx],
            restaurantMenuType: dishActiveDays.days,
          },
          ...prevMenu.dishes.slice(addDishFromListMenus + 1),
        ],
      }));
      setDishActiveDays({
        isActive: -1,
        days: [false, true, false, false, false, false, false],
      });
    } else
      setNewMenu((prevMenu) => ({
        ...prevMenu,
        dishes: [
          ...prevMenu.dishes.slice(0, addDishFromListMenus),
          { ...itemList[idx], restaurantMenuType: null },
          ...prevMenu.dishes.slice(addDishFromListMenus + 1),
        ],
      }));
    setAddDishFromListMenus(-1);
    handleDropdownSelect(addDishFromListMenus, defaultDropdownTab, true);
    setIsDropdownActiveMenus([false, ...isDropdownActiveMenus.slice(1)]);
  };

  function addMiniDescription(array, idx, field, value) {
    const updatedArr = [...array];
    const updatedElement = { ...updatedArr[idx] };
    updatedElement[field] = value;
    updatedArr[idx] = updatedElement;

    return updatedArr;
  }

  const handleAddDish = (type, dish) => {
    if (type === "dish") {
      adddDishFromItemListEndpoint(
        dish.dishId,
        categories[addDishFromList].categoryId,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setDishes([...dishes, response.data.data]);
          setAddDishFromList(-1);
          handleDropdownSelect(addDishFromList, defaultDropdownTab);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "drink") {
      addCartDrinksFromItemListEndpoint(
        dish.drinkId,
        drinkCategories[isAddFromListDrinkCart].categoryId,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setDrinksCart([...drinksCart, response.data.data]);
          setIsAddFromListDrinkCart(-1);
          handleDropdownSelect(isAddFromListDrinkCart, defaultDropdownTab);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleDropdownClick = (index) => {
    setIsDropdownActive((prevState) => {
      const updatedArray = [...prevState];
      updatedArray[index] = !updatedArray[index];
      return updatedArray;
    });
  };

  const handleKeyDown = (event, category, type) => {
    if (event.keyCode === 13) {
      // Enter key was pressed
      if (type === "comida") {
        const newVariableDish = new VariableDishes(
          variableDishDescription.description,
          category.categoryName,
          restaurant.restaurantId
        );

        createDishOfTheDayEndpoint(newVariableDish, authorizationToken)
          .then((response) => {
            console.log(response.data);
            setVariableDishes((prev) => [...prev, response.data.data]);
            handleDropdownSelect(
              variableDishDescription.isActive,
              defaultDropdownTab,
              false
            );
            setVariableDishDescription({
              isActive: -1,
              description: "",
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (type === "bebida") {
        const drinkOfTheDay = new VariableDishes(
          variableDishDescription.description,
          category.categoryName,
          restaurant.restaurantId
        );

        createDrinkOfTheDayEndpoint(drinkOfTheDay, authorizationToken)
          .then((response) => {
            console.log(response.data);
            setDrinksOfTheDay((prev) => [...prev, response.data.data]);
            handleDropdownSelect(
              variableDishDescription.isActive,
              defaultDropdownTab,
              false
            );
            setVariableDishDescription({
              isActive: -1,
              description: "",
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const handleSaveMenu = () => {
    setMenus((prev) => [...prev, newMenu]);
    clearNewMenu();
    setIsOptionsCreateActive(false);
    setIsAddMenuActive(false);
  };

  const handleEditMenu = () => {
    setMenus((prevDishes) => {
      const updatedMenus = prevDishes.map((menu, index) => {
        if (index === isEditMenuActive) {
          return {
            ...menu,
            ...newMenu,
          };
        }
        return menu;
      });
      return updatedMenus;
    });

    clearNewMenu();
    setIsEditMenuActive(-1);
  };

  const clearNewMenu = () => {
    setCalendarSelectedStart(formattedBeforeDate);
    setCalendarSelectedEnd(formattedCurrentDate);
    setNewMenu(new MenuDish());
  };

  const deleteItem = (type) => {
    if (type === "comida") {
      deleteDishItemListEndpoint(
        itemList[isEditItemSlideInActive].dishId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setItemList((prevDishes) =>
            prevDishes.filter((_, index) => index !== isEditItemSlideInActive)
          );

          /*const dishId = itemList[isEditItemSlideInActive]?.dishId; // Get the dish ID based on isEditItemSlideInActive index
        
                if (dishId) {
                // Check if the dish with the given ID exists in the dishes array
                const dishIndex = dishes.findIndex(dish => dish.id === dishId);
            
                if (dishIndex !== -1) {
                    setDishes(prevDishes => {
                    const updatedDishes = [...prevDishes];
                    updatedDishes.splice(dishIndex, 1);
                    return updatedDishes;
                    });
                }
                }*/

          setIsEditItemSlideInActive(-1);
          setAddNewItem(new Item());
          setItemVariations([{ price: 0, description: "" }]);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "bebida") {
      deleteDrinkItemListEndpoint(
        drinks[isEditDrinkActive].drinkId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setDrinks((prev) =>
            prev.filter((_, index) => index !== isEditDrinkActive)
          );

          /*const dishId = itemList[isEditItemSlideInActive]?.dishId; // Get the dish ID based on isEditItemSlideInActive index
        
                if (dishId) {
                // Check if the dish with the given ID exists in the dishes array
                const dishIndex = dishes.findIndex(dish => dish.id === dishId);
            
                if (dishIndex !== -1) {
                    setDishes(prevDishes => {
                    const updatedDishes = [...prevDishes];
                    updatedDishes.splice(dishIndex, 1);
                    return updatedDishes;
                    });
                }
                }*/

          setIsEditDrinkActive(-1);
          setNewDrink(new Drink());
          setItemDrinkVariations([{ price: 0, description: "" }]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleDropdownSelect = (index, obj, menus = false) => {
    if (menus) {
      setDropdownSelectedMenus((prevState) => {
        const updatedArray = [...prevState];
        updatedArray[index] = obj;
        return updatedArray;
      });
    } else {
      if (dishTypeSelected === "comida") {
        setSelectedDropdownTab((prevState) => {
          const updatedArray = [...prevState];
          updatedArray[index] = obj;
          return updatedArray;
        });
      } else if (dishTypeSelected === "bebida") {
        setSelectedDrinkDropdownTab((prevState) => {
          const updatedArray = [...prevState];
          updatedArray[index] = obj;
          return updatedArray;
        });
      }
    }
  };

  const handleMenusDropdownSelect = (index, obj) => {
    setDropdownSelectedMenus((prevMenus) => {
      const updatedMenus = [...prevMenus];
      updatedMenus[index] = obj;
      return updatedMenus;
    });
  };

  const handleMenusActivate = (index, obj) => {
    setIsDropdownActiveMenus((prev) => {
      const updatedMenus = [...prev];
      updatedMenus[index] = obj;
      return updatedMenus;
    });
  };

  // Handle form submission
  const handleWineSubmit = (event) => {
    event.preventDefault();
  };

  // Handle form submission
  const handleWineDetailsSubmit = (event) => {
    event.preventDefault();
  };

  // Handle form submission
  const handleSetDescription = async (selectedItem, description) => {
    setWines((prevWines) => {
      return prevWines.map((map) => {
        if (map.id === selectedItem.id && map.year === selectedItem.year) {
          const updatedPriceToVolume = [...map.priceToVolume]; // Create a copy of the priceToVolume array
          updatedPriceToVolume[0] = {
            ...updatedPriceToVolume[0],
            description: description,
          }; // Update the first map in the priceToVolume array
          console.log(updatedPriceToVolume);
          return { ...map, priceToVolume: updatedPriceToVolume }; // Return the map with the updated priceToVolume array
        }
        return map; // Return other maps as they are
      });
    });
  };

  const isHouseWineStateComplete = () => {
    if (
      !houseWineState.wineName ||
      !houseWineState.wineType ||
      houseWineState.winePriceSizes.length === 0 ||
      houseWineState.winePriceSizes.some((item) => item.winePrice === 0)
    )
      return false;

    return true;
  };

  const isDirectoryWineStateComplete = () => {
    if (!wineDirectoryState.wineName)
      // || !(wineDirectoryState.winePriceSizes.length === 0)) || wineDirectoryState.winePriceSizes.some(item => item.winePrice === 0))
      return false;
    if (
      wineDirectoryState.wineType === "Fortificado" &&
      (!wineDirectoryState.wineFort || !wineDirectoryState.wineFortStyle)
    )
      return false;
    if (
      wineDirectoryState.wineType &&
      wineDirectoryState.wineType !== "Fortificado" &&
      (!wineDirectoryState.wineOrigin || !wineDirectoryState.wineRegion)
    )
      return false;

    return true;
  };

  const addHouseWine = async () => {
    const additionalWinePriceSizes = [];

    let updatedWine;
    let updatedWinePriceSize;

    const uniqueWinePriceSizes = houseWineState.winePriceSizes.reduce(
      (acc, current) => {
        const existingItem = acc.find(
          (item) => item.wineSize === current.wineSize
        );
        if (!existingItem) {
          acc.push(new WinePriceSizes(current.wineSize, current.winePrice));
        }
        return acc;
      },
      []
    );

    const existingWine = houseWineState.wines.find(
      (wine) =>
        wine.wineName === houseWineState.wineName &&
        wine.wineType === houseWineState.wineType
    );

    if (existingWine) {
      updatedWinePriceSize = [
        ...existingWine.winePriceSizes,
        ...houseWineState.winePriceSizes,
      ];

      updatedWine = {
        houseWineId: existingWine.houseWineId,
        wineName: houseWineState.wineName,
        wineType: houseWineState.wineType,
        wineImage: houseWineState.wineImage,
        winePriceSizes: updatedWinePriceSize,
      };

      // Check if there are more items in houseWineState.winePriceSizes
      if (
        houseWineState.winePriceSizes.length >
        existingWine.winePriceSizes.length
      ) {
        // Iterate over each wine price size in houseWineState
        houseWineState.winePriceSizes.forEach((priceSize1) => {
          // Check if there's a corresponding wine price size in wineToUpdate
          const matchingPriceSize = existingWine.winePriceSizes.find(
            (priceSize2) =>
              priceSize2.winePriceSizeId === priceSize1.winePriceSizeId
          );
          // If no corresponding wine price size is found, add it to additionalWinePriceSizes
          if (!matchingPriceSize) {
            additionalWinePriceSizes.push(priceSize1);
          }
        });

        const uniqueWinePriceSizes = additionalWinePriceSizes.reduce(
          (acc, current) => {
            const existingItem = acc.find(
              (item) => item.wineSize === current.wineSize
            );
            if (!existingItem) {
              acc.push({
                winePriceSizeId: null,
                wineSize: current.wineSize,
                winePrice: current.winePrice,
              });
            }
            return acc;
          },
          []
        );

        updatedWinePriceSize = [
          ...existingWine.winePriceSizes,
          ...uniqueWinePriceSizes,
        ];

        updatedWine = {
          houseWineId: existingWine.houseWineId,
          wineName: houseWineState.wineName,
          wineType: houseWineState.wineType,
          wineImage: houseWineState.wineImage,
          winePriceSizes: updatedWinePriceSize,
        };
      }

      console.log(updatedWine);

      updateHouseWineEndpoint(updatedWine, authorizationToken)
        .then((response) => {
          console.log(response.data);
          fetchHouseWines();
        })
        .catch((error) => {
          alert("Formulário incorreto.");
          console.error(error);
        });
    } else {
      const newWine = new HouseWine(
        houseWineState.wineName,
        houseWineState.wineType,
        houseWineState.wineImage,
        uniqueWinePriceSizes
      );

      addHouseWineEndpoint(newWine, restaurant.restaurantId, authorizationToken)
        .then((response) => {
          console.log(response.data);
          fetchHouseWines();
        })
        .catch((error) => {
          alert("Formulário incorreto.");
          console.error(error);
        });
    }
  };

  const addDirectoryWine = async () => {
    console.log("wineDirectory -> ", wineDirectoryState);
  };

  const handleWineDetails = (id) => {
    setSelectedRow(selectedRow === id ? null : id);
  };

  const handleYearDropdownItemClick = async (value, year) => {
    if (wines.length > 0) {
      setWines((prevWines) => {
        return prevWines.map((map) => {
          if (map.name === value.name) {
            return { ...map, year: year };
          }
          return map;
        });
      });
    }
    setAddWinePopupIsClicked(false);
  };

  const handleListYearDropdownItemClick = (selValue, year) => {
    setSelectedYear(selValue);
  };

  const handleBottleDropdownItemClick = async (value, newVolume) => {
    const targetIndex = wineVolume.findIndex((map) => map.id === value.id);
    if (targetIndex !== -1) {
      // If the targetIndex is found, update the existing element
      const updatedWineVolume = [...wineVolume];
      updatedWineVolume[targetIndex] = {
        ...updatedWineVolume[targetIndex],
        volume: newVolume,
      };
      setWineVolume(updatedWineVolume);
    } else {
      // If the targetIndex is not found, add a new element
      const updatedWineVolume = [
        ...wineVolume,
        { id: value.id, volume: newVolume, glass: null, price: null },
      ];
      setWineVolume(updatedWineVolume);
    }
    setWines((prevWines) => {
      const index = prevWines.findIndex(
        (map) => map.name === value.name && map.year === value.year
      );
      if (index === -1) {
        const newMap = {
          ...value,
          priceToVolume: [
            ...value.priceToVolume,
            {
              description: `Garrafa de ${newVolume}`,
              volume: newVolume,
              price: null,
            },
          ],
        };
        return [...prevWines, newMap];
      }
      return prevWines.map((map, idx) => {
        if (idx === index) {
          let updatedPriceToVolume = [...map.priceToVolume];
          const volumeExists = updatedPriceToVolume.findIndex(
            (volMap) => volMap.volume === newVolume
          );
          if (volumeExists === -1) {
            updatedPriceToVolume = updatedPriceToVolume.map((volMap) => {
              if (volMap.volume === "") {
                return { ...volMap, volume: newVolume };
              } else {
                return volMap;
              }
            });
            updatedPriceToVolume.push({
              description: `Garrafa de ${newVolume}`,
              volume: newVolume,
              price: null,
            });
          }
          return { ...map, priceToVolume: updatedPriceToVolume };
        }
        return map;
      });
    });
  };

  const handleGlassPriceChange = async (value, event) => {
    const targetIndex = wineVolume.findIndex((map) => map.id === value.id);
    if (targetIndex !== -1) {
      // If the targetIndex is found, update the existing element
      const updatedWineVolume = [...wineVolume];
      updatedWineVolume[targetIndex] = {
        ...updatedWineVolume[targetIndex],
        glass: Number(event.target.value),
      };
      setWineVolume(updatedWineVolume);
    } else {
      // If the targetIndex is not found, add a new element
      const updatedWineVolume = [
        ...wineVolume,
        {
          id: value.id,
          volume: "",
          glass: Number(event.target.value),
          price: null,
        },
      ];
      setWineVolume(updatedWineVolume);
    }
    setWines((prevWines) => {
      let mapExists = false;
      const updatedWines = prevWines.map((map) => {
        if (map.name === value.name && map.year === value.year) {
          mapExists = true;
          const updatedPriceToVolume = [...map.priceToVolume];
          updatedPriceToVolume[0] = {
            ...updatedPriceToVolume[0],
            price: Number(event.target.value),
          };
          return { ...map, priceToVolume: updatedPriceToVolume };
        }
        return map;
      });
      if (!mapExists) {
        updatedWines.push({
          country: value.country,
          name: value.name,
          year: value.year,
          priceToVolume: [
            {
              description: "Copo de vinho",
              price: Number(event.target.value),
              volume: "0,20l",
            },
          ],
          id: value.id,
          region: value.region,
          type: value.type,
        });
      }
      return updatedWines;
    });

    setWine_Directory((prevWines) => {
      let mapExists = false;
      const updatedWines = prevWines.map((map) => {
        if (map.name === value.name && map.year === value.year) {
          mapExists = true;
          const updatedPriceToVolume = [...map.priceToVolume];
          updatedPriceToVolume[0] = {
            ...updatedPriceToVolume[0],
            price: Number(event.target.value),
          };
          return { ...map, priceToVolume: updatedPriceToVolume };
        }
        return map;
      });
      if (!mapExists) {
        updatedWines.push({
          country: value.country,
          name: value.name,
          year: value.year,
          priceToVolume: [
            {
              description: "Copo de vinho",
              price: Number(event.target.value),
              volume: "0,20l",
            },
          ],
          id: value.id,
          region: value.region,
          type: value.type,
        });
      }
      // Update the state with the new wines array
      return updatedWines;
    });
  };

  function findIndexByVolume(arr, key) {
    return arr.findIndex((map) => key === map.volume);
  }

  function findIndexByID(arr, key) {
    return arr.findIndex((map) => key === map.id);
  }

  const handleBottlePriceChange = async (selectedItem, event) => {
    setWines((prevWines) => {
      return prevWines.map((map) => {
        if (map.name === selectedItem.name && map.year === selectedItem.year) {
          const updatedPriceToVolume = [...map.priceToVolume]; // Create a copy of the priceToVolume array
          const targetIndex = wineVolume.findIndex(
            (map) => map.id === selectedItem.id
          );
          if (targetIndex !== -1) {
            // If the targetIndex is found, update the existing element
            const updatedWineVolume = [...wineVolume];
            updatedWineVolume[targetIndex] = {
              ...updatedWineVolume[targetIndex],
              price: Number(event.target.value),
            };
            setWineVolume(updatedWineVolume);
          } else {
            // If the targetIndex is not found, add a new element
            const updatedWineVolume = [
              ...wineVolume,
              {
                id: selectedItem.id,
                volume: "",
                glass: null,
                price: Number(event.target.value),
              },
            ];
            setWineVolume(updatedWineVolume);
          }
          const indexID = findIndexByID(wineVolume, selectedItem.id);
          const indexPriceToVolume = findIndexByVolume(
            map.priceToVolume,
            wineVolume[indexID].volume
          );
          updatedPriceToVolume[indexPriceToVolume] = {
            ...updatedPriceToVolume[indexPriceToVolume],
            price: Number(event.target.value),
          }; // Update the first map in the priceToVolume array
          return { ...map, priceToVolume: updatedPriceToVolume }; // Return the map with the updated priceToVolume array
        }
        return map; // Return other maps as they are
      });
    });
  };

  const handleSuggestionsButton = (key) => {
    setSuggestionIsClicked(key === suggestionIsClicked ? null : key);
  };

  const handleYearDetails = (key) => {
    setIsMainYearDropdownActive(!isMainYearDropdownActive);
    setSelectedYear(key);
  };

  const handleAddWine = () => {
    setAddWineIsClicked(!addWineIsClicked);
  };

  const setPageTab = (tab) => {
    if (tab === menuSelectedTab) return "currentHomeTab";
    else return "";
  };

  const handleCategorySave = () => {
    if (dishTypeSelected === "comida") {
      if (categoriesToBeDeleted.length > 0) {
        deleteDishCategoryEndpoint(categoriesToBeDeleted, authorizationToken)
          .then((response) => {
            console.log(response.data);
            setCategories((prev) => {
              const responseCategoryIds = response.data.data.map(
                (item) => item.categoryId
              );
              return prev.filter(
                (category) => !responseCategoryIds.includes(category.categoryId)
              );
            });
            setCategoriesToBeDeleted([]);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (categoriesToBeSaved.length > 0) {
        addDishCategoryEndpoint(
          categoriesToBeSaved,
          restaurant.restaurantId,
          authorizationToken
        )
          .then((response) => {
            console.log(response.data);
            setCategories((prev) => prev.concat(response.data.data));
            setCategoriesToBeSaved([]);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      if (categoriesToBeDeleted.length > 0) {
        deleteDrinkCategoryEndpoint(categoriesToBeDeleted, authorizationToken)
          .then((response) => {
            console.log(response.data);
            setDrinkCategories((prev) => {
              const responseCategoryIds = response.data.data.map(
                (item) => item.categoryId
              );
              return prev.filter(
                (category) => !responseCategoryIds.includes(category.categoryId)
              );
            });
            setCategoriesToBeDeleted([]);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (categoriesToBeSaved.length > 0) {
        addDrinkCategoryEndpoint(
          categoriesToBeSaved,
          restaurant.restaurantId,
          authorizationToken
        )
          .then((response) => {
            console.log(response.data);
            setDrinkCategories((prev) => prev.concat(response.data.data));
            setCategoriesToBeSaved([]);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    setIsEditCategoriesActive(false);
  };

  const getWineTypes = (houseWines, directoryWines) => {
    let houseWineTypes = [];
    let directoryWineTypes = [];

    if (houseWines) houseWineTypes = houseWines.map((wine) => wine.wineType);

    if (directoryWines)
      directoryWineTypes = directoryWines.map((wine) => wine.wineType);

    const allWineTypes = [...houseWineTypes, ...directoryWineTypes];
    const uniqueWineTypes = Array.from(new Set(allWineTypes));
    return uniqueWineTypes;
  };

  const handleDirectoryDropdown = () => {
    setDropdownOptions("Diretório de vinhos");
    setWineDirectoryState({
      ...wineDirectoryState,
      addWineDirectory: !wineDirectoryState.addWineDirectory,
    });
    setHouseWineState({ ...houseWineState, addHouseWine: false });
    setAddWineIsClicked(false);
  };

  const handleHouseWine = () => {
    setDropdownOptions("Vinho da casa");
    setHouseWineState({ ...houseWineState, addHouseWine: true });
    setAddWineIsClicked(false);
  };

  const handleDropdown = (isHouseWine, value) => {
    if (isHouseWine)
      setHouseWineState({
        ...houseWineState,
        dropdown: houseWineState.dropdown === value ? null : value,
      });
    else
      setWineDirectoryState({
        ...wineDirectoryState,
        dropdown: wineDirectoryState.dropdown === value ? null : value,
      });
  };

  const handleSelectedOption = (isHouseWine, value, toSelect, option) => {
    let updatedState;

    if (isHouseWine) {
      updatedState = {
        ...houseWineState,
        [toSelect]: option,
        dropdown: houseWineState.dropdown === value ? null : value,
      };
      setHouseWineState(updatedState);
      return;
    }

    updatedState = {
      ...wineDirectoryState,
      [toSelect]: option,
      dropdown: wineDirectoryState.dropdown === value ? null : value,
    };

    // Reset values in subsequent dropdowns
    switch (toSelect) {
      case "wineType":
        updatedState = {
          ...updatedState,
          wineOrigin: null,
          wineRegion: null,
          wineFort: null,
          wineFortStyle: null,
        };
        break;
      case "wineOrigin":
        updatedState = {
          ...updatedState,
          wineRegion: null,
        };
        break;
      case "wineFort":
        updatedState = {
          ...updatedState,
          wineFortStyle: null,
        };
        break;
      default:
        break;
    }

    setWineDirectoryState(updatedState);
  };

  const handleWineDirectoryBack = () => {
    setWineDirectoryState({
      ...wineDirectoryState,
      addWineDirectory: false,
      addToWineDirectory: false,
    });
    setDropdownOptions("Adicionar vinho");
  };

  const handleHouseWineCancel = () => {
    setHouseWineState({
      ...houseWineState,
      addHouseWine: false,
      wineId: null,
      wineName: null,
      wineImage: null,
      wineType: null,
      winePriceSizes: [
        {
          winePriceSizeId: null,
          wineSize: "75 cl",
          winePrice: 0,
        },
      ],
      winePriceSizeId: null,
      dropdownStatus: [false],
      dropdown: null,
      editHouseWine: false,
    });
    setDropdownOptions("Adicionar vinho");
  };

  const handleDirectoryWineCancel = () => {
    setWineDirectoryState({
      ...wineDirectoryState,
      addToWineDirectory: false,
      wineId: null,
      wineName: null,
      wineImage: null,
      wineType: null,
      wineOrigin: null,
      wineRegion: null,
      wineFort: null,
      wineFortStyle: null,
      winePriceSizes: [
        {
          winePriceSizeId: null,
          wineSize: "75 cl",
          winePrice: 0,
        },
      ],
      dropdown: null,
      dropdownStatus: [false],
      editDirectoryWine: false,
    });
  };

  const handleAddPriceSize = () => {
    setHouseWineState((prevState) => {
      const updatedWinePriceSizes = [
        ...prevState.winePriceSizes,
        { winePriceSizeId: null, wineSize: "75 cl", winePrice: 0 },
      ];
      const updatedDropdownStatus = [...prevState.dropdownStatus, false];

      return {
        ...prevState,
        winePriceSizes: updatedWinePriceSizes,
        dropdownStatus: updatedDropdownStatus,
      };
    });
  };

  const handleWineSizeSelection = (id, index, vol) => {
    // Use map to update the specific item with matching id
    const updatedWinePriceSizes = houseWineState.winePriceSizes.map(
      (item, idx) => {
        if (id !== null) {
          if (item.winePriceSizeId === id)
            // Update the specific item
            return { ...item, wineSize: vol };
          return item;
        } else {
          if (index === idx)
            // Update the specific item
            return { ...item, wineSize: vol };
          // Keep other items unchanged
          return item;
        }
      }
    );

    // Update the state with the modified array
    setHouseWineState({
      ...houseWineState,
      winePriceSizes: updatedWinePriceSizes,
      dropdownStatus: houseWineState.dropdownStatus.map(() => false),
    });
  };

  const handleWinePriceSelection = (id, index, price) => {
    const parsedPrice = parseFloat(parseFloat(price).toFixed(2));
    // Check if price is not a number
    if (isNaN(parsedPrice)) {
      // Alert the user
      alert("Insira um número.");
      return; // Exit the function early
    }

    // Use map to update the specific item with matching id
    const updatedWinePriceSizes = houseWineState.winePriceSizes.map(
      (item, idx) => {
        if (id !== null) {
          if (item.winePriceSizeId === id)
            // Update the specific item
            return { ...item, winePrice: parsedPrice };
          return item;
        } else {
          if (index === idx)
            // Update the specific item
            return { ...item, winePrice: parsedPrice };
          // Keep other items unchanged
          return item;
        }
      }
    );

    // Update the state with the modified array
    setHouseWineState({
      ...houseWineState,
      winePriceSizes: updatedWinePriceSizes,
    });
  };

  const handleWineNameSelection = (isHouseWine, name) => {
    console.log(isHouseWine, name, houseWineState);
    if (isHouseWine)
      setHouseWineState({
        ...houseWineState,
        wineName: name,
      });
    else
      setWineDirectoryState({
        ...wineDirectoryState,
        wineName: name,
      });
  };

  const handleDropdownToggle = (index) => {
    setHouseWineState((prevState) => {
      const updatedDropdownStatus = prevState.dropdownStatus.map((status, i) =>
        i === index ? !status : false
      );

      return {
        ...prevState,
        dropdownStatus: updatedDropdownStatus,
      };
    });
  };

  const toggleTypeExpansion = (type) => {
    setHouseWineState((prevState) => ({
      ...prevState,
      expandedType: prevState.expandedType === type ? null : type,
      expandedVolume: null,
    }));
  };

  const toggleVolumeExpansion = (vol) => {
    setHouseWineState({ ...houseWineState, expandedVolume: vol });
  };

  const editHouseWine = (houseWineId, winePriceSizeId) => {
    const wineById = houseWineState.wines.find(
      (wine) => wine.houseWineId === houseWineId
    );

    setHouseWineState((prevState) => {
      const updatedDropdownStatus = Array.from(
        { length: wineById.winePriceSizes.length },
        () => false
      );
      return {
        ...prevState,
        dropdownStatus: updatedDropdownStatus,
        editHouseWine: true,
        wineId: wineById.houseWineId,
        wineName: wineById.wineName,
        wineImage: wineById.wineImage,
        wineType: wineById.wineType,
        winePriceSizes: wineById.winePriceSizes,
        winePriceSizeId: winePriceSizeId,
      };
    });
  };

  const updateHouseWine = () => {
    const additionalWinePriceSizes = [];

    const wineToUpdate = houseWineState.wines.find(
      (wine) =>
        wine.wineName === houseWineState.wineName &&
        wine.wineType === houseWineState.wineType
    );

    let updatedWine = {
      houseWineId: houseWineState.wineId,
      wineName: houseWineState.wineName,
      wineType: houseWineState.wineType,
      wineImage: houseWineState.wineImage,
      winePriceSizes: houseWineState.winePriceSizes,
    };

    console.log("wineToUpdate: ", updatedWine);

    if (wineToUpdate) {
      // Check if there are more items in houseWineState.winePriceSizes
      if (
        houseWineState.winePriceSizes.length >
        wineToUpdate.winePriceSizes.length
      ) {
        // Iterate over each wine price size in houseWineState
        houseWineState.winePriceSizes.forEach((priceSize1) => {
          // Check if there's a corresponding wine price size in wineToUpdate
          const matchingPriceSize = wineToUpdate.winePriceSizes.find(
            (priceSize2) =>
              priceSize2.winePriceSizeId === priceSize1.winePriceSizeId
          );
          // If no corresponding wine price size is found, add it to additionalWinePriceSizes
          if (!matchingPriceSize) {
            additionalWinePriceSizes.push(priceSize1);
          }
        });

        const uniqueWinePriceSizes = additionalWinePriceSizes.reduce(
          (acc, current) => {
            const existingItem = acc.find(
              (item) => item.wineSize === current.wineSize
            );
            if (!existingItem) {
              acc.push({
                winePriceSizeId: null,
                wineSize: current.wineSize,
                winePrice: current.winePrice,
              });
            }
            return acc;
          },
          []
        );

        const updatedWinePriceSize = [
          ...wineToUpdate.winePriceSizes,
          ...uniqueWinePriceSizes,
        ];

        updatedWine = {
          houseWineId: wineToUpdate.houseWineId,
          wineName: houseWineState.wineName,
          wineType: houseWineState.wineType,
          wineImage: houseWineState.wineImage,
          winePriceSizes: updatedWinePriceSize,
        };
      }
    }

    console.log("updatedWine: ", updatedWine);

    updateHouseWineEndpoint(updatedWine, authorizationToken)
      .then((response) => {
        console.log(response.data);
        fetchHouseWines();
      })
      .catch((error) => {
        alert("Formulário incorreto.");
        console.error(error);
      });
  };

  const removeHouseWine = () => {
    let wineToUpdate = houseWineState.wines.find(
      (wine) => wine.houseWineId === houseWineState.wineId
    );

    const updatedWinePriceSizes = wineToUpdate.winePriceSizes.filter(
      (item) => item.winePriceSizeId !== houseWineState.winePriceSizeId
    );

    if (updatedWinePriceSizes.length > 0) {
      wineToUpdate = {
        ...wineToUpdate,
        winePriceSizes: updatedWinePriceSizes,
      };

      updateHouseWineEndpoint(wineToUpdate, authorizationToken)
        .then((response) => {
          console.log(response.data);
          fetchHouseWines();
        })
        .catch((error) => {
          alert("Formulário incorreto.");
          console.error(error);
        });

      return;
    }

    deleteHouseWineByIdEndpoint(wineToUpdate.wineId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        fetchHouseWines();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="menu">
      {isLoading && <Loading />}
      <div
        className={`flex justify-between ${
          isLoading ? "invisible" : undefined
        }`}
      >
        <div className="w-full">
          <div className="menu-tabs">
            <PageTabs
              tabs={tabs}
              setClass={setPageTab}
              setTab={setMenuSelectedTab}
            />
          </div>

          {menuSelectedTab === "Carta de vinhos" && (
            <WinePage
              wines={wines}
              isMainYearDropdownActive={isMainYearDropdownActive}
              handleYearDetails={handleYearDetails}
              selectedYear={selectedYear}
              availableYears={availableYears}
              setAvailableYears={setAvailableYears}
              handleListYearDropdownItemClick={handleListYearDropdownItemClick}
              addWinePopupIsClicked={addWinePopupIsClicked}
              setAddWinePopupIsClicked={setAddWinePopupIsClicked}
              setWineData={setWineData}
              wineData={wineData}
              addHouseWine={addHouseWine}
              addDirectoryWine={addDirectoryWine}
              handleWineSubmit={handleWineSubmit}
              wine_Directory={wine_Directory}
              wineVolume={wineVolume}
              handleWineDetails={handleWineDetails}
              selectedRow={selectedRow}
              isBottleDropdownActive={isBottleDropdownActive}
              setIsBottleDropdownActive={setIsBottleDropdownActive}
              isYearDropdownActive={isYearDropdownActive}
              setIsYearDropdownActive={setIsYearDropdownActive}
              suggestionIsClicked={suggestionIsClicked}
              handleSuggestionsButton={handleSuggestionsButton}
              handleYearDropdownItemClick={handleYearDropdownItemClick}
              handleBottleDropdownItemClick={handleBottleDropdownItemClick}
              handleWineDetailsSubmit={handleWineDetailsSubmit}
              handleGlassPriceChange={handleGlassPriceChange}
              handleBottlePriceChange={handleBottlePriceChange}
              handleSetDescription={handleSetDescription}
              handleAddWine={handleAddWine}
              addWineIsClicked={addWineIsClicked}
              getWineTypes={getWineTypes}
              handleDirectoryDropdown={handleDirectoryDropdown}
              handleHouseWine={handleHouseWine}
              dropdownOptions={dropdownOptions}
              houseWineState={houseWineState}
              setHouseWineState={setHouseWineState}
              handleDropdown={handleDropdown}
              handleSelectedOption={handleSelectedOption}
              wineDirectoryState={wineDirectoryState}
              setWineDirectoryState={setWineDirectoryState}
              handleWineDirectoryBack={handleWineDirectoryBack}
              handleHouseWineCancel={handleHouseWineCancel}
              handleAddPriceSize={handleAddPriceSize}
              handleWinePriceSelection={handleWinePriceSelection}
              handleWineSizeSelection={handleWineSizeSelection}
              handleDropdownToggle={handleDropdownToggle}
              handleWineNameSelection={handleWineNameSelection}
              isHouseWineStateComplete={isHouseWineStateComplete}
              isDirectoryWineStateComplete={isDirectoryWineStateComplete}
              uploadImage={uploadImage}
              toggleTypeExpansion={toggleTypeExpansion}
              toggleVolumeExpansion={toggleVolumeExpansion}
              editHouseWine={editHouseWine}
              updateHouseWine={updateHouseWine}
              removeHouseWine={removeHouseWine}
              handleDirectoryWineCancel={handleDirectoryWineCancel}
            />
          )}

          {menuSelectedTab === "Ementa" && (
            <EmentaPage
              dishTypeSelected={dishTypeSelected}
              setDishTypeSelected={setDishTypeSelected}
              isEditCategoriesActive={isEditCategoriesActive}
              setIsEditCategoriesActive={setIsEditCategoriesActive}
              categories={categories}
              multiLayerDropdownTabs={multiLayerDropdownTabs}
              selectedDropdownTab={selectedDropdownTab}
              isDropdownActive={isDropdownActive}
              dishActiveDays={dishActiveDays}
              weekdays={weekdays}
              variableDishDescription={variableDishDescription}
              setVariableDishDescription={setVariableDishDescription}
              dishes={dishes}
              setDishes={setDishes}
              isEditDishSlideInActive={isEditDishSlideInActive}
              setIsEditDishSlideInActive={setIsEditDishSlideInActive}
              addNewDish={addNewDish}
              setAddNewDish={setAddNewDish}
              dishVariations={dishVariations}
              setDishVariations={setDishVariations}
              categoriesToBeSaved={categoriesToBeSaved}
              setCategoriesToBeSaved={setCategoriesToBeSaved}
              categoriesToBeDeleted={categoriesToBeDeleted}
              setCategoriesToBeDeleted={setCategoriesToBeDeleted}
              editCategoryExtensionIsActive={editCategoryExtensionIsActive}
              setEditCategoryExtensionIsActive={
                setEditCategoryExtensionIsActive
              }
              newCategoryName={newCategoryName}
              setNewCategoryName={setNewCategoryName}
              itemList={itemList}
              handleAddDish={handleAddDish}
              addDish={addDish}
              setAddDish={setAddDish}
              addMiniDescription={addMiniDescription}
              caracteristics={caracteristics}
              addDishFromList={addDishFromList}
              handleCaracteristicClick={handleCaracteristicClick}
              uploadImage={uploadImage}
              resetDish={resetDish}
              saveNewItem={saveNewItem}
              handleCategorySave={handleCategorySave}
              handleDropdownClick={handleDropdownClick}
              handleDropdownSelect={handleDropdownSelect}
              handleKeyDown={handleKeyDown}
              authorizationToken={authorizationToken}
              updateDish={updateDish}
              variableDishes={variableDishes}
              setVariableDishes={setVariableDishes}
              isAddFromListDrinkCart={isAddFromListDrinkCart}
              drinksCart={drinksCart}
              drinks={drinks}
              isAddDrinkCartActive={isAddDrinkCartActive}
              setNewCartDrink={setNewCartDrink}
              newCartDrink={newCartDrink}
              drinkCaracteristics={drinkCaracteristics}
              cartDrinkVariations={cartDrinkVariations}
              setCartDrinkVariations={setCartDrinkVariations}
              setIsAddDrinkCartActive={setIsAddDrinkCartActive}
              isEditDrinkCartActive={isEditDrinkCartActive}
              setIsEditDrinkCartActive={setIsEditDrinkCartActive}
              setDrinksCart={setDrinksCart}
              drinksOfTheDay={drinksOfTheDay}
              setDrinksOfTheDay={setDrinksOfTheDay}
              drinkCategories={drinkCategories}
              selectedDrinkDropdownTab={selectedDrinkDropdownTab}
              restaurant={restaurant}
            />
          )}

          {menuSelectedTab === "Lista de artigos" && (
            <ItemList
              itemTypeSelected={itemTypeSelected}
              setItemTypeSelected={setItemTypeSelected}
              itemList={itemList}
              setItemList={setItemList}
              itemVariations={itemVariations}
              setItemVariations={setItemVariations}
              isAddItemActive={isAddItemActive}
              setIsAddItemActive={setIsAddItemActive}
              addNewItem={addNewItem}
              setAddNewItem={setAddNewItem}
              isEditItemSlideInActive={isEditItemSlideInActive}
              setIsEditItemSlideInActive={setIsEditItemSlideInActive}
              addMiniDescription={addMiniDescription}
              uploadImage={uploadImage}
              caracteristics={caracteristics}
              handleCaracteristicClick={handleCaracteristicClick}
              resetDish={resetDish}
              saveNewItem={saveNewItem}
              deleteItem={deleteItem}
              updateItem={updateItem}
              authorizationToken={authorizationToken}
              isAddDrinkActive={isAddDrinkActive}
              setIsAddDrinkActive={setIsAddDrinkActive}
              drinks={drinks}
              newDrink={newDrink}
              setNewDrink={setNewDrink}
              itemDrinkVariations={itemDrinkVariations}
              setItemDrinkVariations={setItemDrinkVariations}
              drinkCaracteristics={drinkCaracteristics}
              isEditDrinkActive={isEditDrinkActive}
              setIsEditDrinkActive={setIsEditDrinkActive}
            />
          )}

          {menuSelectedTab === "Menus" && (
            <MenusPage
              isAddMenuActive={isAddMenuActive}
              setIsAddMenuActive={setIsAddMenuActive}
              isOptionsCreateActive={isOptionsCreateActive}
              setIsOptionsCreateActive={setIsOptionsCreateActive}
              menus={menus}
              newMenu={newMenu}
              setNewMenu={setNewMenu}
              calendarSelectedStart={calendarSelectedStart}
              setCalendarSelectedStart={setCalendarSelectedStart}
              calendarSelectedEnd={calendarSelectedEnd}
              setCalendarSelectedEnd={setCalendarSelectedEnd}
              isEditMenuActive={isEditMenuActive}
              setIsEditMenuActive={setIsEditMenuActive}
              weekdays={weekdays}
              isCalendarActive={isCalendarActive}
              setIsCalendarActive={setIsCalendarActive}
              menuDishType={menuDishType}
              setMenuDishType={setMenuDishType}
              multiLayerDropdownTabs={multiLayerDropdownTabs}
              dropdownSelectedMenus={dropdownSelectedMenus}
              isDropdownActiveMenus={isDropdownActiveMenus}
              dishActiveDays={dishActiveDays}
              setDishActiveDays={setDishActiveDays}
              variableDishDescription={variableDishDescription}
              setVariableDishDescription={setVariableDishDescription}
              addDishFromListMenus={addDishFromListMenus}
              itemList={itemList}
              isAddMenuDishActive={isAddMenuDishActive}
              setIsAddMenuDishActive={setIsAddMenuDishActive}
              menuDishVariations={menuDishVariations}
              setMenuDishVariations={setMenuDishVariations}
              addNewMenuDish={addNewMenuDish}
              setAddNewMenuDish={setAddNewMenuDish}
              caracteristics={caracteristics}
              handleAddDishFromListMenu={handleAddDishFromListMenu}
              handleMenusDropdownSelect={handleMenusDropdownSelect}
              handleMenusActivate={handleMenusActivate}
              handleCaracteristicClick={handleCaracteristicClick}
              addMiniDescription={addMiniDescription}
              uploadImage={uploadImage}
              handleKeyDown={handleKeyDown}
              resetDish={resetDish}
              saveNewItem={saveNewItem}
              handleSaveMenu={handleSaveMenu}
              handleEditMenu={handleEditMenu}
              clearNewMenu={clearNewMenu}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
