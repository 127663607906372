import "./home.css";
import location from "../../utils/logos/location.svg";
import blackArrow from "../../utils/logos/blackArrow.svg";
import logo from "../../utils/logos/logo.jpg";
import {
  Dropdown,
  RatingStars,
  PageTabs,
  CalendarDropdown,
  Loading,
} from "../../components";
import { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { delay } from "../../utils/utils.js";

const tabs = ["Vendas", "Clientes", "Preço Médio"];
const dropdownTabs = ["Semanal", "Mensal", "Dois meses", "Ano", "Sempre"];

const currentDate = new Date();
const formattedCurrentDate = `${(currentDate.getMonth() + 1)
  .toString()
  .padStart(2, "0")}/${currentDate
  .getDate()
  .toString()
  .padStart(2, "0")}/${currentDate.getFullYear()}`;

const oneWeekBefore = new Date(currentDate);
oneWeekBefore.setDate(currentDate.getDate() - 7);
const formattedBeforeDate = `${(oneWeekBefore.getMonth() + 1)
  .toString()
  .padStart(2, "0")}/${oneWeekBefore
  .getDate()
  .toString()
  .padStart(2, "0")}/${oneWeekBefore.getFullYear()}`;

let chart = null;

const Home = ({ restaurant }) => {
  const [graphTab, setGraphTab] = useState("sales");
  const [dropdownSelected, setDropdownSelected] = useState("Semanal");
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [homeSelectedTab, setHomeSelectedTab] = useState("Vendas");

  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantLocation, setRestaurantLocation] = useState("");
  const [restaurantPhoto, setRestaurantPhoto] = useState("");
  const [restaurantRating, setRestaurantRating] = useState(-1);
  const [ratingAmount, setRatingAmount] = useState(null);

  const [calendarSelectedStart, setCalendarSelectedStart] =
    useState(formattedBeforeDate);
  const [calendarSelectedEnd, setCalendarSelectedEnd] =
    useState(formattedCurrentDate);
  const [isCalendarActive, setIsCalendarActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const xValues = [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150];
    const yValues = [7, 8, 8, 9, 9, 9, 10, 11, 14, 14, 15];

    if (chart !== null) {
      chart.destroy();
    }

    const initChart = () => {
      chart = new Chart("myChart", {
        type: "line",
        data: {
          labels: xValues,
          datasets: [
            {
              backgroundColor: "#248E86",
              borderColor: "#248E86",
              data: yValues,
            },
          ],
        },
        options: {},
      });
    };

    initChart();

    Promise.all([{}, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const setHomeTab = (tab) => {
    if (tab === homeSelectedTab) return "currentHomeTab";
    else return "";
  };

  /* This function converts a number at the scale 1-10 to the 0-5 scale */
  function convertScale(value) {
    if (value === 0) return 0;
    return (value - 1) * (5 / 9);
  }

  /* This function roudns a number to its closes half */
  function round(value) {
    return Math.round(value * 2) / 2;
  }

  return (
    <div className="home">
      {isLoading && <Loading />}
      <div className={`w-full h-full ${isLoading ? "invisible" : undefined}`}>
        <div className="restaurant-info">
          <img
            className="restaurant-info-photo"
            src={restaurant.restaurantImage ? restaurant.restaurantImage : logo}
            alt="restaurant logo"
          />
          <div className="restaurant-info-locationAndRating">
            <h1>
              {restaurant.restaurantName
                ? restaurant.restaurantName
                : "Nome Restaurante"}
            </h1>
            <div className="restaurant-info-location">
              <img src={location} alt="location" />
              <p>
                {restaurant.address && restaurant.address.city
                  ? restaurant.address.city
                  : "Localização Restaurante"}
              </p>
            </div>
            <div className="restaurant-info-rating">
              <RatingStars
                value={round(convertScale(restaurant.averageQuality))}
              />
              <p>
                <b>{round(convertScale(restaurant.averageQuality))}</b> (
                {ratingAmount ? ratingAmount : 0} ratings)
              </p>
            </div>
          </div>
        </div>

        <PageTabs
          tabs={tabs}
          setClass={setHomeTab}
          setTab={setHomeSelectedTab}
        />

        <div className="buttons">
          <div className="arrows">
            <img className="leftArrow" src={blackArrow} alt="arrow" />
            <img src={blackArrow} alt="arrow" />
          </div>
          <div className="dropdowns">
            <Dropdown
              tabs={dropdownTabs}
              selected={dropdownSelected}
              setSelected={setDropdownSelected}
              isActive={isDropdownActive}
              setIsActive={setIsDropdownActive}
              style={{ filter: "none" }}
            />
            <div className="calendar">
              <CalendarDropdown
                selectedStart={calendarSelectedStart}
                setSelectedStart={setCalendarSelectedStart}
                selectedEnd={calendarSelectedEnd}
                setSelectedEnd={setCalendarSelectedEnd}
                isActive={isCalendarActive}
                setIsActive={setIsCalendarActive}
              />
            </div>
          </div>
        </div>

        <div
          className="blurArea"
          style={isDropdownActive ? { filter: "blur(5px)" } : {}}
        >
          <div className="chart-area">
            <canvas id="myChart"></canvas>
          </div>
          <p className="last-update">Last updated 01 Jan at 04:30</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
