import crossButton from "../utils/logos/crossButton.png";

const NewRestaurantPopup = ({
    createRestaurant,
    uploadImage,
    setRestaurantName,
    restaurantImage,
    setIsAddRestaurantActive,
}) => {
    return (
        <form
            onSubmit={(e) => createRestaurant(e)}
            className="absolute top-1/2 left-1/2 bg-twinkleColor p-4 transform -translate-x-1/2 -translate-y-1/2 rounded-xl flex flex-col z-10"
        >
            <button
                onClick={() => setIsAddRestaurantActive(false)}
                className="w-7"
            >
                <img src={crossButton} />
            </button>
            <div className="flex items-center justify-center">
                <input
                    id="file-input"
                    type="file"
                    name="file"
                    className="hidden"
                    onChange={(e) => uploadImage(e.target.files[0])}
                />
                <label
                    htmlFor="file-input"
                    className="flex items-center justify-center w-16 h-16 bg-gray-400 text-white rounded-full cursor-pointer"
                >
                    {restaurantImage === "" ? (
                        <svg
                            className="w-8 h-8"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 4v16m8-8H4"
                            />
                        </svg>
                    ) : (
                        <img
                            src={restaurantImage}
                            alt="Restaurant"
                            className="w-full h-full object-cover rounded-full"
                        />
                    )}
                </label>
            </div>
            <input
                className="h-10 w-60 rounded-2xl indent-3 my-7"
                placeholder="Nome Restaurante"
                onChange={(e) => setRestaurantName(e.target.value)}
            />

            <button
                type="submit"
                className="h-10 w-60 rounded-2xl bg-gray-400 px-2 w-30 text-white"
            >
                Criar Restaurante
            </button>
        </form>
    );
};

export default NewRestaurantPopup;
