import { useState, useEffect } from "react";
import {
  createRestaurantEndpoint,
  listRestaurantsByUserEndpoint,
  uploadImageEndpoint,
} from "../../backendFunctions";
import { useNavigate } from "react-router-dom";
import plus from "../../utils/logos/plus.svg";
import logout from "../../utils/logos/logout.svg";
import { NewRestaurantPopup, Loading } from "../../components";
import { delay } from "../../utils/utils.js";

const Panel = ({ authorizationToken, setRestaurant, setSelectedTab }) => {
  const [isAddRestaurantActive, setIsAddRestaurantActive] = useState(false);
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantImage, setRestaurantImage] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantCreateToggle, setRestaurantCreateToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab("home");
    const fetchData = listRestaurantsByUserEndpoint(authorizationToken)
      .then((response) => {
        setRestaurants(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([fetchData, delay()]).then(() => {
      setIsLoading(false);
    });
  }, [restaurantCreateToggle]);

  const uploadImage = (image) => {
    uploadImageEndpoint(image)
      .then((response) => {
        console.log(response.data);
        setRestaurantImage(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createRestaurant = (e) => {
    e.preventDefault();
    if (restaurantName !== "" && restaurantImage !== "") {
      const restaurant = {
        restaurantName: restaurantName,
        restaurantImage: restaurantImage,
      };

      createRestaurantEndpoint(restaurant, authorizationToken)
        .then((response) => {
          console.log(response.data);
          if (
            response.data.httpStatus === 200 &&
            response.data.message === "OK"
          ) {
            setRestaurantCreateToggle(!restaurantCreateToggle);
            setIsAddRestaurantActive(false);
            setRestaurantImage("");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else alert("Insira Nome e Imagem");
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem("authToken");
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div
        className={`flex flex-col items-start justify-between p-10 w-full h-screen ${
          isLoading ? "invisible" : undefined
        }`}
      >
        <div className="flex flex-col items-start space-y-2">
          <h1
            className={`text-2xl font-bold ${
              isAddRestaurantActive ? "blur-sm" : undefined
            }`}
          >
            Os seus restaurantes
          </h1>
          <p
            className={`text-md ${
              isAddRestaurantActive ? "blur-sm" : undefined
            }`}
          >
            Como manager pode ter vários restaurantes sobre a sua gestão.
          </p>
        </div>
        <div className="grid grid-cols-3 grid-rows-2 gap-x-10 gap-y-2 w-1/2 h-full mx-auto my-auto">
          <div
            className={`flex flex-col items-center justify-center space-y-2 ${
              isAddRestaurantActive
                ? "blur-sm cursor-default"
                : "cursor-pointer"
            }`}
            onClick={
              !isAddRestaurantActive
                ? () => setIsAddRestaurantActive(true)
                : () => {}
            }
          >
            <div className="w-56 h-56 rounded-lg bg-gray-200 flex justify-center ittems-center">
              <img src={plus} alt="add Restaurant" />
            </div>
            <span className="opacity-0">Add Restaurant</span>
          </div>
          {isAddRestaurantActive && (
            <NewRestaurantPopup
              createRestaurant={createRestaurant}
              uploadImage={uploadImage}
              setRestaurantName={setRestaurantName}
              restaurantImage={restaurantImage}
              setIsAddRestaurantActive={setIsAddRestaurantActive}
            />
          )}
          {restaurants.map((restaurant) => (
            <div
              className={`flex flex-col items-center justify-center space-y-2 ${
                isAddRestaurantActive
                  ? "blur-sm cursor-default"
                  : "cursor-pointer"
              }`}
              key={restaurant.restaurantId}
            >
              <img
                className="w-56 h-56 rounded-lg"
                src={restaurant.restaurantImage}
                alt="restaurant logo"
                onClick={
                  !isAddRestaurantActive
                    ? () => {
                        setRestaurant(restaurant);
                        navigate("/home");
                      }
                    : () => {}
                }
              />
              <span className="restaurant-name">
                {restaurant.restaurantName}
              </span>
            </div>
          ))}
        </div>
        <div
          className={`bg-gray-200 rounded-lg py-3 px-4 ${
            isAddRestaurantActive ? "blur-sm cursor-default" : "cursor-pointer"
          }`}
        >
          <button
            onClick={!isAddRestaurantActive ? handleLogout : () => {}}
            className={`flex justify-center items-center space-x-3`}
          >
            <img src={logout} alt="logout" />
            <span>Terminar sessão</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Panel;
