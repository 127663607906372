import "./info.css";
import { useState, useEffect } from "react";
import { TypesDropdown, Loading } from "../../components";
import editButton from "../../utils/logos/editButton.png";
import {
  getRestaurantInfoEndpoint,
  updateRestaurantInfoEndpoint,
} from "../../backendFunctions";
import Switch from "react-switch";
import { delay } from "../../utils/utils.js";

const Info = ({ restaurant, authorizationToken, setRestaurantWasUpdated }) => {
  /* DB data */
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [city, setCity] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [kitchenTypes, setKitchenTypes] = useState(null);
  const [isActive, setIsActive] = useState(null);

  const [isEditActive, setIsEditActive] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = getRestaurantInfoEndpoint(
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data.data);
        setName(response.data.data.restaurantName);
        setEmail(response.data.data.restaurantEmail);
        setPhoneNumber(response.data.data.restaurantPhoneNumber);
        setAddress(response.data.data.address.addressLine);
        setPostCode(response.data.data.address.postalCode);
        setCity(response.data.data.address.city);
        setKitchenTypes(
          response.data.data.restaurantTypes
            ? response.data.data.restaurantTypes
            : []
        );
        setIsActive(response.data.data.isActive);
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([fetchData, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const updateInfoOnDB = () => {
    // Validation step to check for null fields
    if (
      !name ||
      !address ||
      !city ||
      !postCode ||
      !email ||
      !phoneNumber ||
      !kitchenTypes
    ) {
      alert("Please fill in all the fields before updating.");
      return; // Do not proceed with the update
    }

    setIsEditActive(false);
    setIsDropdownActive(false);

    const info = {
      restaurantName: name,
      address: {
        addressLine: address,
        city: city,
        postalCode: postCode,
      },
      restaurantEmail: email,
      restaurantPhoneNumber: phoneNumber,
      restaurantTypes: kitchenTypes,
      isActive: isActive,
    };

    updateRestaurantInfoEndpoint(
      info,
      restaurant.restaurantId,
      authorizationToken
    )
      .then((response) => {
        console.log(response.data.data);
        setRestaurantWasUpdated(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function handleOptionSelect(option) {
    // Add or remove the selected option from the array of selected options
    setKitchenTypes((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option))
        return prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        );
      return [...prevSelectedOptions, option];
    });
  }

  return (
    <div className="info">
      {isLoading && <Loading />}
      <div className={`w-full h-full ${isLoading ? "invisible" : undefined}`}>
        <h1>Informações</h1>

        <div
          className={isEditActive ? "inputContainer edit" : "inputContainer"}
        >
          <div className="toggle">
            <span>Restaurant active</span>

            <Switch
              checked={isActive !== null ? isActive : false}
              onChange={
                isEditActive ? () => setIsActive((prev) => !prev) : () => {}
              }
              onColor="#77B9B4"
              offColor="#E9E9E9"
              onHandleColor="#000000"
              offHandleColor="#000000"
              checkedIcon={false}
              uncheckedIcon={false}
              width={40}
              height={20}
              handleDiameter={20}
            />
            <img
              className="ml-96"
              src={editButton}
              onClick={() => setIsEditActive(true)}
              style={isEditActive ? { display: "none" } : {}}
              alt="edit"
            />
          </div>

          <div className="name">
            <span style={isEditActive ? { fontWeight: "500" } : {}}>Nome:</span>
            <input
              value={name ? name : ""}
              onChange={(e) => setName(e.target.value)}
              readOnly={isEditActive ? false : true}
              style={
                isEditActive
                  ? {
                      border: "1px solid #D9D9D9",
                      background: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>

          <div className="address">
            <span style={isEditActive ? { fontWeight: "500" } : {}}>
              Morada:
            </span>
            <input
              value={address ? address : ""}
              onChange={(e) => setAddress(e.target.value)}
              readOnly={isEditActive ? false : true}
              style={
                isEditActive
                  ? {
                      border: "1px solid #D9D9D9",
                      background: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>

          <div className="postCode">
            <span style={isEditActive ? { fontWeight: "500" } : {}}>
              Código Postal:
            </span>
            <input
              value={postCode ? postCode : ""}
              onChange={(e) => setPostCode(e.target.value)}
              readOnly={isEditActive ? false : true}
              style={
                isEditActive
                  ? {
                      border: "1px solid #D9D9D9",
                      background: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>

          <div className="city">
            <span style={isEditActive ? { fontWeight: "500" } : {}}>
              Cidade:
            </span>
            <input
              value={city ? city : ""}
              onChange={(e) => setCity(e.target.value)}
              readOnly={isEditActive ? false : true}
              style={
                isEditActive
                  ? {
                      border: "1px solid #D9D9D9",
                      background: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>

          <div className="email">
            <span style={isEditActive ? { fontWeight: "500" } : {}}>
              E-mail:
            </span>
            <input
              value={email ? email : ""}
              onChange={(e) => setEmail(e.target.value)}
              readOnly={isEditActive ? false : true}
              style={
                isEditActive
                  ? {
                      border: "1px solid #D9D9D9",
                      background: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>

          <div className="phoneNumber">
            <span style={isEditActive ? { fontWeight: "500" } : {}}>
              Nº de telemóvel:
            </span>
            <input
              value={phoneNumber ? phoneNumber : ""}
              onChange={(e) => setPhoneNumber(e.target.value)}
              readOnly={isEditActive ? false : true}
              style={
                isEditActive
                  ? {
                      border: "1px solid #D9D9D9",
                      background: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>

          <div className="kitchenType">
            <span style={isEditActive ? { fontWeight: "500" } : {}}>
              Tipo de cozinha:
            </span>
            <TypesDropdown
              isActive={isDropdownActive}
              setIsActive={setIsDropdownActive}
              isEditable={isEditActive}
              selectedOptions={kitchenTypes ? kitchenTypes : []}
              onOptionSelect={handleOptionSelect}
              height={1.563}
              width={23.5}
            />
          </div>

          {isEditActive && (
            <button onClick={() => updateInfoOnDB()} className="saveBtn">
              Guardar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
