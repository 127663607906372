import "./App.css";
import {
  Home,
  Feedback,
  Payments,
  Menu,
  Services,
  Pictures,
  Users,
  Info,
  Authentication,
  Admin,
  Panel,
  Setup,
  MobilePage,
} from "./pages";
import { SideBar } from "./components";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { getRestaurantByIdEndpoint } from "./backendFunctions";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";

// TODO: No css substituit paddings nos inputs por "text-indent"

function App() {
  const [selectedTab, setSelectedTab] = useState(
    JSON.parse(localStorage.getItem("selectedTab")) || "home"
  );
  const [completePhoneNumber, setCompletePhoneNumber] = useState("");
  const [tokenForUserSetup, setTokenForUserSetup] = useState("");
  const [authorizationToken, setAuthorizationToken] = useState(
    JSON.parse(localStorage.getItem("authToken"))
  );
  const [restaurant, setRestaurant] = useState(
    JSON.parse(localStorage.getItem("restaurant")) || null
  );
  const [restaurantWasUpdated, setRestaurantWasUpdated] = useState(false);

  useEffect(() => {
    console.log("SELECTED:", selectedTab);
    localStorage.setItem("selectedTab", JSON.stringify(selectedTab));
    localStorage.setItem("authToken", JSON.stringify(authorizationToken));
    localStorage.setItem("restaurant", JSON.stringify(restaurant));
  }, [selectedTab, authorizationToken, restaurant]);

  useEffect(() => {
    if (restaurantWasUpdated) {
      getRestaurantByIdEndpoint(restaurant.restaurantId, authorizationToken)
        .then((response) => {
          console.log(response.data.data);
          setRestaurant(mergeRestaurantFields(restaurant, response.data.data));
          setRestaurantWasUpdated(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [restaurantWasUpdated]);

  const mergeRestaurantFields = (originalRestaurant, updatedRestaurant) => {
    const mergedRestaurant = { ...originalRestaurant };

    Object.keys(updatedRestaurant).forEach((key) => {
      if (mergedRestaurant.hasOwnProperty(key)) {
        mergedRestaurant[key] = updatedRestaurant[key];
      }
    });

    return mergedRestaurant;
  };

  const isMobileDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints;
  };

  // Check if the device is a mobile phone
  const isMobilePhone = isMobileDevice();

  //const { isAuthenticated } = useAuth();

  const shouldRenderSideBar = restaurant === null ? false : true;

  return (
    <div className="App">
      <AuthProvider setAuthorizationToken={setAuthorizationToken}>
        <BrowserRouter>
          {shouldRenderSideBar && (
            <SideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              restaurant={restaurant}
              setRestaurant={setRestaurant}
            />
          )}

          <Routes>
            {!isMobilePhone && (
              <>
                <Route
                  path="/"
                  element={
                    <Authentication
                      setCompletePhoneNumber={setCompletePhoneNumber}
                      setTokenForUserSetup={setTokenForUserSetup}
                      setRestaurant={setRestaurant}
                    />
                  }
                />
                <Route
                  path="/setup"
                  element={
                    <Setup
                      completePhoneNumber={completePhoneNumber}
                      tokenForUserSetup={tokenForUserSetup}
                    />
                  }
                />
                <Route
                  path="/panel"
                  element={
                    <ProtectedRoute
                      element={
                        <Panel
                          authorizationToken={authorizationToken}
                          setRestaurant={setRestaurant}
                          setSelectedTab={setSelectedTab}
                        />
                      }
                    />
                  }
                />

                <Route
                  path="/admin"
                  element={<ProtectedRoute element={<Admin />} />}
                />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute
                      element={<Home restaurant={restaurant} />}
                    />
                  }
                />
                <Route
                  path="/feedback"
                  element={
                    <ProtectedRoute
                      element={
                        <Feedback
                          restaurant={restaurant}
                          authorizationToken={authorizationToken}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/menu"
                  element={
                    <ProtectedRoute
                      element={
                        <Menu
                          restaurant={restaurant}
                          authorizationToken={authorizationToken}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/services"
                  element={
                    <ProtectedRoute
                      element={
                        <Services
                          restaurant={restaurant}
                          authorizationToken={authorizationToken}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/pictures"
                  element={
                    <ProtectedRoute
                      element={
                        <Pictures
                          restaurant={restaurant}
                          authorizationToken={authorizationToken}
                          restaurantWasUpdated={restaurantWasUpdated}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/payments"
                  element={
                    <ProtectedRoute
                      element={
                        <Payments
                          restaurant={restaurant}
                          authorizationToken={authorizationToken}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute
                      element={
                        <Users
                          restaurant={restaurant}
                          authorizationToken={authorizationToken}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/info"
                  element={
                    <ProtectedRoute
                      element={
                        <Info
                          restaurant={restaurant}
                          authorizationToken={authorizationToken}
                          setRestaurantWasUpdated={setRestaurantWasUpdated}
                        />
                      }
                    />
                  }
                />
              </>
            )}
            {isMobilePhone && <Route path="/*" element={<MobilePage />} />}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
