import "./sideBar.css";
import SideBarElement from "../sideBarElement/SideBarElement";
import arrow from "../../utils/logos/arrow.svg";
import { icons } from "../../utils/constants";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../utils/logos/twinkleLogo.png";
import logo2 from "../../utils/logos/logo.jpg";
import whiteLogout from "../../utils/logos/whiteLogout.svg";
import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";

function SideBar({ selectedTab, setSelectedTab, restaurant, setRestaurant }) {
  const [isArrowExtensionActive, setIsArrowExtensionActive] = useState(false);
  const [restaurantPicture, setRestaurantPicture] = useState(null);

  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    try {
      localStorage.removeItem("authToken");
      localStorage.removeItem("restaurant");
      //setAuthorizationToken(null)
      logout();
      setRestaurant(null);
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  const handlePanelClick = () => {
    try {
      localStorage.removeItem("restaurant");
      setRestaurant(null);
      navigate("/panel");
      console.log("teste");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    setRestaurantPicture(restaurant.restaurantImage);
  }, [restaurant.restaurantImage]);

  return (
    <div className="sideBar">
      <img className="logo" src={logo} alt="twinkle logo" />

      <div className="sideBar-elements">
        {icons.map((icon) => (
          <div
            className="sideBar-element"
            key={icon.id}
            onClick={() => setSelectedTab(icon.id)}
          >
            <Link
              to={icon.id === "home" ? "/home" : "/" + icon.id.toLowerCase()}
              style={{ textDecoration: "none" }}
            >
              <SideBarElement
                class={icon.id === selectedTab ? "currentSidebarTab" : ""}
                logo={icon.icon}
                title={icon.title}
              />
            </Link>
          </div>
        ))}
      </div>

      {isArrowExtensionActive && (
        <div className="sideBar-logout">
          <span onClick={handlePanelClick}>Os meus restaurantes</span>
          <button onClick={handleLogout} className="logout-button">
            <img src={whiteLogout} alt="logout" />
            <span style={{ marginLeft: "1.313rem" }}>Terminar sessão</span>
          </button>
        </div>
      )}

      <div className="sideBar-footer">
        <img
          src={restaurantPicture ? restaurantPicture : logo2}
          alt="restaurant logo"
          className="sideBar-footer-image"
        />

        <div className="sideBar-footer-text">
          <h1>
            {restaurant.restaurantName
              ? restaurant.restaurantName
              : "Nome Restaurante..."}
          </h1>
          <p>
            {restaurant.address && restaurant.address.city
              ? restaurant.address.city
              : "Localização..."}
          </p>
        </div>

        <div className="sideBar-footer-icon">
          <img
            src={arrow}
            onClick={() => {
              setIsArrowExtensionActive(!isArrowExtensionActive);
            }}
            alt="Arrow"
            style={
              isArrowExtensionActive ? { transform: "rotate(180deg)" } : {}
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SideBar;
