import './dropdown.css';
import blackArrow from '../../utils/logos/blackArrow.svg';
import arrow from '../../utils/logos/arrow.svg';

function Dropdown({ tabs, selected, setSelected, isActive, setIsActive }) {

    return (
        <div className="dropdown">
            <div className="dropdown-btn" onClick={() => setIsActive(!isActive)} style={isActive ? { backgroundColor: "#2F2F2F", color: "#FFFFFF" } : {}} >
                <p style={isActive ? {color: "#FFFFFF"} : {}}>{selected}</p>
                <img src={isActive ? arrow : blackArrow} alt="arrow" />
            </div>

            {isActive &&
                <div className="dropdown-content">
                    {tabs.map((item) => (
                        item !== selected && (
                            <div className="dropdown-item" onClick={() => {
                                setSelected(item)
                                setIsActive(!isActive)
                            }} key={item} >
                                <p>{item}</p>
                            </div>
                        )
                    ))}
                </div>
            }
        </div>
    );
}

export default Dropdown;