import "./pictures.css";
import plus from "../../utils/logos/plus.svg";
import { Image, Loading } from "../../components";
import { useState, useEffect } from "react";
import {
  uploadImageEndpoint,
  getImagesList,
  changeImagesList,
} from "../../backendFunctions";
import { delay } from "../../utils/utils.js";

const Pictures = ({
  restaurant,
  authorizationToken,
  setRestaurantWasUpdated,
}) => {
  const [isAddPicActive, setIsAddPicActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [restaurantImages, setRestaurantImages] = useState(null);
  const [isUploadImageReady, setIsUploadImageReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = getImagesList(restaurant.restaurantId, authorizationToken)
      .then((response) => {
        console.log(response.data);
        setRestaurantImages(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });

    Promise.all([fetchData, delay()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("restaurantImages: ", restaurantImages);
  }, [restaurantImages]);

  useEffect(() => {
    if (isUploadImageReady || restaurantImages) {
      changeImagesList(
        restaurantImages,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setIsUploadImageReady(false);
          setIsAddPicActive(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isUploadImageReady, restaurantImages]);

  const saveNewImage = (image) => {
    uploadImageEndpoint(image)
      .then((response) => {
        console.log(response.data);
        const newImage = {
          pictureName: "Nova Fotografia",
          pictureURL: response.data.data,
          isDish: false,
          isVisible: true,
        };
        setRestaurantImages([...restaurantImages, newImage]);
        setIsUploadImageReady(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const editImage = (picture, id) => {
    uploadImageEndpoint(picture)
      .then((response) => {
        console.log(response.data);
        const updatedImages = restaurantImages.map((img) =>
          img.pictureId === id
            ? { ...img, pictureURL: response.data.data }
            : img
        );
        setRestaurantImages(updatedImages);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateDescription = (idx, newDescription) => {
    if (newDescription !== "") {
      setRestaurantImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[idx] = {
          ...updatedImages[idx],
          pictureName: newDescription,
        };
        return updatedImages;
      });
      setIsUploadImageReady(true);
    }
  };

  const updateVisibilityState = (idx) => {
    setRestaurantImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[idx] = {
        ...updatedImages[idx],
        isVisible: !prevImages[idx].isVisible,
      };
      return updatedImages;
    });
  };

  return (
    <div className="pictures">
      {isLoading && <Loading />}
      <div
        className={`flex justify-between ${
          isLoading ? "invisible" : undefined
        }`}
      >
        <div className="mb-4">
          <h1 className="pageTitle">Fotografias</h1>
          <p className="headingDescription">
            Aqui pode definir as fotografias que aparecem no perfil do seu
            restaurante.
          </p>

          <div className="addNewImage">
            <label htmlFor="file">
              <div
                className="addImgBtn"
                onClick={() => setIsAddPicActive(!isAddPicActive)}
                style={isAddPicActive ? { background: "#77B9B4" } : {}}
              >
                <img src={plus} alt="plus" />
                <p>Adicionar fotografia</p>
              </div>
            </label>
            <input
              type="file"
              id="file"
              onChange={(e) => {
                saveNewImage(e.target.files[0]);
              }}
            />
          </div>

          {restaurantImages &&
            restaurantImages.map((image, idx) => (
              <Image
                key={idx}
                onClick={() => {
                  setRestaurantImages((prev) =>
                    prev.filter((imageURL) => imageURL !== image)
                  );
                  setIsUploadImageReady(true);
                }}
                image={image}
                isEditActive={activeIndex === idx + 1}
                setIsEditActive={setActiveIndex}
                idx={idx + 1}
                updateDescription={updateDescription}
                updateVisibilityState={updateVisibilityState}
                sort={false}
                editImage={editImage}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Pictures;
