import './admin.css';
import { useState } from 'react';
import { AdminAccess } from '../../components';

const Admin = () => {

    const [isAdmin, setIsAdmin] = useState(false);

    return (
        <div className='admin'>
            { !isAdmin &&
                <AdminAccess onSuccess={setIsAdmin} />
            }
            { isAdmin &&
                <div className='menuPage'>
                    <h1>Admin's Menu</h1>
                    <h2>EM CONSTRUÇÂO...</h2>
                </div>
            }

        </div>
    )
}

export default Admin