import './wineList.css';
import menu from '../../utils/logos/menu.svg';
import blackArrow from '../../utils/logos/blackArrow.svg';

const WineList = ({ houseWines, directoryWines, getWineTypes, toggleTypeExpansion, expandedType, toggleVolumeExpansion, expandedVolume, bottleVolume, editHouseWine, updateHouseWine }) => {

    const getWinesByRegion = (wines) => {
        return wines.reduce((acc, wine) => {
            const { wineRegion, wineYear } = wine;
            if (!acc[wineRegion]) {
                acc[wineRegion] = [];
            }
            acc[wineRegion].push(wine);
            // Sort wines within the region by name and then by year
            acc[wineRegion].sort((a, b) => {
                if (a.wineName === b.wineName) {
                    return b.wineYear - a.wineYear;
                }
                // Otherwise, sort by name
                return a.wineName.localeCompare(b.wineName);
            });
            return acc;
        }, {});
    };

    const getWinesByOrigin = (wines) => {
        return wines.reduce((acc, wine) => {
            const { wineOrigin } = wine;
            if (!acc[wineOrigin])
                acc[wineOrigin] = [];

            acc[wineOrigin].push(wine);
            return acc;
        }, {});
    };

    const getWineVolumesByType = (type) => {
        let volumesByType = [];
        if (houseWines)
            getWinesToList(houseWines).map((wine) => {
                if (wine.wineType === type && !volumesByType.includes(wine.wineSize))
                    volumesByType.push(wine.wineSize);
            });
        
        if (directoryWines) 
            getWinesToList(directoryWines).map((wine) => {
                if (wine.wineType === type && !volumesByType.includes(wine.wineSize))
                    volumesByType.push(wine.wineSize);
            });

        // Custom sorting function based on bottleVolume array
        volumesByType.sort((a, b) => {
            const indexA = bottleVolume.indexOf(a);
            const indexB = bottleVolume.indexOf(b);
            // Sort by their indices
            return indexA - indexB;
        });

        return volumesByType;
    }

    const getWinesToList = (wines) => {
        const winesByVolume = [];
    
        wines && wines.map((item) => {
            if (item.winePriceSizes.length > 0) {
                const wineByVolume = item.winePriceSizes.map(priceVolume => {
                    // Create a new wine object with the same properties
                    const splitWine = { ...item };
                    
                    splitWine.wineSize = priceVolume.wineSize;
                    splitWine.winePrice = priceVolume.winePrice;
                    splitWine.winePriceSizeId = priceVolume.winePriceSizeId;
                    
                    return splitWine;
                });
                winesByVolume.push(...wineByVolume);
            } else {
                winesByVolume.push(item);
            }
        });
        return winesByVolume;
    }

    return (
        <div className='wineList'>
            {getWineTypes(houseWines, directoryWines).map((type, index) => (
                <div key={index} className='winesByType'>
                    <div className='wines-header' onClick={() => {expandedType === type ? toggleTypeExpansion(null) : toggleTypeExpansion(type)}}>
                        <h2>{type}</h2>
                        <img 
                            src={blackArrow} 
                            alt="arrow" 
                            style={expandedType === type ? { transform: 'rotate(180deg)' } : {}}
                        /> 
                    </div>
                    {(expandedType === type) && (
                        <div>
                            <div className='volume-tabs'>
                                {getWineVolumesByType(type).map((vol) => (
                                    <button 
                                        key={`${type}-${index}-${vol}`} 
                                        className='volume-tab' 
                                        onClick={() => {toggleVolumeExpansion(vol)}} 
                                        style={expandedVolume === vol ? { background: '#248E86', color: 'white', fontWeight: '600'} : {}}>
                                            {vol}
                                    </button>
                                ))}
                            </div>
                            <div className='winesByCategory'>
                                {houseWines && houseWines.length > 0 &&
                                    <div className='list'>
                                        <h2>Vinho da casa</h2>
                                        {getWinesToList(houseWines).map((wine) => {
                                            if ((!expandedVolume || wine.wineSize === expandedVolume) && wine.wineType === expandedType) {
                                                return (
                                                    <li key={`${wine.houseWineId}-${wine.winePriceSizeId}`} className='wine-row' onClick={() => {editHouseWine(wine.houseWineId, wine.winePriceSizeId)}}>
                                                        <img src={menu} alt="menu" />
                                                        <div className='wine-item'>
                                                            <div className='wine-left'>
                                                                <img className='wine-image' src={wine.wineImage} alt='wine' />
                                                                <span>{wine.wineName}</span>
                                                            </div>
                                                            <div className='wine-right'>
                                                                <span>{wine.winePrice}€</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>    
                                }
                                {directoryWines && directoryWines.length > 0 && 
                                    <div className='list'>
                                        {Object.entries(getWinesByOrigin(directoryWines)).map(([origin, wines]) => (
                                            <div key={origin}>
                                                <h2>{origin}</h2>
                                                {Object.entries(getWinesByRegion(wines)).map(([region, winesInRegion]) => (
                                                    <div key={region}>
                                                        <h3>{region}</h3>
                                                        {winesInRegion.map(wine => (
                                                            <li key={wine.wineNameId}>
                                                                <img src={menu} alt="menu" />
                                                                <div className='wine-item'>
                                                                    <div className='wine-left'>
                                                                        <div className="wine-name">
                                                                            {wine.wineName}
                                                                        </div>
                                                                        <div className='wine-image'>
                                                                            <img className='wine-image' src={wine.wineImage} alt='wine'></img>
                                                                        </div>
                                                                    </div>
                                                                    <div className='wine-right'>
                                                                        <span>{wine.winePrice}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>  
                                }
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default WineList;