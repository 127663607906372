import './sideBarElement.css'

function SideBarElement (props) {
    return(
        <div className={props.class}>
            <div className='element'>
                <img src={props.class === 'currentSidebarTab' ? props.logo[1] : props.logo[0]} alt={props.title} />
                <p>{props.title}</p>
            </div>
        </div>
    );
}

export default SideBarElement;