import './mobilePage.css'
import logo from '../../utils/logos/twinkleLogo.png'

const MobilePage = () => {
  return (
    <div className='mobilePage'>
        <img src={logo} alt="logo" />
        <h1>Por favor, aceda através do seu computador.</h1>
    </div>
  )
}

export default MobilePage