import { useState } from "react";
import {
  SearchBar,
  MultiLayerDropdown2,
  DishForSlideIn,
  Dish,
  DishOfTheDay,
  DishTypeButtons,
} from "../../components";
import plus from "../../utils/logos/plus.svg";
import whitePlus from "../../utils/logos/whitePlus.svg";
import crossButton from "../../utils/logos/crossButton.png";
import minus from "../../utils/logos/minus.svg";
import menu from "../../utils/logos/menu.svg";
import pencil from "../../utils/logos/pencil.svg";
import {
  getDishCartByIdEndpoint,
  deleteDishCartEndpoint,
  deleteDishOfTheDayEndpoint,
  updateDishOfTheDayEndpoint,
  getCartDrinksByIdEndpoint,
  deleteCartDrinkEndpoint,
  deleteDrinkOfTheDayEndpoint,
  updateDrinkOfTheDayEndpoint,
  getDishOfTheDayEndpoint,
  getDrinkOfTheDayEndpoint,
} from "../../backendFunctions";

const EmentaPage = ({
  dishTypeSelected,
  setDishTypeSelected,
  isEditCategoriesActive,
  setIsEditCategoriesActive,
  categories,
  multiLayerDropdownTabs,
  selectedDropdownTab,
  handleDropdownSelect,
  isDropdownActive,
  handleDropdownClick,
  dishActiveDays,
  weekdays,
  variableDishDescription,
  setVariableDishDescription,
  handleKeyDown,
  dishes,
  setIsEditDishSlideInActive,
  setAddNewDish,
  setDishVariations,
  categoriesToBeSaved,
  setCategoriesToBeSaved,
  categoriesToBeDeleted,
  setCategoriesToBeDeleted,
  setEditCategoryExtensionIsActive,
  editCategoryExtensionIsActive,
  newCategoryName,
  setNewCategoryName,
  handleCategorySave,
  addDishFromList,
  itemList,
  handleAddDish,
  addDish,
  dishVariations,
  addMiniDescription,
  addNewDish,
  uploadImage,
  caracteristics,
  handleCaracteristicClick,
  caracteristicsCorrespondency,
  setAddDish,
  resetDish,
  saveNewItem,
  isEditDishSlideInActive,
  setDishes,
  authorizationToken,
  updateDish,
  variableDishes,
  setVariableDishes,
  isAddFromListDrinkCart,
  drinks,
  drinksCart,
  isAddDrinkCartActive,
  setNewCartDrink,
  newCartDrink,
  drinkCaracteristics,
  cartDrinkVariations,
  setCartDrinkVariations,
  setIsAddDrinkCartActive,
  isEditDrinkCartActive,
  setIsEditDrinkCartActive,
  setDrinksCart,
  drinksOfTheDay,
  setDrinksOfTheDay,
  drinkCategories,
  selectedDrinkDropdownTab,
  restaurant,
}) => {
  const [isEditDishOfTheDayActive, setIsEditDishOfTheDayActive] = useState(-1);
  const [isEditDrinkOfTheDayActive, setIsEditDrinkOfTheDayActive] =
    useState(-1);

  function addCategory(newCategory) {
    setCategoriesToBeSaved((prevCategorys) => [...prevCategorys, newCategory]);
  }

  const getDishById = (type, id, idx) => {
    if (type === "dish") {
      getDishCartByIdEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setIsEditDishSlideInActive(idx);
          setAddNewDish(response.data.data);
          setDishVariations(response.data.data.dish.prices);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "drink") {
      getCartDrinksByIdEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setIsEditDrinkCartActive(idx);
          setNewCartDrink(response.data.data);
          setCartDrinkVariations(response.data.data.drink.prices);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "dishOfTheDay") {
      getDishOfTheDayEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setIsEditDishOfTheDayActive(idx);
          setVariableDishDescription({
            isActive: response.data.data.dishOfTheDayId,
            description: response.data.data.description,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "drinkOfTheDay") {
      getDrinkOfTheDayEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setIsEditDrinkOfTheDayActive(idx);
          setVariableDishDescription({
            isActive: response.data.data.drinkOfTheDayId,
            description: response.data.data.description,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const deleteDish = (type, id) => {
    if (type === "dish") {
      deleteDishCartEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setDishes((prevDishes) =>
            prevDishes.filter((_, index) => index !== isEditDishSlideInActive)
          );
          setIsEditDishSlideInActive(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "drink") {
      deleteCartDrinkEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setDrinksCart((prev) =>
            prev.filter((_, index) => index !== isEditDrinkCartActive)
          );
          setIsEditDrinkCartActive(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const deleteDishOfTheDay = (type, id) => {
    if (type === "dish") {
      deleteDishOfTheDayEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setVariableDishes(
            variableDishes.filter((dish) => dish.dishOfTheDayId !== id)
          );
          setIsEditDishOfTheDayActive(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "drink") {
      deleteDrinkOfTheDayEndpoint(id, authorizationToken)
        .then((response) => {
          console.log(response.data);
          setDrinksOfTheDay(
            variableDishes.filter((dish) => dish.dishOfTheDayId !== id)
          );
          setIsEditDrinkOfTheDayActive(-1);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const updateDishOfTheDay = (type, id) => {
    if (type === "dish") {
      let dish = variableDishes.find((dish) => dish.dishOfTheDayId === id);
      dish.description = variableDishDescription.description;

      updateDishOfTheDayEndpoint(
        dish,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setVariableDishDescription({ isActive: -1, description: "" });
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (type === "drink") {
      let drink = drinksOfTheDay.find((drink) => drink.drinkOfTheDayId === id);
      drink.description = variableDishDescription.description;

      updateDrinkOfTheDayEndpoint(
        drink,
        restaurant.restaurantId,
        authorizationToken
      )
        .then((response) => {
          console.log(response.data);
          setVariableDishDescription({ isActive: -1, description: "" });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="ementaPage">
      <h1 className="pageTitle">Ementa</h1>
      <DishTypeButtons
        dishTypeSelected={dishTypeSelected}
        setDishTypeSelected={setDishTypeSelected}
        isEditCategoriesActive={isEditCategoriesActive}
        setIsEditCategoriesActive={setIsEditCategoriesActive}
      />

      {categories &&
        drinkCategories &&
        (dishTypeSelected === "comida" ? categories : drinkCategories).map(
          (category, idx) => (
            <div
              className={`categoryDiv ${idx === 0 ? "firstDiv" : undefined}`}
              key={idx}
            >
              <h2>{category.categoryName}</h2>
              <div className="addDishDropdown">
                <MultiLayerDropdown2
                  tabs={multiLayerDropdownTabs}
                  selected={
                    dishTypeSelected === "comida"
                      ? selectedDropdownTab[idx]
                      : selectedDrinkDropdownTab[idx]
                  }
                  setSelected={handleDropdownSelect}
                  isActive={isDropdownActive[idx]}
                  setIsActive={() => handleDropdownClick(idx)}
                  order={idx}
                  width="18.5rem"
                />
              </div>

              {variableDishDescription.isActive === idx && (
                <div className="variableDish">
                  <span className="title">Descrição</span>
                  <input
                    className="input"
                    onChange={(e) =>
                      setVariableDishDescription((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }))
                    }
                    onKeyDown={(e) =>
                      handleKeyDown(e, category, dishTypeSelected)
                    }
                  />
                </div>
              )}

              {dishes &&
                dishes.map((dish, idx2) => (
                  <div
                    style={
                      !dishTypeSelected || dishTypeSelected === "comida"
                        ? {}
                        : { display: "none" }
                    }
                    key={idx2}
                  >
                    <div
                      className="dishDiv"
                      style={
                        dish.categoryName === category.categoryName
                          ? {}
                          : { display: "none" }
                      }
                    >
                      <Dish
                        heading={dish.dish.dishName}
                        description={dish.dish.dishDescription}
                        price={dish.dish.prices[0].price}
                        image={dish.dish.dishImage}
                        sort={/*TODO*/ false}
                        onClick={() =>
                          getDishById("dish", dish.dishCartId, idx2)
                        }
                      />
                    </div>
                  </div>
                ))}

              {variableDishes &&
                variableDishes.map((dish, idx3) => {
                  return dish.dish !== null ? (
                    <div
                      style={
                        !dishTypeSelected || dishTypeSelected === "comida"
                          ? {}
                          : { display: "none" }
                      }
                      key={idx3}
                    >
                      <div
                        className="dishDiv"
                        style={
                          dish.categoryName === category.categoryName
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <Dish
                          heading={dish.dish.dishName}
                          description={dish.dish.dishDescription}
                          price={dish.dish.prices[0].price}
                          image={dish.dish.dishImage}
                          sort={/*TODO*/ false}
                          onClick={() =>
                            getDishById("dish", dish.dishCartId, idx3)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={
                        !dishTypeSelected || dishTypeSelected === "comida"
                          ? {}
                          : { display: "none" }
                      }
                      key={idx3}
                    >
                      <div
                        className="dishDiv"
                        style={
                          dish.categoryName === category.categoryName
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <DishOfTheDay
                          item={dish}
                          onClick={() =>
                            getDishById(
                              "dishOfTheDay",
                              dish.dishOfTheDayId,
                              idx3
                            )
                          }
                          /*isEditActive={isEditDishOfTheDayActive} setVariableDishDescription={setVariableDishDescription} edit={!isEditDishOfTheDayActive ? () => setIsEditDishOfTheDayActive(true) : () => {setIsEditDishOfTheDayActive(false); updateDishOfTheDay("dish", dish.dishOfTheDayId)}} remove={() => deleteDishOfTheDay("dish", dish.dishOfTheDayId)}*/ sort={
                            false
                          }
                        />
                      </div>
                    </div>
                  );
                })}

              {drinksCart &&
                drinksCart.map((drink, idx4) => (
                  <div
                    style={
                      !dishTypeSelected || dishTypeSelected === "bebida"
                        ? {}
                        : { display: "none" }
                    }
                    key={idx4}
                  >
                    <div
                      className="dishDiv"
                      style={
                        drink.categoryName === category.categoryName
                          ? {}
                          : { display: "none" }
                      }
                    >
                      <Dish
                        heading={drink.drink.drinkName}
                        description={drink.drink.drinkDescription}
                        price={drink.drink.prices[0].price}
                        image={drink.drink.drinkImage}
                        sort={/*TODO*/ false}
                        onClick={() =>
                          getDishById("drink", drink.drinkCartId, idx4)
                        }
                      />
                    </div>
                  </div>
                ))}

              {drinksOfTheDay &&
                drinksOfTheDay.map((drink, idx3) => {
                  return drink.drink !== null ? (
                    <div
                      style={
                        !dishTypeSelected || dishTypeSelected === "bebida"
                          ? {}
                          : { display: "none" }
                      }
                      key={idx3}
                    >
                      <div
                        className="dishDiv"
                        style={
                          drink.categoryName === category.categoryName
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <Dish
                          heading={drink.drink.drinkName}
                          description={drink.drink.drinkDescription}
                          price={drink.drink.prices[0].price}
                          image={drink.drink.drinkImage}
                          sort={/*TODO*/ false}
                          onClick={() =>
                            getDishById("drink", drink.drinkCartId, idx3)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={
                        !dishTypeSelected || dishTypeSelected === "bebida"
                          ? {}
                          : { display: "none" }
                      }
                      key={idx3}
                    >
                      <div
                        className="dishDiv"
                        style={
                          drink.categoryName === category.categoryName
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <DishOfTheDay
                          item={drink}
                          onClick={() =>
                            getDishById(
                              "drinkOfTheDay",
                              drink.drinkOfTheDayId,
                              idx3
                            )
                          }
                          /*isEditActive={isEditDrinkOfTheDayActive} setVariableDishDescription={setVariableDishDescription} edit={!isEditDrinkOfTheDayActive ? () => setIsEditDrinkOfTheDayActive(true) : () => {setIsEditDrinkOfTheDayActive(false); updateDishOfTheDay("drink", drink.drinkOfTheDayId)}} remove={() => deleteDishOfTheDay("drink", drink.dishOfTheDayId)}*/ sort={
                            false
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )
        )}

      {isEditCategoriesActive && (
        <div className="slideIn edit-categories">
          <h1>Editar categorias</h1>

          <div className="categories">
            <h2
              style={
                (dishTypeSelected === "comida"
                  ? categories && categories.length > 0
                  : drinkCategories && drinkCategories.length > 0) ||
                (categoriesToBeSaved && categoriesToBeSaved.length > 0)
                  ? {}
                  : { display: "none" }
              }
            >
              Ordem categorias
            </h2>
            <div className="list">
              {categories &&
                drinkCategories &&
                (dishTypeSelected === "comida"
                  ? categories
                  : drinkCategories
                ).map((category, idx) => (
                  <div key={idx}>
                    {!categoriesToBeDeleted.includes(category.categoryId) && (
                      <div className="category">
                        <img
                          src={menu}
                          alt="sort"
                          style={{ /*TODO*/ display: "none" }}
                        />
                        <p>{category.categoryName}</p>
                        <img
                          onClick={() => {
                            setCategoriesToBeDeleted((prev) => [
                              ...prev,
                              category.categoryId,
                            ]);
                          }}
                          className="delete"
                          src={plus}
                          alt="delete"
                        />
                        <img
                          src={pencil}
                          alt="edit"
                          className="edit"
                          style={{ /*TODO*/ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              {categoriesToBeSaved &&
                categoriesToBeSaved.map((category, idx) => (
                  <div className="category" key={idx}>
                    <img
                      src={menu}
                      alt="sort"
                      style={{ /*TODO*/ display: "none" }}
                    />
                    <p>{category}</p>
                    <img className="delete" src={plus} alt="delete" />
                    <img
                      src={pencil}
                      alt="edit"
                      className="edit"
                      style={{ /*TODO*/ display: "none" }}
                    />
                  </div>
                ))}
            </div>
            <button
              onClick={() =>
                setEditCategoryExtensionIsActive(!editCategoryExtensionIsActive)
              }
              style={
                editCategoryExtensionIsActive
                  ? { backgroundColor: "#2F2F2F" }
                  : {}
              }
            >
              <img
                src={editCategoryExtensionIsActive ? whitePlus : plus}
                alt="plus"
              />
              <p
                style={
                  editCategoryExtensionIsActive ? { color: "#FFFFFF" } : {}
                }
              >
                Adicionar categoria
              </p>
            </button>
          </div>
          {editCategoryExtensionIsActive && (
            <div className="extension">
              <h2>Nome</h2>
              <input
                onChange={(e) => setNewCategoryName(e.target.value)}
                placeholder="Nome categoria"
              />
              <button
                onClick={() => {
                  addCategory(newCategoryName);
                  setNewCategoryName("");
                  setEditCategoryExtensionIsActive(false);
                }}
                className="save"
              >
                Guardar
              </button>
            </div>
          )}

          <div className="buttons">
            <button
              onClick={() => {
                setIsEditCategoriesActive(false);
                setCategoriesToBeSaved([]);
                setCategoriesToBeDeleted([]);
                setEditCategoryExtensionIsActive(false);
              }}
              className="cancel"
            >
              Cancelar
            </button>
            <button onClick={() => handleCategorySave()} className="save">
              Guardar
            </button>
          </div>
        </div>
      )}

      {addDishFromList !== -1 && (
        <div className="slideIn fixedPlate-list">
          <h1>Lista de artigos</h1>
          {/*TODO<SearchBar width="32.688rem" text="Pesquisar na lista de pratos" margin="0 0 0 3.875rem" background="#E9E9E9" />*/}

          <div className="dishes">
            {itemList.map((item, idx) => (
              <DishForSlideIn
                title={item.dishName}
                description={item.dishDescription}
                price={item.prices[0].price}
                image={item.dishImage}
                margin="1.188rem 7.625rem 0 3.875rem"
                onClick={() => handleAddDish("dish", item)}
                key={idx}
              />
            ))}
          </div>
        </div>
      )}

      {addDish !== -1 && (
        <div className="slideIn add-dish">
          <h1>Nome Prato</h1>
          <div className="inputs">
            <div className="name">
              <h2>Nome</h2>
              <input
                onChange={(e) =>
                  setAddNewDish((prev) => ({
                    ...prev,
                    dish: { ...prev.dish, dishName: e.target.value },
                  }))
                }
                placeholder="Nome item"
              />
            </div>
            <div className="priceContainer">
              {dishVariations.map((dish, idx) => (
                <div className="priceDiv" key={idx}>
                  <div className="price">
                    <span>€</span>
                    <input
                      value={
                        dishVariations[idx].price === 0
                          ? ""
                          : dishVariations[idx].price.toString()
                      }
                      onChange={(e) =>
                        setDishVariations(
                          addMiniDescription(
                            dishVariations,
                            idx,
                            "price",
                            Number(e.target.value)
                          )
                        )
                      }
                      type="number"
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (dishVariations.length === idx + 1) {
                        setDishVariations((prev) => [
                          ...prev,
                          { price: 0, description: "" },
                        ]);
                      } else {
                        setDishVariations((prevArr) =>
                          idx >= 0 && idx < prevArr.length
                            ? [
                                ...prevArr.slice(0, idx),
                                ...prevArr.slice(idx + 1),
                              ]
                            : prevArr
                        );
                      }
                    }}
                  >
                    <img
                      src={
                        dishVariations.length === idx + 1 ? whitePlus : minus
                      }
                    />
                  </button>
                  <input
                    value={dishVariations[idx].description}
                    onChange={(e) =>
                      setDishVariations(
                        addMiniDescription(
                          dishVariations,
                          idx,
                          "description",
                          e.target.value
                        )
                      )
                    }
                    style={
                      dishVariations.length === 1 ? { display: "none" } : {}
                    }
                    placeholder="Descrição"
                    className="secondaryDescription"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="description">
            <h2>Descrição</h2>
            <textarea
              onChange={(e) =>
                setAddNewDish((prev) => ({
                  ...prev,
                  dish: { ...prev.dish, dishDescription: e.target.value },
                }))
              }
              placeholder="Descrição prato..."
            />
          </div>

          <div className="accompaniments">
            <div className="title-button">
              <h2>Acompanhamentos</h2>
              <button onClick={() => alert("In Construction")}>
                <img src={plus} alt="plus" />
                <span>Adicionar acompanhamento</span>
              </button>
            </div>
            <p>
              Adicione um acompanhamento com identidade a partir da sua lista de
              pratos
            </p>
          </div>

          <div className="dishOptions">
            <h2>Opções</h2>
            <button onClick={() => alert("In Construction")}>
              <img src={plus} alt="plus" />
              <span>Adicionar opções</span>
            </button>
          </div>

          <div className="photos">
            <h2>Fotos</h2>
            <div className="album">
              {addNewDish.dish.dishImage && (
                <img
                  className="photo"
                  src={addNewDish.dish.dishImage}
                  alt="dish image"
                />
              )}
              <label htmlFor="file">
                <img className="addPhotoBtn" src={crossButton} />
              </label>
              <input
                type="file"
                id="file"
                onChange={(e) => {
                  uploadImage(e.target.files[0], "dish");
                }}
              />
            </div>
          </div>

          <div className="caracteristics">
            <h2>Características</h2>
            <div className="row">
              {caracteristics.map((caracteristic, idx) => (
                <button
                  onClick={() => handleCaracteristicClick(caracteristic, 2)}
                  className="cat"
                  style={
                    addNewDish.dish.dishCaracteristics.includes(caracteristic)
                      ? { background: "#77B9B4" }
                      : {}
                  }
                  key={idx}
                >
                  <p>{caracteristic}</p>
                </button>
              ))}
            </div>
          </div>

          <div className="code">
            <div className="firstLine">
              <h2>Código</h2>
              <input
                onChange={(e) =>
                  setAddNewDish((prev) => ({
                    ...prev,
                    dish: { ...prev.dish, dishCode: e.target.value },
                  }))
                }
              />
            </div>
            <p>Adicione um código ao prato (não obrigatório)</p>
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                handleDropdownSelect(addDish, {
                  main: "Adicionar prato",
                  secondary: "",
                });
                setAddDish(-1);
                resetDish("dish");
                setDishVariations([{ price: 0, description: "" }]);
              }}
              className="cancel"
            >
              Cancelar
            </button>
            <button onClick={() => saveNewItem("dish")} className="save">
              Guardar
            </button>
          </div>
        </div>
      )}

      {isEditDishSlideInActive !== -1 && (
        <div className="slideIn add-dish">
          <h1>Nome Prato</h1>
          <div className="inputs">
            <div className="name">
              <h2>Nome</h2>
              <input
                value={addNewDish.dish.dishName}
                onChange={(e) =>
                  setAddNewDish((prev) => ({
                    ...prev,
                    dish: { ...prev.dish, dishName: e.target.value },
                  }))
                }
                placeholder="Nome item"
              />
            </div>
            <div className="priceContainer">
              {dishVariations.map((dish, idx) => (
                <div className="priceDiv" key={idx}>
                  <div className="price">
                    <span>€</span>
                    <input
                      value={
                        dishVariations[idx].price === 0
                          ? ""
                          : dishVariations[idx].price.toString()
                      }
                      onChange={(e) =>
                        setDishVariations(
                          addMiniDescription(
                            dishVariations,
                            idx,
                            "price",
                            Number(e.target.value)
                          )
                        )
                      }
                      type="number"
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (dishVariations.length === idx + 1) {
                        setDishVariations((prev) => [
                          ...prev,
                          { price: 0, description: "" },
                        ]);
                      } else {
                        setDishVariations((prevArr) =>
                          idx >= 0 && idx < prevArr.length
                            ? [
                                ...prevArr.slice(0, idx),
                                ...prevArr.slice(idx + 1),
                              ]
                            : prevArr
                        );
                      }
                    }}
                  >
                    <img
                      src={
                        dishVariations.length === idx + 1 ? whitePlus : minus
                      }
                    />
                  </button>
                  <input
                    value={dishVariations[idx].description}
                    onChange={(e) =>
                      setDishVariations(
                        addMiniDescription(
                          dishVariations,
                          idx,
                          "description",
                          e.target.value
                        )
                      )
                    }
                    style={
                      dishVariations.length === 1 ? { display: "none" } : {}
                    }
                    placeholder="Descrição"
                    className="secondaryDescription"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="description">
            <h2>Descrição</h2>
            <textarea
              value={addNewDish.dish.dishDescription}
              onChange={(e) =>
                setAddNewDish((prev) => ({
                  ...prev,
                  dish: { ...prev.dish, dishDescription: e.target.value },
                }))
              }
            />
          </div>

          <div className="accompaniments">
            <div className="title-button">
              <h2>Acompanhamentos</h2>
              <button>
                <img src={plus} alt="plus" />
                <span>Adicionar acompanhamento</span>
              </button>
            </div>
            <p>
              Adicione um acompanhamento com identidade a partir da sua lista de
              pratos
            </p>
          </div>

          <div className="dishOptions">
            <h2>Opções</h2>
            <button>
              <img src={plus} alt="plus" />
              <span>Adicionar opções</span>
            </button>
          </div>

          <div className="photos">
            <h2>Fotos</h2>
            <div className="album">
              {addNewDish.dish.dishImage && (
                <img
                  className="photo"
                  src={addNewDish.dish.dishImage}
                  alt="dish image"
                />
              )}
              <label htmlFor="file">
                <img className="addPhotoBtn" src={crossButton} />
              </label>
              <input
                type="file"
                id="file"
                onChange={(e) => {
                  uploadImage(e.target.files[0], "dish");
                }}
              />
            </div>
          </div>

          <div className="caracteristics">
            <h2>Características</h2>
            <div className="row">
              {caracteristics.map((caracteristic, idx) => (
                <button
                  onClick={() => handleCaracteristicClick(caracteristic, 2)}
                  className="cat"
                  style={
                    addNewDish.dish.dishCaracteristics.includes(caracteristic)
                      ? { background: "#77B9B4" }
                      : {}
                  }
                  key={idx}
                >
                  <p>{caracteristic}</p>
                </button>
              ))}
            </div>
          </div>

          <div className="code">
            <div className="firstLine">
              <h2>Código</h2>
              <input
                value={addNewDish.dish.dishCode ? addNewDish.dish.dishCode : ""}
                onChange={(e) =>
                  setAddNewDish((prev) => ({
                    ...prev,
                    dish: { ...prev.dish, dishCode: e.target.value },
                  }))
                }
              />
            </div>
            <p>Adicione um código ao prato (não obrigatório)</p>
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                setIsEditDishSlideInActive(-1);
                resetDish("dish");
                setDishVariations([{ price: 0, description: "" }]);
              }}
              className="cancel"
            >
              Cancelar
            </button>
            <button
              onClick={() => updateDish(dishTypeSelected)}
              className="save"
            >
              Guardar
            </button>
          </div>

          <p
            onClick={() => deleteDish("dish", addNewDish.dishCartId)}
            className="deleteDish"
          >
            Eliminar prato
          </p>
        </div>
      )}

      {isEditDishOfTheDayActive !== -1 && (
        <div className="slideIn add-dish">
          <h1>Prato do Dia</h1>

          <div className="description">
            <h2>Descrição</h2>
            <textarea
              value={variableDishDescription.description}
              onChange={(e) =>
                setVariableDishDescription((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                setIsEditDishOfTheDayActive(-1);
                resetDish("dishOfTheDay");
              }}
              className="cancel"
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                setIsEditDishOfTheDayActive(-1);
                updateDishOfTheDay("dish", variableDishDescription.isActive);
              }}
              className="save"
            >
              Guardar
            </button>
          </div>

          <p
            onClick={() =>
              deleteDishOfTheDay("dish", variableDishDescription.isActive)
            }
            className="deleteDish"
          >
            Eliminar prato
          </p>
        </div>
      )}

      {isEditDrinkOfTheDayActive !== -1 && (
        <div className="slideIn add-dish">
          <h1>Prato do Dia</h1>

          <div className="description">
            <h2>Descrição</h2>
            <textarea
              value={variableDishDescription.description}
              onChange={(e) =>
                setVariableDishDescription((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                setIsEditDrinkOfTheDayActive(-1);
                resetDish("drinkOfTheDay");
              }}
              className="cancel"
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                setIsEditDrinkOfTheDayActive(-1);
                updateDishOfTheDay("drink", variableDishDescription.isActive);
              }}
              className="save"
            >
              Guardar
            </button>
          </div>

          <p
            onClick={() =>
              deleteDishOfTheDay("drink", variableDishDescription.isActive)
            }
            className="deleteDish"
          >
            Eliminar prato
          </p>
        </div>
      )}

      {isAddFromListDrinkCart !== -1 && (
        <div className="slideIn fixedPlate-list">
          <h1>Lista de artigos</h1>
          {/*TODO<SearchBar width="32.688rem" text="Pesquisar na lista de pratos" margin="0 0 0 3.875rem" background="#E9E9E9" />*/}

          <div className="dishes">
            {drinks.map((drink, idx) => (
              <DishForSlideIn
                title={drink.drinkName}
                description={drink.drinkDescription}
                price={drink.prices[0].price}
                image={drink.drinkImage}
                margin="1.188rem 7.625rem 0 3.875rem"
                onClick={() => handleAddDish("drink", drink)}
                key={idx}
              />
            ))}
          </div>
        </div>
      )}

      {isAddDrinkCartActive !== -1 && (
        <div className="slideIn add-dish">
          <h1>Nome Bebida</h1>
          <div className="inputs">
            <div className="name">
              <h2>Nome</h2>
              <input
                onChange={(e) =>
                  setNewCartDrink((prev) => ({
                    ...prev,
                    drink: { ...prev.drink, drinkName: e.target.value },
                  }))
                }
                placeholder="Nome item"
              />
            </div>
            <div className="priceContainer">
              {cartDrinkVariations.map((drink, idx) => (
                <div className="priceDiv" key={idx}>
                  <div className="price">
                    <span>€</span>
                    <input
                      value={
                        cartDrinkVariations[idx].price === 0
                          ? ""
                          : cartDrinkVariations[idx].price.toString()
                      }
                      onChange={(e) =>
                        setCartDrinkVariations(
                          addMiniDescription(
                            cartDrinkVariations,
                            idx,
                            "price",
                            Number(e.target.value)
                          )
                        )
                      }
                      type="number"
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (cartDrinkVariations.length === idx + 1) {
                        setCartDrinkVariations((prev) => [
                          ...prev,
                          { price: 0, description: "" },
                        ]);
                      } else {
                        setCartDrinkVariations((prevArr) =>
                          idx >= 0 && idx < prevArr.length
                            ? [
                                ...prevArr.slice(0, idx),
                                ...prevArr.slice(idx + 1),
                              ]
                            : prevArr
                        );
                      }
                    }}
                  >
                    <img
                      src={
                        cartDrinkVariations.length === idx + 1
                          ? whitePlus
                          : minus
                      }
                    />
                  </button>
                  <input
                    value={cartDrinkVariations[idx].description}
                    onChange={(e) =>
                      setCartDrinkVariations(
                        addMiniDescription(
                          cartDrinkVariations,
                          idx,
                          "description",
                          e.target.value
                        )
                      )
                    }
                    style={
                      cartDrinkVariations.length === 1
                        ? { display: "none" }
                        : {}
                    }
                    placeholder="Descrição"
                    className="secondaryDescription"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="description">
            <h2>Descrição</h2>
            <textarea
              onChange={(e) =>
                setNewCartDrink((prev) => ({
                  ...prev,
                  drink: { ...prev.drink, drinkDescription: e.target.value },
                }))
              }
              placeholder="Descrição bebida..."
            />
          </div>

          <div className="dishOptions">
            <h2>Opções</h2>
            <button onClick={() => alert("In Construction")}>
              <img src={plus} alt="plus" />
              <span>Adicionar opções</span>
            </button>
          </div>

          <div className="photos">
            <h2>Fotos</h2>
            <div className="album">
              {newCartDrink.drink.drinkImage && (
                <img
                  className="photo"
                  src={newCartDrink.drink.drinkImage}
                  alt="dish image"
                />
              )}
              <label htmlFor="file">
                <img className="addPhotoBtn" src={crossButton} />
              </label>
              <input
                type="file"
                id="file"
                onChange={(e) => {
                  uploadImage(e.target.files[0], "drinkCart");
                }}
              />
            </div>
          </div>

          <div className="caracteristics">
            <h2>Características</h2>
            <div className="row">
              {drinkCaracteristics.map((caracteristic, idx) => (
                <button
                  onClick={() => handleCaracteristicClick(caracteristic, 5)}
                  className="cat"
                  style={
                    newCartDrink.drink.drinkCaracteristics.includes(
                      caracteristic
                    )
                      ? { background: "#77B9B4" }
                      : {}
                  }
                  key={idx}
                >
                  <p>{caracteristic}</p>
                </button>
              ))}
            </div>
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                handleDropdownSelect(isAddDrinkCartActive, {
                  main: "Adicionar prato",
                  secondary: "",
                });
                setIsAddDrinkCartActive(-1);
                resetDish("drinkCart");
                setCartDrinkVariations([{ price: 0, description: "" }]);
              }}
              className="cancel"
            >
              Cancelar
            </button>
            <button onClick={() => saveNewItem("drinkCart")} className="save">
              Guardar
            </button>
          </div>
        </div>
      )}

      {isEditDrinkCartActive !== -1 && (
        <div className="slideIn add-dish">
          <h1>Nome Bebida</h1>
          <div className="inputs">
            <div className="name">
              <h2>Nome</h2>
              <input
                value={newCartDrink.drink.drinkName}
                onChange={(e) =>
                  setNewCartDrink((prev) => ({
                    ...prev,
                    drink: { ...prev.drink, drinkName: e.target.value },
                  }))
                }
                placeholder="Nome item"
              />
            </div>
            <div className="priceContainer">
              {cartDrinkVariations.map((drink, idx) => (
                <div className="priceDiv" key={idx}>
                  <div className="price">
                    <span>€</span>
                    <input
                      value={
                        cartDrinkVariations[idx].price === 0
                          ? ""
                          : cartDrinkVariations[idx].price.toString()
                      }
                      onChange={(e) =>
                        setCartDrinkVariations(
                          addMiniDescription(
                            cartDrinkVariations,
                            idx,
                            "price",
                            Number(e.target.value)
                          )
                        )
                      }
                      type="number"
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (cartDrinkVariations.length === idx + 1) {
                        setCartDrinkVariations((prev) => [
                          ...prev,
                          { price: 0, description: "" },
                        ]);
                      } else {
                        setCartDrinkVariations((prevArr) =>
                          idx >= 0 && idx < prevArr.length
                            ? [
                                ...prevArr.slice(0, idx),
                                ...prevArr.slice(idx + 1),
                              ]
                            : prevArr
                        );
                      }
                    }}
                  >
                    <img
                      src={
                        cartDrinkVariations.length === idx + 1
                          ? whitePlus
                          : minus
                      }
                    />
                  </button>
                  <input
                    value={cartDrinkVariations[idx].description}
                    onChange={(e) =>
                      setCartDrinkVariations(
                        addMiniDescription(
                          cartDrinkVariations,
                          idx,
                          "description",
                          e.target.value
                        )
                      )
                    }
                    style={
                      cartDrinkVariations.length === 1
                        ? { display: "none" }
                        : {}
                    }
                    placeholder="Descrição"
                    className="secondaryDescription"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="description">
            <h2>Descrição</h2>
            <textarea
              value={newCartDrink.drink.drinkDescription}
              onChange={(e) =>
                setNewCartDrink((prev) => ({
                  ...prev,
                  drink: { ...prev.drink, drinkDescription: e.target.value },
                }))
              }
            />
          </div>

          <div className="dishOptions">
            <h2>Opções</h2>
            <button>
              <img src={plus} alt="plus" />
              <span>Adicionar opções</span>
            </button>
          </div>

          <div className="photos">
            <h2>Fotos</h2>
            <div className="album">
              {newCartDrink.drink.drinkImage && (
                <img
                  className="photo"
                  src={newCartDrink.drink.drinkImage}
                  alt="dish image"
                />
              )}
              <label htmlFor="file">
                <img className="addPhotoBtn" src={crossButton} />
              </label>
              <input
                type="file"
                id="file"
                onChange={(e) => {
                  uploadImage(e.target.files[0], "drinkCart");
                }}
              />
            </div>
          </div>

          <div className="caracteristics">
            <h2>Características</h2>
            <div className="row">
              {drinkCaracteristics.map((caracteristic, idx) => (
                <button
                  onClick={() => handleCaracteristicClick(caracteristic, 5)}
                  className="cat"
                  style={
                    newCartDrink.drink.drinkCaracteristics.includes(
                      caracteristic
                    )
                      ? { background: "#77B9B4" }
                      : {}
                  }
                  key={idx}
                >
                  <p>{caracteristic}</p>
                </button>
              ))}
            </div>
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                setIsEditDrinkCartActive(-1);
                resetDish("drinkCart");
                setCartDrinkVariations([{ price: 0, description: "" }]);
              }}
              className="cancel"
            >
              Cancelar
            </button>
            <button
              onClick={() => updateDish(dishTypeSelected)}
              className="save"
            >
              Guardar
            </button>
          </div>

          <p
            onClick={() => deleteDish("drink", newCartDrink.drinkCartId)}
            className="deleteDish"
          >
            Eliminar prato
          </p>
        </div>
      )}
    </div>
  );
};

export default EmentaPage;
