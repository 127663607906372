import './dishOfTheDay.css';
import menu from '../../utils/logos/menu.svg';

const DishOfTheDay = ({ item, onClick, sort=true }) => {
  return (
    <div className='dishOfTheDay'>
        <img src={menu} alt="sort" style={sort ? {} : {display: "none"}} />
        <p onClick={onClick}>{item.description}</p>
    </div>
  )
}

export default DishOfTheDay;