import './hourDropdown.css';
import blackArrow from '../../utils/logos/blackArrow.svg';
import arrow from '../../utils/logos/arrow.svg';

function HourDropdown({ tabs, selected, setSelected, isActive, setIsActive, top=false }) {

    return (
        <div className="hourDropdown">
            <div className="hourDropdown-btn" onClick={() => setIsActive(!isActive)} style={isActive ? { backgroundColor: "#2F2F2F", color: "#FFFFFF", ...top ? {zIndex: "3"}: {zIndex: "2"} } : {}} >
                <p style={isActive ? {color: "#FFFFFF"} : {}}>{selected}</p>
                <img src={isActive ? arrow : blackArrow} alt="arrow" />
            </div>

            {isActive &&
                <div className="hourDropdown-content" style={top ? {zIndex: "2"} : {zIndex: "1"}}>
                    {tabs.map((item) => (
                        item !== selected && (
                            <div className="hourDropdown-item" onClick={() => {
                                setSelected(item)
                                setIsActive(!isActive)
                            }} key={item} >
                                <p>{item}</p>
                            </div>
                        )
                    ))}
                </div>
            }
        </div>
    );
}

export default HourDropdown;